import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ClipLoader } from 'react-spinners';
import { useApiRequest } from '../store/Common';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { Close, SearchTwoTone } from '@mui/icons-material';

function CompliancePage() {

    const {fetchData, postData} = useApiRequest();
    const [totalDocs, setTotalDocs] = useState(0);
    const [isLoadingFy, setIsLoadingFy] = useState(false);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [fyView, setFyView] = useState('');
    const [selectedFy, setSelectedFy] = useState({name: '', id: null});
    const [accountantsList, setAccountantsList] = useState([]);

    const containerRef = useRef();
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [bodyData, setBodyData] = useState({type: [], status: [], search: '', start: 0, end: 0, accountant: []});
    const [complianceList, setComplianceList] = useState([]);

    const [complianceTypes, setComplianceTypes] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAccountant, setSelectedAccountant] = useState([]);

    const getFyData = useCallback(async() =>{
        setIsLoadingFy(true);
        const resp = await fetchData('settings/financialyears');
        const resp1 = await fetchData('users/accountants');
        if(resp && resp1){
            setFinancialYearList(resp.data);
            setAccountantsList(resp1.data);
        }
        setIsLoadingFy(false);
    },[fetchData]);

    useEffect(()=>{
        getFyData();
    },[getFyData]);

    const loadComplianceButton = async() =>{
        if(fyView === ""){
            return;
        }
        setSelectedFy(fyView);
        setIsLoading(true);
        setComplianceList([]);
        const resp = await postData(`app/compliances/${fyView.id}/1`, {type: [], status: [], search: '', start: 0, end: 0, accountant: []});
        const resp1 = await fetchData(`settings/compliances/${fyView.id}`);
        if(resp && resp1){
            setComplianceList(resp.data.docs);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
            setComplianceTypes(resp1.data);
        }
        setIsLoading(false);
    }

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body, fid) =>{
        setIsLoading(true);
        const resp = await postData(`app/compliances/${fid}/${page}`, body);
        if(resp){
            setComplianceList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && complianceList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData, selectedFy.id);
            }
        }
    },[getData, isLoading, complianceList.length, page, bodyData, selectedFy])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleTypeChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedType(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleStatusChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleAccountantChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedAccountant(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const resetList = () =>{
        if(isLoading || !selectedFy.id){
            return;
        }
        setBodyData({type: [], status: [], search: '', start: 0, end: 0, accountant: []});
        setComplianceList([]);
        setSearchTerm('');
        setSelectedType([]);
        setSelectedAccountant([]);
        setSelectedDate(null);
        setSelectedStatus([]);
        getData(1, {type: [], status: [], search: '', start: 0, end: 0, accountant: []}, selectedFy.id);
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading || !selectedFy.id){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            search: searchTerm,
            type: selectedType,
            status: selectedStatus,
            accountant: selectedAccountant,
            start,
            end
        }
        setBodyData(body);
        setComplianceList([]);
        getData(1, body, selectedFy.id);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='h4'>Compliances ({totalDocs})</Typography>
                    
                    {isLoadingFy && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Financial Years</Typography>
                    </Stack>}

                    <Stack flexDirection={'row'} gap={1}>
                        <Box width={250}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Select Financial Year</InputLabel>
                                <Select value={fyView} onChange={(e)=>{setFyView(e.target.value)}} label="Select Financial Year">
                                    {financialYearList.map(fy=>{  
                                        return <MenuItem key={fy.id} value={fy}>{fy.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Button variant='contained' color='success' onClick={loadComplianceButton}>View</Button>
                    </Stack>

                </Stack>

                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2}>
                        <TextField fullWidth variant='outlined' label='Name/Phone/Company Name' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                        <Stack>
                            <Box width={300}></Box>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Completed Date Range" value={selectedDate} onChange={setSelectedDate} />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel>Compliance Name</InputLabel>
                            <Select multiple value={selectedType} onChange={handleTypeChange} input={<OutlinedInput label="Chip" />} >
                                {complianceTypes.map((comp) => {
                                    return <MenuItem key={comp.id} value={comp.id}>{comp.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select multiple value={selectedStatus} onChange={handleStatusChange} input={<OutlinedInput label="Chip" />} >
                                <MenuItem value={'Done'}>Done</MenuItem>
                                <MenuItem value={'Ongoing'}>Ongoing</MenuItem>
                                <MenuItem value={'To Be Done'}>To Be Done</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Accountant</InputLabel>
                            <Select multiple value={selectedAccountant} onChange={handleAccountantChange} input={<OutlinedInput label="Chip" />} >
                                {accountantsList.map((acc) => {
                                    return <MenuItem key={acc.id} value={acc.id}>{acc.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                       
                        <IconButton type='submit'>
                            <SearchTwoTone fontSize='large' color='primary' />
                        </IconButton>
                        <IconButton type='reset'>
                            <Close fontSize='large' color='primary' />
                        </IconButton>
                    </Stack>
                </Box>

                <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' gap={1} px={3.2}>
                    <Box width='15%'>
                        <Typography><b>Customer Name</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Customer Company</b></Typography>
                    </Box>
                    <Box width='25%'>
                        <Typography><b>Compliance Name</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Expiry</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Status</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Completed</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Accountant</b></Typography>
                    </Box>
                </Stack>

                <Stack mt={1} p={2} pt={0} height='calc(100vh - 250px)' overflow='auto' gap={3} ref={containerRef}>

                    {complianceList.map(compliance=>{
                        let eColor = 'success';
                        if (compliance.compliance.expiry && moment(compliance.compliance.expiry).isBefore(moment().add(10, 'days'))) {
                            eColor = 'warning';
                        }
                        if(compliance.compliance.expiry && moment(compliance.compliance.expiry).isBefore(moment())){
                            eColor = 'error';
                        }
                        if(compliance.status !== 'To Be Done'){
                            eColor = 'success';
                        }
                        return <Stack key={compliance.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Stack width={'15%'}>
                                <Box width={'fit-content'}>
                                    <Link to={`/customer/${compliance.customer.id}`} style={{textDecoration: 'none'}}>
                                        <Typography color={'#000'} variant='h6'>{compliance.customer.name}</Typography>
                                    </Link>
                                </Box>
                                <Typography>+91 {compliance.customer.phone}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                <Typography>{compliance.customer.companyName}</Typography>
                            </Stack>
                            <Stack width={'25%'}>
                                <Typography>{compliance.compliance.name}</Typography>
                            </Stack>
                            <Box width={'10%'}>
                                {compliance.compliance.expiry && <Typography color={eColor}>{moment(compliance.compliance.expiry).format('Do MMM yyyy')}</Typography>}
                            </Box>
                            <Box width={'10%'}>
                                <Typography>{compliance.status}</Typography>
                            </Box>   
                            <Box width={'15%'}>
                                {compliance.doneOn && <Typography>{moment(compliance.doneOn).format('Do MMM YYYY h:mm a')}</Typography>}
                            </Box>
                            <Box width={'10%'}>
                                {compliance.accountant && <Typography>{compliance.accountant.name}</Typography>}
                            </Box>  
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Compliances</Typography>
                    </Stack>}
                </Stack>

            </Stack>
        </Stack>
    )
}

export default CompliancePage