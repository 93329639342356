import { Autocomplete, Box, Button, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useApiRequest } from '../store/Common';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useCommonUI } from '../context/UI';
import { LoadingButton } from '@mui/lab';
import { Chat, DateRange, Download, Email, Handshake, LocalPhone, PersonAdd, PersonPin, Public, Reply, SupportAgent, WhatsApp } from '@mui/icons-material';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Editor } from '@tinymce/tinymce-react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ClipLoader } from 'react-spinners';
import parse from 'html-react-parser';

function LeadDetails() {

    const { leadId } = useParams();
    const navigate = useNavigate();
    const {openSnackbar} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [isLoading, setIsLoading] = useState(false);
    const [LeadDetails, setLeadDetails] = useState(null);
    const [notes, setNotes] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [servicesList, setServicesList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [templateList, setTemplateList] = useState([])
    
    const [editEmailsList, setEditEmailsList] = useState([]);
    const [editEmailField, setEditEmailField] = useState('');
    const [emailModal, setEmailModal] = useState(false);

    const [agentModal, setAgentModal] = useState(false);
    const[selectedAgent, setSelectedAgent] = useState('');

    const [followupModal, setFollowupModal] = useState(false);
    const [followupDate, setFollowupDate] = useState(dayjs());
    const [doCall, setDoCall] = useState(false);

    const [interactionModal, setInteractionModal] = useState(false);
    const [interactionDetails, setInteractionDetails] = useState('');
    const [isCall, setIsCall] = useState(false);

    const [templateModal, setTemplateModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateSubject, setTemplateSubject] = useState('');
    const [templateContent, setTemplateContent] = useState('');

    const [viewTemplateModal, setViewTemplateModal] = useState(false);
    const [viewTemplate, setViewTemplate] = useState(null);

    const [customerModal, setCustomerModal] = useState(false);
    const [newCustomer, setNewCustomer] = useState({company: '', address: '', gst: '', type: 'Private Limited Company', incorporation: dayjs(), state: '', newlyIncorporated: false, username: '' });

    const [editLeadModal, setEditLeadModal] = useState(false);
    const [editLead, setEditLead] = useState({name: '', phone: '', address: '', state: null, service: 'none', source: 'others', type: 'hot', priority: 'high', company: '', response: 'none'})
    
    const [waTemplates, setWaTemplates] = useState([]);
    const [waModal, setWaModal] = useState(false);
    const [isLoadingWa, setIsLoadingWa] = useState(false);
    const [waHeaderComponents, setWaHeaderComponents] = useState(null);
    const [waHeaderVariables, setWaHeaderVariables] = useState([]);
    const [waHeaderMedia, setWaHeaderMedia] = useState('');
    const [waHeaderDocumentName, setWaHeaderDocumentName] = useState('');
    const [waBodyComponent, setWaBodyComponent] = useState(null);
    const [waBodyVariables, setWaBodyVariables] = useState([]);
    const [waFooterComponent, setWaFooterComponent] = useState(null);
    const [waButtonComponent, setWaButtonComponent] = useState(null);
    const [selectedWaTemplate, setSelectedWaTemplate] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('services/list');
            const resp1 = await fetchData('users/agents');
            const resp2 = await fetchData('app/states');
            const resp3 = await fetchData('template/list');
            if(resp && resp1 && resp2){
                setAgentsList(resp1.data);
                setServicesList(resp.data);
                setStatesList(resp2.data);
                setTemplateList(resp3.data);
                if(resp3.data.length > 1){
                    setSelectedTemplate(resp3.data[0]);
                }
            }
        }
        getLists();
    },[fetchData])

    const openEmailModal = () =>{
        if(!LeadDetails){
            return;
        }
        setEditEmailsList(LeadDetails.emails);
        setEditEmailField('');
        setEmailModal(true);
    }
    const submitEmails = async() =>{
        setIsSubmitting(true);
        const resp = await postData('leads/emails', {id: LeadDetails.id, emails: editEmailsList});
        if(resp){
            setEmailModal(false);
            openSnackbar("Emails Updated", "success");
            setLeadDetails(null);
            getData(leadId);
        }
        setIsSubmitting(false);
    }
    const addEmail = () =>{
        if(editEmailField === ""){
            return;
        }
        setEditEmailsList((emails)=> [...emails, editEmailField]);
        setEditEmailField('');
    }
    const removeEmail = (email) => {
        setEditEmailsList((emails) => emails.filter((em) => em !== email));
    };

    const getData = useCallback(async(oid) =>{
        setIsLoading(true);
        const resp = await fetchData('leads/details/'+oid);
        if(resp){
            setLeadDetails(resp.data);
            setNotes(resp.notes);
            setSelectedAgent(resp.data.assigned?resp.data.assigned.id:'');
        }
        setIsLoading(false);
    },[fetchData])

    useEffect(()=>{
        getData(leadId);
    },[getData, leadId]);

    const handleEditLead = (e) => {
        const { name, value } = e.target;
        setEditLead({ ...editLead, [name]: value });
    };

    const openEditModal = () =>{
        if(!LeadDetails){
            return;
        }
        const obj = {
            name: LeadDetails.name,
            phone: LeadDetails.phone, 
            address: LeadDetails.address?LeadDetails.address:'', 
            state: LeadDetails.state?LeadDetails.state.id:'', 
            service: LeadDetails.service?LeadDetails.service.id:'none', 
            source: LeadDetails.source, 
            type: LeadDetails.type, 
            priority: LeadDetails.priority?LeadDetails.priority:'none', 
            company: LeadDetails.companyName?LeadDetails.companyName:'',
            response: LeadDetails.response?LeadDetails.response:'none'
        }
        setEditLead(obj);
        setEditLeadModal(true);
    }

    const updateLead = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        const resp = await postData('leads/update', {id: LeadDetails.id, ...editLead});
        if(resp){
            setEditLeadModal(false);
            openSnackbar("Lead Updated", "success");
            setLeadDetails(null);
            getData(leadId);
        }
        setIsSubmitting(false);
    }

    const submitAgent = async() =>{
        setIsSubmitting(true);
        const resp = await postData('leads/agent', {id: LeadDetails.id, agent: selectedAgent});
        if(resp){
            setAgentModal(false);
            openSnackbar("Agent Updated", "success");
            setLeadDetails(null);
            getData(leadId);
        }
        setIsSubmitting(false);
    }

    const openFollowUpModal = () =>{
        if(!LeadDetails){
            return;
        }
        if(LeadDetails.nextFollowup){
            setFollowupDate(dayjs(LeadDetails.nextFollowup));
        }else{
            setFollowupDate(dayjs());
        }
        if(LeadDetails.doCall){
            setDoCall(true);
        }else{
            setDoCall(false);
        }
        setFollowupModal(true);
    }
    const submitFollowup = async() =>{
        setIsSubmitting(true);
        const resp = await postData('leads/followup', {id: LeadDetails.id, date: new Date(followupDate).getTime(), doCall});
        if(resp){
            setFollowupModal(false);
            openSnackbar("Followup Date Updated", "success");
            setLeadDetails(null);
            getData(leadId);
        }
        setIsSubmitting(false);
    }

    const openInteractionModal = () =>{
        setInteractionDetails('');
        setIsCall(false);
        setInteractionModal(true);
    }

    const submitInteraction = async() =>{
        if(interactionDetails === ""){
            return;
        }
        const resp = await postData('leads/note', {id: LeadDetails.id, note: interactionDetails, isCall: isCall});
        if(resp){
            setInteractionModal(false);
            openSnackbar("Interaction Added", "success");
            setNotes([]);
            getData(leadId);
        }
    }

    const closeModal = () =>{
        if(isSubmitting){
            return;
        }
        setFollowupModal(false);
        setAgentModal(false);
        setEditLeadModal(false);
        setEmailModal(false);
        setInteractionModal(false);
        setTemplateModal(false);
        setCustomerModal(false);
        setWaModal(false);
    }

    const sendEmail = async() =>{
        if(templateContent === "" || templateSubject === ""){
            return;
        }
        const resp = await postData('leads/sendemail', {id: LeadDetails.id, content: templateContent, subject: templateSubject, tid: selectedTemplate.id});
        if(resp){
            setTemplateModal(false);
            openSnackbar("Email Sent", "success");
            setNotes([]);
            getData(leadId);
        }
    }
    const openTemplateModal = () =>{
        setTemplateModal(true);
    }
    useEffect(()=>{
        if(selectedTemplate){
            setTemplateContent(selectedTemplate.content);
            setTemplateSubject(selectedTemplate.subject);
        }
    },[selectedTemplate]);

    const openTemplateView = (note) =>{
        setViewTemplate({subject: note.templateSubject, content: note.note});
        setViewTemplateModal(true);
    }

    const openConvertCustomer = () =>{
        setNewCustomer({company: LeadDetails.companyName?LeadDetails.companyName:'', address: LeadDetails.address?LeadDetails.address:'', gst: '', type: 'Private Limited Company', incorporation: dayjs(), state: LeadDetails.state?LeadDetails.state.id:'', newlyIncorporated: true, username: '' });
        setCustomerModal(true);
    }
    const handleNewCustomer = (e) => {
        const { name, value } = e.target;
        setNewCustomer({ ...newCustomer, [name]: value });
    };

    const convertCustomer = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        const resp = await postData('customers/convert', {id: LeadDetails.id, ...newCustomer});
        if(resp){
            setCustomerModal(false);
            openSnackbar("Converted to Customer", "success");
            navigate(`/customer/${resp.data}`);
        }
        setIsSubmitting(false);
    }

    const openWaTemplateModal = async() =>{
        setWaModal(true);
        setIsLoadingWa(true);
        setWaTemplates([]);
        setWaHeaderVariables([]);
        setWaHeaderComponents(null);
        setWaHeaderDocumentName('');
        setWaHeaderMedia('');
        setWaBodyVariables([]);
        setWaBodyComponent(null);
        setWaFooterComponent(null);
        setSelectedWaTemplate(null);
        const resp = await fetchData("wapi/templates");
        if(resp){
            setWaTemplates(resp.data);
        }
        setIsLoadingWa(false);
    }

    const changeWaTemplate = (template) =>{
        setWaHeaderVariables([]);
        setWaHeaderComponents(null);
        setWaHeaderDocumentName('');
        setWaHeaderMedia('');
        setWaBodyVariables([]);
        setWaBodyComponent(null);
        setWaFooterComponent(null);
        setSelectedWaTemplate(template);
        if(!template){
            return;
        }
        const headerComponent = template.components.find(
            (component) => component.type === "HEADER"
        );
        setWaHeaderComponents(headerComponent);
        if(headerComponent && headerComponent.format === "TEXT" && headerComponent.variables.length > 0){
            setWaHeaderVariables(headerComponent.variables.map(() => ''));
        }
        const bodyComponent = template.components.find(
            (component) => component.type === "BODY"
        );
        const body = bodyComponent.text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,'<b>$1</b>')
            .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
            .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
            .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
            .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>')
            .replace(/\\n/g, '<br/>').replace(/\n/g, '<br/>');
        setWaBodyComponent({...bodyComponent, text: body});
        if(bodyComponent && bodyComponent.variables.length > 0){
            setWaBodyVariables(bodyComponent.variables.map(() => ''));
        }
        const footerComponent = template.components.find(
            (component) => component.type === "FOOTER"
        );
        setWaFooterComponent(footerComponent);
        const buttonComponent = template.components.find(
            (component) => component.type === "BUTTON"
        );
        setWaButtonComponent(buttonComponent);
    }
    const downloadFile = (mediaUrl, fileName) => {
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };
    const sendWhatsappTemplate = async() =>{
        setIsSubmitting(true);
        let obj = {
            templateName: selectedWaTemplate.name,
            language: selectedWaTemplate.language,
        }
        let templateData = {};
        if(waBodyVariables.length > 0){
            if (waBodyVariables.some(variable => variable === "")) {
                openSnackbar("Please fill all body variables", "error");
                setIsSubmitting(false);
                return;
            }
            templateData.body = {placeholders: waBodyVariables};
            obj.templateData = templateData;
        }
        if(waHeaderVariables.length > 0){
            if (waHeaderVariables.some(variable => variable === "")) {
                openSnackbar("Please fill all header variables", "error");
                setIsSubmitting(false);
                return;
            }
            templateData.header = {type: "TEXT", placeholders: waBodyVariables};
            obj.templateData = templateData;
        }else if(waHeaderComponents){
            if(waHeaderMedia !== ""){
                templateData.header = {type: waHeaderComponents.format, mediaUrl: waHeaderMedia};
                if(waHeaderComponents.format === "DOCUMENT"){
                    templateData.header = {...templateData.header, filename: waHeaderDocumentName}
                }
                obj.templateData = templateData;
            }
        }

        let resp = await postData('leads/whatsapptemplate', {obj: obj, id: leadId});
        if(resp){
            openSnackbar("Whatsapp Template Sent", "success");
            setWaModal(false);
            setNotes([]);
            getData(leadId);
        }

        setIsSubmitting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
 
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Lead Details</Typography>
                </Stack>}

                {LeadDetails && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Lead Details</Typography>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                                <Typography variant='h6'>{LeadDetails.name}</Typography>
                                {LeadDetails.converted && <Chip sx={{margin: '5px'}} label={'CUSTOMER'} color="success" />}

                                {LeadDetails.doCall && <Chip sx={{margin: '5px'}} label={'CALL'} color="info" />}

                                {LeadDetails.type === "hot" && <Chip sx={{margin: '5px'}} label={'HOT'} color="error" />}
                                {LeadDetails.type === "cold" && <Chip sx={{margin: '5px'}} label={'COLD'} color="success" />}

                                {LeadDetails.priority && LeadDetails.priority === "high" && <Chip sx={{margin: '5px'}} label={'HIGH'} color="error" />}
                                {LeadDetails.priority && LeadDetails.priority === "medium" && <Chip sx={{margin: '5px'}} label={'MEDIUM'} color="warning" />}
                                {LeadDetails.priority && LeadDetails.priority === "low" && <Chip sx={{margin: '5px'}} label={'LOW'} color="success" />}

                                {LeadDetails.response === "not interested" && <Chip label={'Not Interested'} color='error' />}
                                {LeadDetails.response === "medium" && <Chip label={'Medium'} color='warning' />}
                                {LeadDetails.response === "interested" && <Chip label={'Interested'} color='success' />}
                            </Stack>
                        </Stack>
                        <Stack gap={1} alignItems={'flex-end'}>
                            <Stack flexDirection={'row'} gap={1}>
                                {!LeadDetails.converted && <Button variant='contained' color='error' onClick={openConvertCustomer}>Convert to Customer</Button>}
                                <Button variant='contained' color='info' onClick={openFollowUpModal}>Next Followup</Button>
                                <Button variant='contained' color='warning' onClick={openEmailModal}>Emails</Button>
                                <Button variant='contained' onClick={openEditModal}>Edit</Button>
                            </Stack>
                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                <Typography fontSize={18}>Agent: {LeadDetails.assigned?LeadDetails.assigned.name: 'Not Assigned'}</Typography>
                                <Button variant='contained' color='info' onClick={()=>{setAgentModal(true)}}>Change Assign</Button>
                                <Button variant='contained' color='success' onClick={openTemplateModal}>Send Template</Button>
                                <Button variant='contained' color='success' onClick={openWaTemplateModal}>Send Whatsapp Template</Button>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack sx={{flexDirection: 'row', padding: 2, paddingTop: 0}}>
                        <Stack flex={1} gap={0.4}>
                            <Typography variant='h6'><b>Customer Name:</b> {LeadDetails.name}</Typography>
                            <Typography variant='h6'><b>Customer Phone:</b> {LeadDetails.phone}</Typography>
                            {LeadDetails.companyName && <Typography variant='h6'><b>Company Name:</b> {LeadDetails.companyName}</Typography>}
                            {LeadDetails.service && <Typography variant='h6'><b>Service:</b> {LeadDetails.service.name}</Typography>}
                            <Stack flexDirection={'row'} gap={2}>
                                <Typography variant='h6'><b>Source:</b> </Typography>
                                {LeadDetails.source === "instagram" && <Typography variant='h6'><FontAwesomeIcon icon={faInstagram} /> Instagram</Typography>}
                                {LeadDetails.source === "facebook" && <Typography variant='h6'><FontAwesomeIcon icon={faFacebook} /> Facebook</Typography>}
                                {LeadDetails.source === "whatsapp" && <Typography variant='h6'><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp</Typography>}
                                {LeadDetails.source === "others" && <Typography variant='h6'>Others</Typography>}
                            </Stack>
                            <Typography variant='h6'><b>Created:</b> {moment(LeadDetails.created).format('Do MMMM YYYY h:mm a')}</Typography>
                            {LeadDetails.lastFollowup && <Typography variant='h6'><b>Last Followup:</b> {moment(LeadDetails.lastFollowup).format('Do MMMM YYYY h:mm a')}</Typography>}
                            {LeadDetails.nextFollowup && <Typography variant='h6'><b>Next Followup:</b> {moment(LeadDetails.nextFollowup).format('Do MMMM YYYY')}</Typography>}
                        </Stack>
                        <Stack flex={1} gap={0.4}>
                            <Stack>
                                <Typography variant='h6'><b>Emails</b></Typography>
                                {LeadDetails.emails.map((email, index)=>{
                                    return <Typography key={index}>{email}</Typography>
                                })}
                            </Stack>
                            <Stack>
                                <Typography variant='h6'><b>Address</b></Typography>
                                {LeadDetails.address && <Typography>{LeadDetails.address}</Typography>}
                            </Stack>
                            {LeadDetails.state && <Typography variant='h6'><b>State:</b> {LeadDetails.state.name}</Typography>}
                        </Stack>
                    </Stack>
                </Stack>}

                {LeadDetails && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Typography variant='h5'>Lead History</Typography>
                        {LeadDetails.assigned && <Button variant='contained' onClick={openInteractionModal}>Add Interaction</Button>}
                    </Stack>
                    <Stack sx={{padding: 2, paddingTop: 0, gap: 3}}>
                        {notes.map(note=>{
                            return <Stack key={note.id}>
                                <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                    <Typography fontWeight={'bold'}>{moment(note.created).format('Do MMMM YYYY h:mm a')}</Typography>
                                    {note.call && <Chip sx={{margin: '5px'}} label={'CALLED'} color="success" />}
                                </Stack>
                                {note.agent && <Typography variant='body2'>{note.agent.name}</Typography>}
                                {!note.template && <Typography>{note.note}</Typography>}
                                {note.template && <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                    <Typography>Email Template sent - {note.template.name}</Typography>
                                    <Button size='small' variant='contained' color='success' onClick={()=>{openTemplateView(note)}}>View</Button>
                                </Stack>}
                            </Stack>
                        })}
                    </Stack>
                </Stack>}
                
            </Stack>

            <Modal open={emailModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Emails</Typography>
                        <Stack gap={1}>
                            {editEmailsList.map((email, index)=>{
                                return <Stack key={index} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body'>{email}</Typography>
                                    <Typography color='error' sx={{cursor: 'pointer'}} onClick={()=>{removeEmail(email)}}><FontAwesomeIcon icon={faTrash}/></Typography>
                                </Stack>
                            })}
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <TextField label="Add Email" type='email' value={editEmailField} onChange={(e)=>{setEditEmailField(e.target.value)}} variant="outlined" fullWidth/>
                            <Button variant='contained' color='success' onClick={addEmail}>Add</Button>
                        </Stack>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Email/>} onClick={submitEmails}><span>Update Emails</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={editLeadModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={updateLead}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Lead</Typography>
                        <TextField label="Name" name='name' value={editLead.name} onChange={handleEditLead} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={editLead.phone} onChange={handleEditLead} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>
                        <TextField label="Company Name" name='company' value={editLead.company} onChange={handleEditLead} variant="outlined" fullWidth/>
                        <FormControl fullWidth required>
                            <InputLabel>Service</InputLabel>
                            <Select label="Service" name="service" value={editLead.service} onChange={handleEditLead} variant="outlined">
                                <MenuItem value={'none'}>None</MenuItem>
                                {servicesList.map((srv) => {
                                    return <MenuItem key={srv.id} value={srv.id}>{srv.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Source</InputLabel>
                            <Select label="Source" name="source" value={editLead.source} onChange={handleEditLead} variant="outlined">
                                <MenuItem value={'instagram'}><FontAwesomeIcon icon={faInstagram} />&nbsp;&nbsp;Instagram</MenuItem>
                                <MenuItem value={'facebook'}><FontAwesomeIcon icon={faFacebook} />&nbsp;&nbsp;Facebook</MenuItem>
                                <MenuItem value={'whatsapp'}><FontAwesomeIcon icon={faWhatsapp} />&nbsp;&nbsp;Whatsapp</MenuItem>
                                <MenuItem value={'others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select label="Type" name="type" value={editLead.type} onChange={handleEditLead} variant="outlined">
                                <MenuItem value={'hot'}>Hot</MenuItem>
                                <MenuItem value={'cold'}>Cold</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Priority</InputLabel>
                            <Select label="Priority" name="priority" value={editLead.priority} onChange={handleEditLead} variant="outlined">
                                <MenuItem value={'high'}>High</MenuItem>
                                <MenuItem value={'medium'}>Medium</MenuItem>
                                <MenuItem value={'low'}>Low</MenuItem>
                                <MenuItem value={'none'}>None</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Response</InputLabel>
                            <Select label="Response" name="response" value={editLead.response} onChange={handleEditLead} variant="outlined">
                                <MenuItem value={'interested'}>Interested</MenuItem>
                                <MenuItem value={'medium'}>Medium</MenuItem>
                                <MenuItem value={'not interested'}>Not Interested</MenuItem>
                                <MenuItem value={'none'}>None</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="Address" name='address' value={editLead.address} onChange={handleEditLead} variant="outlined" fullWidth multiline rows={4}/>
                        <FormControl fullWidth>
                            <InputLabel>State</InputLabel>
                            <Select label="State" name="state" value={editLead.state} onChange={handleEditLead} variant="outlined">
                                {statesList.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<PersonPin/>}><span>Update Lead</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={agentModal} onClose={closeModal} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    p: 4,
                }}>
                    <Typography variant="h5">Add/ Change Agent</Typography>
                    <Box mt={2} mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Agent</InputLabel>
                            {LeadDetails && <Select label="Select Agent" value={selectedAgent} onChange={(e)=>{setSelectedAgent(e.target.value)}}>
                                {
                                    agentsList.map((item, index)=>{
                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>}
                        </FormControl>  
                    </Box>
                    <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<SupportAgent/>} onClick={submitAgent}><span>Update Agent</span></LoadingButton>
                </Box>
            </Modal>

            <Modal open={followupModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant="h5">Next Followup Date</Typography>
                        <FormControl fullWidth>
                            <DatePicker format='MMM DD, YYYY' label="Followup Date" value={followupDate} onChange={(newValue) => setFollowupDate(newValue)} defaultValue={dayjs()} disablePast={true} />
                        </FormControl>  
                        <FormControlLabel control={<Switch checked={doCall} onChange={(e)=>{setDoCall(e.target.checked)}} />} label="Also make a phone call?" />
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<DateRange/>} onClick={submitFollowup}><span>Set Next Followup</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={interactionModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant="h5">Add Interaction</Typography> 
                        <TextField label="Interaction Details" value={interactionDetails} onChange={(e)=>{setInteractionDetails(e.target.value)}} variant="outlined" fullWidth multiline rows={4} required/>
                        <FormControlLabel control={<Switch checked={isCall} onChange={(e)=>{setIsCall(e.target.checked)}} />} label="Phone call made?" />
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Handshake/>} onClick={submitInteraction}><span>Add Interaction</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={templateModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 500px)',width: 1000,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant="h5">Send Email Template</Typography> 
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Email Template</InputLabel>
                            <Select label="Select Email Template" value={selectedTemplate} onChange={(e)=>{setSelectedTemplate(e.target.value)}}>
                                {
                                    templateList.map((item, index)=>{
                                        return <MenuItem key={index} value={item}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>  
                        <TextField label="Subject" value={templateSubject} onChange={(e)=>{setTemplateSubject(e.target.value)}} variant="outlined" fullWidth/>
                        <Stack flexDirection={'row'} gap={2}>
                            <Chip label="{{name}}" color='info'/>
                            <Chip label="{{companyName}}" color='info'/>
                            <Chip label="{{address}}" color='info'/>
                        </Stack>
                        <Editor
                            apiKey='k11h0lei05kosgmjaodsq6on5o1hs0zxi8b222tjelc1kr0v'
                            init = {{
                                height: '600',
                                plugins: 'table accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help importcss insertdatetime link lists nonbreaking pagebreak preview quickbars save searchreplace visualblocks visualchars wordcount',
                                toolbar: 'undo redo bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify outdent indent code anchor emoticons fontsize table link bullist numlist pagebreak preview  quicklink accordion fullscreen',
                                paste_data_images: false,
                                relative_urls : false
                            }}
                            onEditorChange={(newValue, editor) => setTemplateContent(newValue)}
                            value={templateContent}
                        />
                        {selectedTemplate && selectedTemplate.attachments.length > 0 && <Stack>
                            <Typography><b>Attachments:</b></Typography>
                            {selectedTemplate.attachments.map(item=>{
                                return <Typography key={item._id}>{item.filename}</Typography>
                            })}    
                        </Stack>}
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Email/>} onClick={sendEmail}><span>Send Email</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={viewTemplateModal} onClose={()=>{setViewTemplateModal(false)}}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 500px)',width: 1000,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    {viewTemplate && <Stack gap={2}>
                        <TextField label="Subject" value={viewTemplate.subject} variant="outlined" fullWidth slotProps={{input: {readOnly: true}}} />
                        <Editor
                            apiKey='k11h0lei05kosgmjaodsq6on5o1hs0zxi8b222tjelc1kr0v'
                            init = {{
                                height: '750',
                                menubar: false,
                                toolbar: false,
                                readonly: true
                            }}
                            value={viewTemplate.content}
                        />
                    </Stack>}
                </Box>
            </Modal>

            <Modal open={customerModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '5%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={convertCustomer}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Convert to Customer</Typography>
                        
                        <TextField label="Company Name" name='company' value={newCustomer.company} onChange={handleNewCustomer} variant="outlined" fullWidth required/>

                        <TextField label="Address" name='address' value={newCustomer.address} onChange={handleNewCustomer} variant="outlined" fullWidth multiline rows={4} required/>

                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select label="State" name="state" value={newCustomer.state} onChange={handleNewCustomer} variant="outlined">
                                {statesList.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField label="GST" name='gst' value={newCustomer.gst} onChange={handleNewCustomer} variant="outlined" fullWidth/>
                        
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select label="Type" name="type" value={newCustomer.type} onChange={handleNewCustomer} variant="outlined">
                                <MenuItem value={'Private Limited Company'}>Private Limited Company</MenuItem>
                                <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                                <MenuItem value={'Public Limited Company'}>Public Limited Company</MenuItem>
                                <MenuItem value={'Section 8'}>Section 8</MenuItem>
                                <MenuItem value={'FPC'}>FPC</MenuItem>
                                <MenuItem value={'LLP'}>LLP</MenuItem>
                                <MenuItem value={'Trust'}>Trust</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label="Incorporation Date"
                            value={newCustomer.incorporation}
                            format='MMM DD, YYYY'
                            onChange={(newValue) => handleNewCustomer({ target: { name: 'incorporation', value: newValue } })}
                            defaultValue={dayjs()}
                            />
                        </LocalizationProvider>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={newCustomer.newlyIncorporated || false}
                                    onChange={(e) => handleNewCustomer({ target: { name: 'newlyIncorporated', value: e.target.checked } })}
                                    name="newlyIncorporated"
                                    color="primary"
                                />
                            }
                            label="Newly Incorporated?"
                        />

                        <TextField label="Username" name='username' value={newCustomer.username} onChange={handleNewCustomer} variant="outlined" fullWidth required/>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Convert to Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={waModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '10%', left: '10%', right: '10%', bottom: '10%', overflowY: 'auto', bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Send Whatsapp Template</Typography>
                        <Stack>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={selectedWaTemplate}
                                    onChange={(event, newValue) => {
                                        changeWaTemplate(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    options={waTemplates}
                                    getOptionLabel={(option) => `${option.name}`}
                                    renderInput={(params) => <TextField {...params} label="Select Whatsapp Template" />}
                                    />
                            </FormControl>
                            {isLoadingWa && <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Whatsapp Templates</Typography>
                            </Stack>}
                        </Stack>
                    </Stack>
                    {selectedWaTemplate && <Stack flexDirection={'row'}>
                        <Stack flex={1}>
                            {waHeaderComponents && waHeaderComponents.variables.length > 0 && <Stack mt={3} gap={1}>
                                <Typography><b>Header Variables ({waHeaderComponents.format})</b></Typography>
                                {waHeaderComponents.format !== "TEXT" && <TextField type='url' label="Media URL" value={waHeaderMedia} onChange={(e)=>{setWaHeaderMedia(e.target.value)}} variant="outlined" fullWidth />}
                                {waHeaderComponents.format === "DOCUMENT" && <TextField label="File Name" value={waHeaderDocumentName} onChange={(e)=>{setWaHeaderDocumentName(e.target.value)}} variant="outlined" fullWidth />}
                                {waHeaderVariables.map((variable, index) => (
                                    <TextField
                                        key={index}
                                        label={waHeaderComponents.variables[index].name}
                                        value={variable}
                                        onChange={(e) => {
                                            const newVariables = [...waHeaderVariables];
                                            newVariables[index] = e.target.value;
                                            setWaHeaderVariables(newVariables);
                                        }}
                                        variant="outlined"
                                        fullWidth required
                                    />
                                ))}
                            </Stack>}
                            {waBodyVariables.length > 0 && <Stack mt={3} gap={1}>
                                <Typography><b>Body Variables</b></Typography>
                                {waBodyVariables.map((variable, index) => (
                                    <TextField
                                        key={index}
                                        label={waBodyComponent.variables[index].name}
                                        value={variable}
                                        onChange={(e) => {
                                            const newVariables = [...waBodyVariables];
                                            newVariables[index] = e.target.value;
                                            setWaBodyVariables(newVariables);
                                        }}
                                        variant="outlined"
                                        fullWidth required
                                    />
                                ))}
                            </Stack>}
                            <Box mt={5}>
                                <LoadingButton fullWidth onClick={sendWhatsappTemplate} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<WhatsApp/>}><span>Send Template via Whatsapp</span></LoadingButton>
                            </Box>
                        </Stack>
                        <Stack flex={1} p={2} justifyContent={'end'} alignItems={'flex-end'}>
                            <Stack sx={{width: 400, borderRadius: '18px', borderBottomRightRadius: '4px', p: 1.5, backgroundColor: '#075e54'}} gap={1}>
                                {waHeaderComponents && <Stack>
                                    {waHeaderComponents.format === "IMAGE" && <img src={waHeaderMedia !== ""?waHeaderMedia:waHeaderComponents.variables[0].mediaUrl} alt="" style={{width: '100%', maxHeight: '500px'}} />}
                                    {waHeaderComponents.format === "VIDEO" && <video src={waHeaderMedia !== ""?waHeaderMedia:waHeaderComponents.variables[0].mediaUrl} controls style={{width: '100%',maxHeight: '500px'}} />}
                                    {waHeaderComponents.format === "DOCUMENT" && <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{borderRadius: 2, p:1, background: 'rgba(0,0,0,0.1)', color: '#fff'}}>
                                        <Typography>{waHeaderMedia !== ""?waHeaderDocumentName:waHeaderComponents.variables[0].fileName}</Typography>
                                        <IconButton onClick={()=>{downloadFile(waHeaderComponents.variables[0].mediaUrl,waHeaderComponents.variables[0].fileName)}}>
                                            <Download color='#fff'/>
                                        </IconButton>
                                    </Stack>}
                                </Stack>}
                                {waBodyComponent && <Stack>
                                    <Typography variant="body1" color={'#fff'}>
                                        {parse(waBodyComponent.text)}
                                    </Typography>
                                </Stack>}
                                {waFooterComponent && <Stack>
                                    <Typography variant="body2" color={'#eee'}>
                                        {waFooterComponent.text}
                                    </Typography>
                                </Stack>}
                                {waButtonComponent && <Stack gap={1}>
                                    {waButtonComponent.buttons.map((btn, index)=>{
                                        let startIcon = <Chat />
                                        if(btn.type === "QUICK_REPLY"){
                                            startIcon = <Reply />
                                        }else if(btn.type === "URL"){
                                            startIcon = <Public />
                                        }else if(btn.type === "PHONE_NUMBER"){
                                            startIcon = <LocalPhone/>
                                        }
                                        return <Button key={index} variant='contained' size='small' startIcon={startIcon}>{btn.text}</Button>
                                    })}    
                                </Stack>}
                            </Stack>
                        </Stack>
                    </Stack>}
                </Box>
            </Modal>

        </Stack>
    )
}

export default LeadDetails