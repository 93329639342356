import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { currency, useApiRequest } from '../store/Common';
import { Box, Button, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Email, Payment } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

function InvoiceDetails() {

    const {invoiceId} = useParams();
    const navigate = useNavigate();
    const {openSnackbar, openDialog} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [payments, setPayments] = useState([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [emailModal, setEmailModal] = useState(false);
    
    const [productWidth, setProductWidth] = useState('65%');
    const [groupedGSTData, setGroupedGSTData] = useState([]);
    const [totals, setTotals] = useState({
        totalCGST: 0,
        totalSGST: 0,
        totalIGST: 0,
        totalGST: 0,
        totalTaxable: 0
    });

    const [payType, setPayType] = useState('Cash');
    const [payAmount, setPayAmount] = useState(0);
    const [note, setNote] = useState('');
    const [payDate, setPayDate] = useState(dayjs());
    const [payModal, setPayModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const getData = useCallback(async(oid) =>{
        setIsLoading(true);
        const resp = await fetchData('invoice/details/'+oid);
        if(resp){
            setInvoiceDetails(resp.data);
            setInvoiceItems(resp.items);
            setPayments(resp.payments);
            setEmailContent(resp.emailTemplate.content);
            setEmailSubject(resp.emailTemplate.subject);
            if(resp.data.cgst > 0){
                setProductWidth('45%');
            }
            if(resp.data.igst > 0){
                setProductWidth('55%');
            }

            const groupedData = resp.items.reduce((result, item) => {
                const key = `${item.product.hsn}-${item.cgst}-${item.sgst}-${item.igst}`;
                if (!result[key]) {
                    result[key] = {
                        hsn: item.product.hsn,
                        cgst: item.cgst,
                        sgst: item.sgst,
                        igst: item.igst,
                        totalCGSTAmt: 0,
                        totalSGSTAmt: 0,
                        totalIGSTAmt: 0,
                        totalGST: 0,
                        taxableAmount: 0,
                    };
                }
                result[key].totalCGSTAmt += parseFloat(item.cgstAmt);
                result[key].totalSGSTAmt += parseFloat(item.sgstAmt);
                result[key].totalIGSTAmt += parseFloat(item.igstAmt);
                result[key].totalGST += parseFloat(parseFloat(item.cgstAmt) + parseFloat(item.sgstAmt) + parseFloat(item.igstAmt));
                result[key].taxableAmount += parseFloat(item.professional);
                return result;
            }, {});
            const groupedArray = Object.values(groupedData);
            // Calculate Totals
            const totalData = groupedArray.reduce(
                (totals, item) => {
                    totals.totalCGST += parseFloat(item.totalCGSTAmt);
                    totals.totalSGST += parseFloat(item.totalSGSTAmt);
                    totals.totalIGST += parseFloat(item.totalIGSTAmt);
                    totals.totalGST += parseFloat(item.totalGST);
                    totals.totalTaxable += parseFloat(item.taxableAmount);
                    return totals;
                },
                { totalCGST: 0, totalSGST: 0, totalIGST: 0, totalGST: 0, totalTaxable: 0 }
            );
            setGroupedGSTData(groupedArray);
            setTotals(totalData);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getData(invoiceId);
    },[getData, invoiceId]);

    const openPayModal = () =>{
        setPayType('Cash');
        setNote('');
        setPayDate(dayjs());
        setPayAmount(invoiceDetails?invoiceDetails.due:0);
        setPayModal(true);
    }
    const closeModal = () =>{
        if(isSubmitting){
            return;
        }
        setPayModal(false);
        setEmailModal(false);
    }
    const addPayment = async(e) =>{
        e.preventDefault();
        if(isNaN(payAmount) || payAmount <= 0 || !payDate){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('invoice/payment', {id: invoiceId, payType: payType, amount: payAmount, note: note, payDate: payDate});
        if(resp){
            setPayModal(false);
            setPayments([]);
            openSnackbar("Payment Added", "success");
            getData(invoiceId);
        }
        setIsSubmitting(false);
    }

    const removePayment = (payment) =>{
        if(isDeleting){
            openSnackbar('Wait for previous remove request to complete');
            return;
        }
        openDialog("Delete Payment?", `Amount: ${currency} ${parseFloat(payment.amount).toFixed(2)}`, confirmRemovePayment, {pid: payment.id, id: invoiceId});
    }
    const confirmRemovePayment = async(obj) =>{
        const tid = toast.loading('Deleting Payment',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await postData('invoice/deletepayment', obj);
        if(resp){
            toast.update(tid, { render: "Payment Deleted", type: "success", isLoading: false, autoClose: 2000 });
            setPayments([]);
            getData(invoiceId);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    const removeInvoice = () =>{
        if(isDeleting){
            openSnackbar('Wait for previous remove request to complete');
            return;
        }
        openDialog("Delete this Invoice Permanently?", `This will also delete all invoice items and invoice payments. This cannot be undone`, confirmRemoveInvoice, {id: invoiceId});
    }
    const confirmRemoveInvoice = async(obj) =>{
        const tid = toast.loading('Deleting Invoice',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await postData('invoice/deleteinvoice', obj);
        if(resp){
            toast.update(tid, { render: "Invoice Deleted", type: "success", isLoading: false, autoClose: 2000 });
            navigate('/invoices')
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    const sendEmail = async() => {
        if(emailContent === "" || emailSubject === ""){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('invoice/sendemail', {id: invoiceId, content: emailContent, subject: emailSubject});
        if(resp){
            setEmailModal(false);
            openSnackbar("Email Sent", "success");
        }
        setIsSubmitting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Invoice Details</Typography>
                </Stack>}
                {invoiceDetails && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Invoice Details</Typography>
                        </Stack>
                        <Stack flexDirection={'row'} gap={1}>
                            {invoiceDetails.customer.emails.length > 0 && <Button variant='contained' color='success' onClick={()=>{setEmailModal(true)}}>Email Invoice</Button>}
                            {invoiceDetails.due > 0 && <Button variant='contained' color='warning' onClick={openPayModal}>Add Payment</Button>}
                            <Button variant='contained' color='error' onClick={removeInvoice}>Delete Invoice</Button>
                        </Stack>
                    </Stack>

                    <Stack sx={{flexDirection: 'row', padding: 2, paddingTop: 0}}>
                        <Stack flex={1} gap={2}>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Invoice No</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.invoiceNo}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Invoice Date</Typography>
                                <Typography fontWeight={'bold'}>{moment(invoiceDetails.invDate).format('Do MMM YYYY')}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Place of Supply</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.state.code} - {invoiceDetails.customer.state.name}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Company Name</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.companyName}</Typography>
                            </Box>
                            {invoiceDetails.customer.gst && <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>GST</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.gst}</Typography>
                            </Box>}
                        </Stack>
                        <Stack flex={1} gap={2}>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Customer Name</Typography>
                                <Box width={'fit-content'}>
                                    <Link to={`/customer/${invoiceDetails.customer.id}`} style={{textDecoration: 'none'}}>
                                        <Typography fontWeight={'bold'} color='#000'>{invoiceDetails.customer.name}</Typography>
                                    </Link>
                                </Box>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Customer Phone</Typography>
                                <Typography fontWeight={'bold'}>+91 {invoiceDetails.customer.phone}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Emails</Typography>
                                {invoiceDetails.customer.emails.map((email, index)=>{
                                    return <Typography fontWeight={'bold'} key={index}>{email}</Typography>
                                })}
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Customer Address</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.address}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>}

                {invoiceItems.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Invoice Items</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ paddingY: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2} px={2}>
                            <Box width={'5%'}>
                                <Typography><b>No</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>HSN/ SAC</b></Typography>
                            </Box>
                            <Box width={productWidth}>
                                <Typography><b>Product</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Price</b></Typography>
                            </Box>
                            {invoiceDetails.cgst > 0 && <Box width={'10%'}>
                                <Typography><b>CGST</b></Typography>
                            </Box>}
                            {invoiceDetails.sgst > 0 && <Box width={'10%'}>
                                <Typography><b>SGST</b></Typography>
                            </Box>}
                            {invoiceDetails.igst > 0 && <Box width={'10%'}>
                                <Typography><b>IGST</b></Typography>
                            </Box>}
                            <Box width={'10%'}>
                                <Typography><b>Amount</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                            {invoiceItems.map((item, index)=>{
                                return <Stack key={item.id} flexDirection='row' gap={1} p={1.2} px={2} sx={{borderBottom: '1px solid #e2e3e5', color: '#000'}}>
                                    <Box width={'5%'}>
                                        <Typography>{index + 1}</Typography>
                                    </Box>
                                    <Box width={'10%'}>
                                        <Typography>{item.product.hsn}</Typography>
                                    </Box>
                                    <Box width={productWidth} pr={4}>
                                        <Typography>{item.itemName}</Typography>
                                        {item.govt > 0 && <Stack alignItems={'flex-end'} gap={0.5}>
                                            <Typography>Professional Fees: {currency} {parseFloat(item.professional).toFixed(2)}</Typography>
                                            <Typography>Government Fees: {currency} {parseFloat(item.govt).toFixed(2)}</Typography>
                                        </Stack>}
                                    </Box>
                                    <Box width={'10%'}>
                                        <Typography>{currency} {parseFloat(item.price).toFixed(2)}</Typography>
                                    </Box>
                                    {invoiceDetails.cgst > 0 && <Stack width={'10%'}>
                                        <Typography>{currency} {parseFloat(item.cgstAmt).toFixed(2)}</Typography>
                                        <Typography variant='body2'>@ {item.cgst}%</Typography>
                                    </Stack>}
                                    {invoiceDetails.sgst > 0 && <Stack width={'10%'}>
                                        <Typography>{currency} {parseFloat(item.sgstAmt).toFixed(2)}</Typography>
                                        <Typography variant='body2'>@ {item.sgst}%</Typography>
                                    </Stack>}
                                    {invoiceDetails.igst > 0 && <Stack width={'10%'}>
                                        <Typography>{currency} {parseFloat(item.igstAmt).toFixed(2)}</Typography>
                                        <Typography variant='body2'>@ {item.igst}%</Typography>
                                    </Stack>}
                                    <Box width={'10%'}>
                                        <Typography>{currency} {parseFloat(item.total).toFixed(2)}</Typography>
                                    </Box>
                                </Stack>
                            })}
                        </Stack>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2} px={2} mt={1}>
                            <Box width={productWidth}>
                                <Typography><b>TOTAL</b></Typography>
                            </Box>
                            <Box width={'15%'}></Box>
                            <Box width={'10%'}>
                                <Typography>{currency} {parseFloat(invoiceDetails.price).toFixed(2)}</Typography>
                            </Box>
                            {invoiceDetails.cgst > 0 && <Box width={'10%'}>
                                <Typography>{currency} {parseFloat(invoiceDetails.cgst).toFixed(2)}</Typography>
                            </Box>}
                            {invoiceDetails.sgst > 0 && <Box width={'10%'}>
                                <Typography>{currency} {parseFloat(invoiceDetails.sgst).toFixed(2)}</Typography>
                            </Box>}
                            {invoiceDetails.igst > 0 && <Box width={'10%'}>
                                <Typography>{currency} {parseFloat(invoiceDetails.igst).toFixed(2)}</Typography>
                            </Box>}
                            <Box width={'10%'}>
                                <Typography>{currency} {parseFloat(invoiceDetails.total).toFixed(2)}</Typography>
                            </Box>
                        </Stack>
                    </Stack>

                </Stack>}

                {payments.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Payments</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ paddingY: 1, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2} px={2}>
                            <Box width={'5%'}>
                                <Typography><b>No</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Date</b></Typography>
                            </Box>
                            <Box width={'20%'}>
                                <Typography><b>Amount</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Type</b></Typography>
                            </Box>
                            <Box width={'40%'}>
                                <Typography><b>Note</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Delete</b></Typography>
                            </Box>
                        </Stack>
                        <Stack>
                            {payments.map((item, index)=>{
                                return <Stack key={item.id} flexDirection='row' gap={1} p={1.2} px={2} sx={{borderBottom: '1px solid #e2e3e5', color: '#000'}} alignItems={'center'}>
                                    <Box width={'5%'}>
                                        <Typography>{index + 1}</Typography>
                                    </Box>
                                    <Box width={'15%'}>
                                        <Typography>{moment(item.payDate).format('Do MMM YYYY')}</Typography>
                                    </Box>
                                    <Box width={'20%'} pr={4}>
                                        <Typography>{currency} {parseFloat(item.amount).toFixed(2)}</Typography>
                                    </Box>
                                    <Box width={'10%'}>
                                        <Typography>{item.payType}</Typography>
                                    </Box>
                                    <Box width={'40%'}>
                                        <Typography>{item.note}</Typography>
                                    </Box>
                                    <Box width={'10%'}>
                                        <Button size='small' color='error' variant='contained' onClick={()=>{removePayment(item)}}>Delete</Button>
                                    </Box>
                                </Stack>
                            })}
                        </Stack>
                    </Stack>

                </Stack>}

                {invoiceItems.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Totals</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ padding: 2, paddingTop: 0}} flexDirection={'row'}>
                        <Stack flex={1}>
                            {invoiceDetails.gst > 0 && <Stack flexDirection={'row'} gap={2} px={1} py={2} sx={{borderBottom: '2px solid #e5e7eb;'}}>
                                <Box width={'20%'}>
                                    <Typography><b>HSN/ SAC</b></Typography>
                                </Box>
                                <Box width={'20%'}>
                                    <Typography><b>Taxable Amount</b></Typography>
                                </Box>
                                {invoiceDetails.cgst > 0 && <Box width={'20%'}>
                                    <Typography><b>CGST</b></Typography>
                                </Box>}
                                {invoiceDetails.sgst > 0 && <Box width={'20%'}>
                                    <Typography><b>SGST</b></Typography>
                                </Box>}
                                {invoiceDetails.igst > 0 && <Box width={'20%'}>
                                    <Typography><b>IGST</b></Typography>
                                </Box>}
                                <Box width={'20%'}>
                                    <Typography><b>Total GST</b></Typography>
                                </Box>
                            </Stack>}
                            {invoiceDetails.gst > 0 && <Stack>
                                {groupedGSTData.map((item, index) => (
                                    <Stack key={index} flexDirection={'row'} gap={2} py={1} px={1} sx={{borderBottom: '1px solid #e5e7eb;'}}>
                                         <Box width={'20%'}>
                                            <Typography>{item.hsn}</Typography>
                                        </Box>
                                        <Box width={'20%'}>
                                            <Typography>{currency} {parseFloat(item.taxableAmount).toFixed(2)}</Typography>
                                        </Box>
                                        {invoiceDetails.cgst > 0 && <Stack width={'20%'}>
                                            <Typography>{currency} {parseFloat(item.totalCGSTAmt).toFixed(2)}</Typography>
                                            <Typography variant='body2'>@ {item.cgst}%</Typography>
                                        </Stack>}
                                        {invoiceDetails.sgst > 0 && <Stack width={'20%'}>
                                            <Typography>{currency} {parseFloat(item.totalSGSTAmt).toFixed(2)}</Typography>
                                            <Typography variant='body2'>@ {item.sgst}%</Typography>
                                        </Stack>}
                                        {invoiceDetails.igst > 0 && <Stack width={'20%'}>
                                            <Typography>{currency} {parseFloat(item.totalIGSTAmt).toFixed(2)}</Typography>
                                            <Typography variant='body2'>@ {item.igst}%</Typography>
                                        </Stack>}
                                        <Box width={'20%'}>
                                            <Typography>{currency} {parseFloat(item.totalGST).toFixed(2)}</Typography>
                                        </Box>
                                    </Stack>
                                ))}
                            </Stack>}
                            {invoiceDetails.gst > 0 && <Stack flexDirection={'row'} gap={2} px={1} py={2} sx={{borderBottom: '2px solid #e5e7eb;'}}>
                                <Box width={'20%'}>
                                    <Typography><b>TOTAL</b></Typography>
                                </Box>
                                <Box width={'20%'}>
                                    <Typography>{currency} {parseFloat(totals.totalTaxable).toFixed(2)}</Typography>
                                </Box>
                                {invoiceDetails.cgst > 0 && <Box width={'20%'}>
                                    <Typography>{currency} {parseFloat(totals.totalCGST).toFixed(2)}</Typography>
                                </Box>}
                                {invoiceDetails.sgst > 0 && <Box width={'20%'}>
                                    <Typography>{currency} {parseFloat(totals.totalSGST).toFixed(2)}</Typography>
                                </Box>}
                                {invoiceDetails.igst > 0 && <Box width={'20%'}>
                                    <Typography>{currency} {parseFloat(totals.totalIGST).toFixed(2)}</Typography>
                                </Box>}
                                <Box width={'20%'}>
                                    <Typography>{currency} {parseFloat(totals.totalGST).toFixed(2)}</Typography>
                                </Box>
                            </Stack>}
                        </Stack>
                        <Stack flex={1} gap={2} alignItems={'flex-end'} justifyContent={'flex-end'}>
                            <table style={{fontSize: 18}}>
                                <tbody>
                                    <tr>
                                        <td style={{paddingRight: '30px'}}><b>Sub Total</b></td>
                                        <td><b>{currency} {parseFloat(invoiceDetails.price).toFixed(2)}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>GST</b></td>
                                        <td><b>{currency} {parseFloat(invoiceDetails.gst).toFixed(2)}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td><b>{currency} {parseFloat(invoiceDetails.total).toFixed(2)}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Paid</b></td>
                                        <td><b>{currency} {parseFloat(parseFloat(invoiceDetails.total) - parseFloat(invoiceDetails.due)).toFixed(2)}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Due</b></td>
                                        <td><b>{currency} {parseFloat(invoiceDetails.due).toFixed(2)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Stack>
                    </Stack>
                </Stack>}

            </Stack>

            <Modal open={payModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '10%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={addPayment}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add Payment</Typography>
                        <TextField label="Pay Amount" type='number' value={payAmount} onChange={(e)=>{setPayAmount(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>
                        <TextField label="Note" value={note} onChange={(e)=>{setNote(e.target.value)}} variant="outlined" fullWidth multiline rows={4} />
                        <FormControl fullWidth required>
                            <InputLabel>Payment Mode</InputLabel>
                            <Select label="Payment Mode" value={payType} onChange={(e)=>{setPayType(e.target.value)}} variant="outlined">
                                <MenuItem value={'Cash'}>Cash</MenuItem>
                                <MenuItem value={'Card'}>Card</MenuItem>
                                <MenuItem value={'UPI'}>UPI</MenuItem>
                                <MenuItem value={'Net Banking'}>Net Banking</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <DatePicker format='MMM DD, YYYY' label="Payment Date" value={payDate} onChange={(newValue) => setPayDate(newValue)} defaultValue={dayjs()} disableFuture={true} />
                        </FormControl>  
                        <LoadingButton fullWidth type='submit' variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Payment/>} ><span>Add Payment</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={emailModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 500px)',width: 1000,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant="h5">Send Email</Typography> 
                        <TextField label="Subject" value={emailSubject} onChange={(e)=>{setEmailSubject(e.target.value)}} variant="outlined" fullWidth/>
                        <Stack flexDirection={'row'} gap={2}>
                            <Chip label="{{name}}" color='info'/>
                            <Chip label="{{companyName}}" color='info'/>
                            <Chip label="{{address}}" color='info'/>
                            <Chip label="{{invoiceNo}}" color='info'/>
                            <Chip label="{{invoiceDate}}" color='info'/>
                            <Chip label="{{invoiceAmount}}" color='info'/>
                        </Stack>
                        <Editor
                            apiKey='k11h0lei05kosgmjaodsq6on5o1hs0zxi8b222tjelc1kr0v'
                            init = {{
                                height: '600',
                                plugins: 'table accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help importcss insertdatetime link lists nonbreaking pagebreak preview quickbars save searchreplace visualblocks visualchars wordcount',
                                toolbar: 'undo redo bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify outdent indent code anchor emoticons fontsize table link bullist numlist pagebreak preview  quicklink accordion fullscreen',
                                paste_data_images: false,
                                relative_urls : false
                            }}
                            onEditorChange={(newValue, editor) => setEmailContent(newValue)}
                            value={emailContent}
                        />
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Email/>} onClick={sendEmail}><span>Send Email</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default InvoiceDetails