import LeadsPage from './pages/LeadsPage';
import { createTheme, CssBaseline, Stack, ThemeProvider, Typography } from '@mui/material';
import CustomersPage from './pages/CustomersPage';
import { Link, Route, Routes } from 'react-router-dom';
import AddInvoice from './pages/AddInvoice';
import LeadDetails from './pages/LeadDetails';
import TemplatePage from './pages/TemplatePage';
import CustomerDetails from './pages/CustomerDetails';
import ServicesPage from './pages/ServicesPage';
import InvoicePage from './pages/InvoicePage';
import RecurringInvoicePage from './pages/RecurringInvoicePage';
import InvoiceDetails from './pages/InvoiceDetails';
import RecurringInvoiceDetails from './pages/RecurringInvoiceDetails';
import ComplianceSettings from './pages/ComplianceSettings';
import UsersPage from './pages/UsersPage';
import PaymentsPage from './pages/PaymentsPage';
import CompliancePage from './pages/CompliancePage';
import SettingsPage from './pages/SettingsPage';
import AccountantJobs from './pages/AccountantJobs';
import { connect, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useApiRequest } from './store/Common';
import { updateLogin, updateName } from './store/slices/configSlice';
import { useCallback, useEffect } from 'react';
import Login from './pages/Login';

function App({loggedIn}) {

  const dispatch = useDispatch();
  const {fetchData} = useApiRequest();

  const getConfig = useCallback(async() =>{
    let session = localStorage.getItem('session');
    if(!session){
      dispatch(updateLogin(false));
    }else{
      const resp = await fetchData('app/config');
      if(resp){
        dispatch(updateName(resp.data.name));
        dispatch(updateLogin(true));
      }
    }
  },[dispatch, fetchData]);

  useEffect(()=>{
    getConfig();
  },[getConfig]);

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {main: '#fbb755'}
    }
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline/>

      {loggedIn === null && <Stack sx={{height: '100vh', color: '#000', justifyContent: 'center', alignItems: 'center', gap:5}}>
        <img src='/assets/logoNw.png' alt='Startup Station' width='300px' />
        <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
          <ClipLoader size={15} color='#000' />
          <Typography variant='h6'>Loading StartupStation CRM</Typography>
        </Stack>
      </Stack>}

      {loggedIn === false && <Stack sx={{height: '100vh'}}>
        <Login />
      </Stack>}

      {loggedIn && <Stack flexDirection={'row'} height={'100vh'}>
        <Stack width={250}>
          <Link to={'/leads'}>Leads</Link>
          <Link to={'/customers'}>customers</Link>
          <Link to={'/services'}>Services</Link>
          <Link to={'/compliances'}>Compliances</Link>
          <Link to={'/addinvoice'}>Add Invoice</Link>
          <Link to={'/invoices'}>Invoice</Link>
          <Link to={'/payments'}>Payments</Link>
          <Link to={'/recurringinvoices'}>Recurring Invoice</Link>
          <Link to={'/templates'}>Template</Link>
          <Link to={'/accountantjobs'}>Accountant Jobs</Link>
          <Link to={'/users'}>Users</Link>
          <Link to={'/compliancesettings'}>Compliance Settings</Link>
          <Link to={'/settings'}>Settings</Link>
        </Stack>
        <Stack flexGrow={1} height={'100%'}>
          <Routes>
            <Route path='/leads' element={<LeadsPage/>} />
            <Route path='/lead/:leadId' element={<LeadDetails/>} />
            <Route path='/customers' element={<CustomersPage/>} />
            <Route path='/customer/:customerId' element={<CustomerDetails/>} />
            <Route path='/services' element={<ServicesPage/>} />
            <Route path='/addinvoice' element={<AddInvoice/>} />
            <Route path='/invoices' element={<InvoicePage/>} />
            <Route path='/invoice/:invoiceId' element={<InvoiceDetails/>} />
            <Route path='/recurringinvoices' element={<RecurringInvoicePage/>} />
            <Route path='/recurringinvoice/:invoiceId' element={<RecurringInvoiceDetails/>} />
            <Route path='/payments' element={<PaymentsPage/>} />
            <Route path='/templates' element={<TemplatePage/>} />
            <Route path='/compliancesettings' element={<ComplianceSettings/>} />
            <Route path='/users' element={<UsersPage/>} />
            <Route path='/compliances' element={<CompliancePage/>} />
            <Route path='/settings' element={<SettingsPage/>} />
            <Route path='/accountantjobs' element={<AccountantJobs/>} />
          </Routes>
        </Stack>
      </Stack>}
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  loggedIn: state.configs.loggedIn
});
export default connect(mapStateToProps)(App);
