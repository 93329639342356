import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useApiRequest } from '../store/Common';
import { useCommonUI } from '../context/UI';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { CalendarMonth, TaskAlt } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

function ComplianceSettings() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const [isLoading, setIsLoading] = useState(false);
    const [isComplianceLoading, setIsComplianceLoading] = useState(false);
    const [financialYearLists, setFinancialYearLists] = useState([]);
    const [complianceLists, setComplianceLists] = useState([]);
    const [templateLists, setTemplateLists] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState('');
    const [currentFinancialYear, setCurrentFinancialYear] = useState(null);
    const [updateFinancialYear, setUpdateFinancialYear] = useState(null);

    const [addFyModal, setAddFyModal] = useState(false);
    const [financialYearName, setFinancialYearName] = useState('');
    const [complianceData, setComplianceData] = useState({id: null, name: '', expiry: dayjs(), new: false, expiryTemplate: '', completeTemplate: ''});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [complianceModal, setComplianceModal] = useState(false);

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('template/list');
            if(resp){
                setTemplateLists(resp.data);
            }
        }
        getLists();
    },[fetchData]);

    const getData = useCallback(async() =>{
        setIsLoading(true);
        setFinancialYearLists([]);
        const resp = await fetchData('settings/financialyears');
        if(resp){
            setFinancialYearLists(resp.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getData();
    },[getData]);

    const loadCompliances = async() =>{
        if(selectedFinancialYear === ""){
            return;
        }
        setCurrentFinancialYear(selectedFinancialYear);
        setIsComplianceLoading(true);
        setComplianceLists([]);
        const resp = await fetchData('settings/compliances/'+selectedFinancialYear);
        if(resp){
            setComplianceLists(resp.data);
        }
        setIsComplianceLoading(false);
    }


    const openAddFyModal = () =>{
        setUpdateFinancialYear(null);
        setFinancialYearName('');
        setAddFyModal(true);
    }
    const openEditFyModal = (fy) =>{
        setUpdateFinancialYear(fy.id);
        setFinancialYearName(fy.name);
        setAddFyModal(true);
    }

    const openComplianceModal = () =>{
        setComplianceData({id: null, name: '', expiry: dayjs(), newInc: false, expiryTemplate: 'None', completeTemplate: 'None'});
        setComplianceModal(true);
    }
    const editComplianceModal = (compliance) =>{
        setComplianceData({
            id: compliance.id,
            name: compliance.name, 
            expiry: dayjs(compliance.expiry), 
            newInc: compliance.new, 
            expiryTemplate: compliance.expiryTemplate?compliance.expiryTemplate.id:'None', 
            completeTemplate: compliance.completeTemplate?compliance.completeTemplate.id:'None'
        });
        setComplianceModal(true);
    }

    const closeModal = () =>{
        if(isSubmitting){
            return;
        }
        setAddFyModal(false);
        setComplianceModal(false);
    }

    const handleCompliance = (e) =>{
        const { name, value } = e.target;
        setComplianceData({ ...complianceData, [name]: value });
    }
    
    const submitFy = async() =>{
        if(financialYearName === ""){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('settings/financialyear', {id: updateFinancialYear, name: financialYearName});
        if(resp){
            setAddFyModal(false);
            openSnackbar(updateFinancialYear?"Financial Year Updated":"Financial Year Added", "success");
            getData();
        }
        setIsSubmitting(false);
    }
    const updateCompliance = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        const resp = await postData('settings/compliance', {...complianceData, financialYear: currentFinancialYear});
        if(resp){
            setComplianceModal(false);
            openSnackbar(complianceData.id?"Compliance Updated":"Compliance Added", "success");
            loadCompliances();
        }
        setIsSubmitting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Financial Years</Typography>
                </Stack>}

                <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection:'row', alignItems:'center' ,justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}} gap={2}>
                        <Typography variant='h5'>Financial Year Settings</Typography>
                        <Button variant='contained' color='warning' onClick={openAddFyModal}>Add Financial Year</Button>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'85%'}>
                                <Typography><b>Financial Year</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Modify</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {financialYearLists.map(fy=>{
                            return <Paper key={fy.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'85%'}>
                                        <Typography color='#000'>{fy.name}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        <Button size='small' variant='contained' color='success' onClick={()=>{openEditFyModal(fy)}}>Modify</Button>
                                    </Box>
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>
                </Stack>

                <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection:'row', alignItems:'center' ,justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}} gap={2}>
                        <Typography variant='h5'>Annual Compliances Settings</Typography>
                        {financialYearLists.length > 0 && <Stack flexDirection={'row'} gap={1}>
                            <Box width={250}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-label">Select Financial Year</InputLabel>
                                    <Select value={selectedFinancialYear} onChange={(e)=>{setSelectedFinancialYear(e.target.value)}} label="Select Financial Year">
                                        {financialYearLists.map(fy=>{  
                                            return <MenuItem key={fy.id} value={fy.id}>{fy.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Button variant='contained' color='success' onClick={loadCompliances}>Load</Button>
                        </Stack>}
                        {currentFinancialYear && <Button variant='contained' color='warning' onClick={openComplianceModal}>Add Compliance</Button>}
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        {isComplianceLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <ClipLoader size={15} color='#000' />
                            <Typography variant='body'>Loading Compliance Details</Typography>
                        </Stack>}
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'35%'}>
                                <Typography><b>Compliance Name</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Expiry Date</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>New</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Expiry Template</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Complete Template</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Modify</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {complianceLists.map(compliance=>{
                            return <Paper key={compliance.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'35%'}>
                                        <Typography color='#000'>{compliance.name}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        {compliance.expiry && <Typography>{moment(compliance.expiry).format('Do MMM YYYY')}</Typography>}
                                    </Box>  
                                    <Box width={'10%'}>
                                        <Typography>{compliance.new?"Yes":"No"}</Typography>
                                    </Box>   
                                    <Box width={'15%'}>
                                        {compliance.expiryTemplate && <Typography>{compliance.expiryTemplate.name}</Typography>}
                                    </Box>
                                    <Box width={'15%'}>
                                        {compliance.completeTemplate && <Typography>{compliance.completeTemplate.name}</Typography>}
                                    </Box>     
                                    <Box width={'15%'}>
                                        <Button size='small' variant='contained' color='success' onClick={()=>{editComplianceModal(compliance)}}>Modify</Button>
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>
                </Stack>

            </Stack>

            <Modal open={addFyModal} onClose={closeModal} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: 400,
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    p: 4,
                }}>
                    <Typography variant="h5">{updateFinancialYear?"Edit":"Add"} Financial Year</Typography>
                    <Stack mt={2} mb={2} gap={2}>
                        <TextField label="Financial Year" value={financialYearName} onChange={(e)=>{setFinancialYearName(e.target.value)}} variant="outlined" fullWidth required/>
                    </Stack>
                    <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<CalendarMonth/>} onClick={submitFy}><span>{updateFinancialYear?"Update":"Add"} Financial Year</span></LoadingButton>
                </Box>
            </Modal>

            <Modal open={complianceModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={updateCompliance}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{complianceData.id?"Edit":"Add"} Compliance</Typography>
                        <TextField name='name' label="Compliance Name" value={complianceData.name} onChange={handleCompliance} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <DatePicker format='MMM DD, YYYY' label="Expiry Date" value={complianceData.expiry} onChange={(newValue) => handleCompliance({ target: { name: 'expiry', value: newValue } })} defaultValue={dayjs()} />
                        </FormControl>  

                        <FormControl fullWidth required>
                            <InputLabel id="select-label">Inc20?</InputLabel>
                            <Select name='newInc' label="Inc20?" value={complianceData.newInc} onChange={handleCompliance}>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel id="select-label">Expiry Template</InputLabel>
                            <Select name='expiryTemplate' label="Expiry Template" value={complianceData.expiryTemplate} onChange={handleCompliance}>
                                <MenuItem value={'None'}>None</MenuItem>
                                {templateLists.map((template)=>{
                                    return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel id="select-label">Complete Template</InputLabel>
                            <Select name='completeTemplate' label="Complete Template" value={complianceData.completeTemplate} onChange={handleCompliance}>
                                <MenuItem value={'None'}>None</MenuItem>
                                {templateLists.map((template)=>{
                                    return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<TaskAlt/>}><span>{complianceData.id?"Update":"Add"} Compliance</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default ComplianceSettings