import { Autocomplete, Box, Button, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { Receipt } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import { useNavigate } from 'react-router-dom';

function AddInvoice() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const navigate = useNavigate();
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [productsList, setProductsList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('none');
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [invDate, setInvDate] = useState(dayjs());
    const [invRecurring, setInvRecurring] = useState(false);
    const [invInternal, setInvInternal] = useState(0);
    const [invIntervalType, setInvIntervalType] = useState('month');
    const [invEndDate, setInvEndDate] = useState(dayjs());

    const [totalPrice, setTotalPrice] = useState(0);
    const [totalGst, setTotalGst] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(()=>{
        const getProducts = async() =>{
            const resp = await fetchData('services/list');
            const resp1 = await fetchData("invoice/customers");
            if(resp && resp1){
                setProductsList(resp.data);
                setCustomerList(resp1.data);
            }
        }
        getProducts();
    },[fetchData]);

    const addInvoiceItem = () =>{
        if(selectedProduct === "none"){
            return;
        }
        let obj = {
            product: selectedProduct.id,
            name: selectedProduct.name,
            professional: selectedProduct.professional,
            govt: selectedProduct.govt,
            price: parseFloat(parseFloat(selectedProduct.professional) + parseFloat(selectedProduct.govt)).toFixed(2),
            gst: 0,
            gstValue: parseFloat((0/100)*(parseFloat(selectedProduct.professional))).toFixed(2),
            amount: parseFloat(parseFloat(selectedProduct.professional) + parseFloat(selectedProduct.govt) + parseFloat((0/100)*(parseFloat(selectedProduct.professional)))).toFixed(2)
        }
        setInvoiceItems((prevItems)=> [...prevItems, obj]);
        setSelectedProduct('none');
    }

    const editInvoiceItem = (index, fieldName, value) => {
        setInvoiceItems((prevItems) =>
            prevItems.map((item, i) => {
                if (i === index) {
                    const updatedItem = { ...item, [fieldName]: value };
                    updatedItem.price = parseFloat(parseFloat(updatedItem.govt) + parseFloat(updatedItem.professional)).toFixed(2);
                    const gstValue = parseFloat((parseFloat(updatedItem.gst)/100)*parseFloat(updatedItem.professional)).toFixed(2);
                    updatedItem.gstValue = gstValue;
                    updatedItem.amount = parseFloat(parseFloat(updatedItem.price) + parseFloat(gstValue)).toFixed(2);
                    return updatedItem;
                }
                return item;
            })
        );
    };
    const removeInvoiceItem = (index) => {
        setInvoiceItems((prevItems) =>
            prevItems.filter((item, i) => i !== index)
        );
    }

    const createInvoice = async() =>{
        if(!selectedCustomer || !invDate || invoiceItems.length === 0){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('invoice/add', {cid: selectedCustomer.id, invDate: invDate, items: invoiceItems, recurring: invRecurring, end: invEndDate, interval: invInternal, intervalType: invIntervalType});
        if(resp){
            openSnackbar("Invoice Created", "success");
            navigate('/invoices')
        }
        setIsSubmitting(false);
    }

    useEffect(()=>{
        let totalPrice = 0;
        let totalGst = 0;
        let totalAmount = 0;

        invoiceItems.forEach(item => {
            const price = parseFloat(item.professional) + parseFloat(item.govt);
            totalPrice += parseFloat(price);
            const gst = parseFloat(((parseFloat(item.gst) / 100) * parseFloat(item.professional)).toFixed(2));
            totalGst += gst;
            totalAmount += parseFloat(price) + parseFloat(gst);
        });

        setTotalPrice(totalPrice.toFixed(2));
        setTotalGst(totalGst.toFixed(2));
        setTotalAmount(totalAmount.toFixed(2));
    },[invoiceItems]);

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>

                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Create Invoice</Typography>
                </Stack>

                <Stack mb={3} mt={3}>
                    <Paper elevation={3}>
                        <Stack p={2} gap={2}>
                            <Typography variant='h6'>Invoice Details</Typography>
                            <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                <Box width={'20%'}>
                                    <Typography>Select Customer</Typography>
                                </Box>
                                <Box width={'80%'}>
                                    <Autocomplete
                                        value={selectedCustomer}
                                        onChange={(event, newValue) => {
                                            setSelectedCustomer(newValue);
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        options={customerList}
                                        getOptionLabel={(option) => `${option.name} - ${option.companyName}`}
                                        renderInput={(params) => <TextField {...params} label="Select Customer" />}
                                        />
                                </Box>
                            </Stack>
                            <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                <Box width={'20%'}>
                                    <Typography>Invoice {invRecurring?"Start":''} Date</Typography>
                                </Box>
                                <Box width={'80%'}>
                                    <FormControl fullWidth>
                                        <DatePicker format='MMMM DD, YYYY' label={invRecurring?"Invoice Start Date":"Invoice Date"} value={invDate} onChange={(newValue) => setInvDate(newValue)} defaultValue={dayjs()} disableFuture={true} />
                                    </FormControl>  
                                </Box>
                            </Stack>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={invRecurring || false}
                                            onChange={(e) => setInvRecurring(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Recurring Invoice?"
                                />
                            </Box>
                            {invRecurring && <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                <Box width={'20%'}>
                                    <Typography>Interval</Typography>
                                </Box>
                                <Box width={'80%'}>
                                    <TextField label="Interval" type='number' value={invInternal} onChange={(e)=>{setInvInternal(e.target.value)}} variant="outlined" fullWidth required/> 
                                </Box>
                            </Stack>}
                            {invRecurring && <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                <Box width={'20%'}>
                                    <Typography>Interval Type</Typography>
                                </Box>
                                <Box width={'80%'}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-label">Interval Type</InputLabel>
                                        <Select label="Interval Type" value={invIntervalType} onChange={(e)=>{setInvIntervalType(e.target.value)}}>
                                            <MenuItem value={'day'}>Day</MenuItem>
                                            <MenuItem value={'month'}>Month</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Stack>}
                            {invRecurring && <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                <Box width={'20%'}>
                                    <Typography>Invoice End Date</Typography>
                                </Box>
                                <Box width={'80%'}>
                                    <FormControl fullWidth>
                                        <DatePicker format='MMMM DD, YYYY' label="Invoice End Date" value={invEndDate} onChange={(newValue) => setInvEndDate(newValue)} defaultValue={dayjs()} disablePast={true} />
                                    </FormControl>  
                                </Box>
                            </Stack>}
                        </Stack>
                    </Paper>
                </Stack>

                <Stack>
                    <Paper elevation={3}>
                        <Stack p={2} gap={2} pb={0}>
                            <Typography variant='h6'>Invoice Items</Typography>
                            <Stack flexDirection={'row'}>
                                <Box width={'5%'}>
                                    <Typography><b>No</b></Typography>
                                </Box>
                                <Box width={'50%'}>
                                    <Typography><b>Name</b></Typography>
                                </Box>
                                <Box width={'15%'}>
                                    <Typography><b>Price</b></Typography>
                                </Box>
                                <Box width={'10%'}>
                                    <Typography><b>GST</b></Typography>
                                </Box>
                                <Box width={'20%'}>
                                    <Typography><b>Amount</b></Typography>
                                </Box>
                            </Stack>
                        </Stack>
                        {invoiceItems.map((eItem, index)=>{
                            return <Stack key={index} p={2} sx={{borderBottom: '1px solid #aaa'}} flexDirection={'row'} gap={2}>
                                <Box width={'5%'}>
                                    <Typography>{index + 1}</Typography>
                                </Box>
                                <Stack width={'50%'} gap={2}>
                                    <Typography>{eItem.name}</Typography>
                                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
                                        <Typography textAlign={'right'}>Professional Fees</Typography>
                                        <TextField type='number' size='small' sx={{width: 130}} value={eItem.professional} onChange={(e)=>{editInvoiceItem(index, "professional", e.target.value)}} variant="outlined" required
                                        slotProps={{
                                            input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>{currency}</Typography>
                                                </InputAdornment>
                                            ),
                                            },
                                        }}/>
                                    </Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
                                        <Typography textAlign={'right'}>Government Fees</Typography>
                                        <TextField type='number' size='small' sx={{width: 130}} value={eItem.govt}  onChange={(e)=>{editInvoiceItem(index, "govt", e.target.value)}} variant="outlined" required
                                        slotProps={{
                                            input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>{currency}</Typography>
                                                </InputAdornment>
                                            ),
                                            },
                                        }}/>
                                    </Stack>
                                </Stack>
                                <Stack width={'15%'}>
                                    <TextField type='number' size='small' sx={{width: 160}} value={eItem.price} variant="outlined" fullWidth required
                                    slotProps={{
                                        input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                        },
                                    }}/>
                                </Stack>
                                <Box width={'10%'}>
                                    <TextField type='number' size='small' sx={{width: 120}} value={eItem.gst} onChange={(e)=>{editInvoiceItem(index, "gst", e.target.value)}} variant="outlined" fullWidth required
                                    slotProps={{
                                        input: {
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>%</Typography>
                                            </InputAdornment>
                                        )
                                        },
                                    }}/>
                                    <Typography>{currency} {eItem.gstValue}</Typography>
                                </Box>
                                <Stack width={'15%'}>
                                    <TextField type='number' size='small' sx={{width: 160}} value={eItem.amount} variant="outlined" fullWidth required
                                    slotProps={{
                                        input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                        },
                                    }}/>
                                </Stack>
                                <Box width={'5%'}>
                                    <Typography style={{cursor: 'pointer', color: 'red'}} onClick={()=>{removeInvoiceItem(index)}}><FontAwesomeIcon icon={faTrash}/></Typography>
                                </Box>
                            </Stack>
                        })}
                        <Stack p={2} pt={1} pb={0}>
                            <Stack flexDirection={'row'}>
                                <Box width={'55%'}>
                                    <Typography><b>TOTAL</b></Typography>
                                </Box>
                                <Box width={'15%'}>
                                    <Typography><b>{currency} {totalPrice}</b></Typography>
                                </Box>
                                <Box width={'10%'}>
                                    <Typography><b>{currency} {totalGst}</b></Typography>
                                </Box>
                                <Box width={'20%'}>
                                    <Typography><b>{currency} {totalAmount}</b></Typography>
                                </Box>
                            </Stack>
                        </Stack>

                        <Stack flexDirection={'row'} gap={2} alignItems={'center'} p={2}>
                            <Typography>Select Product</Typography>
                            <Select value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}} variant='outlined' sx={{flex: 1}}>
                                <MenuItem value={'none'}>None</MenuItem>
                                {productsList.map(product=>{
                                    return <MenuItem key={product.id} value={product}>{product.name} - {currency} {parseFloat(product.professional) + parseFloat(product.govt)}</MenuItem>
                                })}
                            </Select>
                            <Button variant='contained' onClick={addInvoiceItem}>Add Product</Button>
                        </Stack>
                    </Paper>
                </Stack>

                <Stack my={3}>
                    <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Receipt/>} onClick={createInvoice}><span>Create Invoice</span></LoadingButton>
                </Stack>

            </Stack>
        </Stack>
    )
}

export default AddInvoice