import React, { useCallback, useEffect, useState } from 'react'
import { useCommonUI } from '../context/UI'
import { useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Person } from '@mui/icons-material';

function UsersPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const [isLoading, setIsLoading] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [newUser, setNewUser] = useState({id: null, name: '', phone: '', password: '', type: 'agent', status: true});
    const [userModal, setUserModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setUsersList([]);
        const resp = await fetchData('users/lists');
        if(resp){
            setUsersList(resp.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addUserModal = () =>{
        setNewUser({id: null, name: '', phone: '', password: '', type: 'agent', status: true});
        setUserModal(true);
    }
    const editUserModal = (user) =>{
        setNewUser({id: user.id, name: user.name, phone: user.phone, password: '', type: user.type, status: user.status});
        setUserModal(true);
    }

    const updateUser = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        if(newUser.id){
            const resp = await postData('users/edit', newUser);
            if(resp){
                setUsersList((prevList) =>
                    prevList.map((user) =>
                        user.id === newUser.id ? resp.data : user
                    )
                );
                setUserModal(false);
                openSnackbar("User Updated", "success");
            }
        }else{
            if(newUser.password === ""){
                setIsSubmitting(false);
                return;
            }
            const resp = await postData('users/new', newUser);
            if(resp){
                setUsersList((prevArray) => [...prevArray, resp.data]);
                setUserModal(false);
                openSnackbar("User Added", "success");
            }
        }
        setIsSubmitting(false);
    }

    const handleUser = (e) =>{
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    }

    const closeModal = () =>{
        if(isSubmitting){
            return;
        }
        setUserModal(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Users ({usersList.length})</Typography>
                    <Button variant='contained' onClick={addUserModal}>New User</Button>
                </Stack>

                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Users</Typography>
                </Stack>}

                <Stack flexDirection='row' gap={1} p={3.2} pb={0} alignItems='center'>
                    <Box width={'20%'}>
                        <Typography variant='h6'><b>Created</b></Typography>
                    </Box>  
                    <Box width={'20%'}>
                        <Typography variant='h6' color='#000'><b>Name</b></Typography>
                    </Box>  
                    <Box width={'20%'}>
                        <Typography variant='h6'><b>Phone</b></Typography>
                    </Box>     
                    <Box width={'10%'}>
                        <Typography variant='h6'><b>Type</b></Typography>
                    </Box>   
                    <Box width={'10%'}>
                        <Typography variant='h6'><b>Status</b></Typography>
                    </Box>      
                    <Box width={'10%'}>
                        <Typography variant='h6'><b>Sessions</b></Typography>
                    </Box>      
                    <Box width={'10%'}>
                        <Typography variant='h6'><b>Modify</b></Typography>
                    </Box>  
                </Stack>
                <Stack mt={1} p={2} pt={0} gap={3}>
                    {usersList.map(user=>{
                        return <Stack key={user.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'20%'}>
                                <Typography>{moment(user.created).format('Do MMMM YYYY h:mm a')}</Typography>
                            </Box>  
                            <Box width={'20%'}>
                                <Typography color='#000'>{user.name}</Typography>
                            </Box>   
                            <Box width={'20%'}>
                                <Typography>+91 {user.phone}</Typography>
                            </Box>  
                            <Box width={'10%'}>
                                {<Typography>{user.type === "admin"?"Admin":""}</Typography>}
                                {<Typography>{user.type === "accountant"?"Accountant":""}</Typography>}
                                {<Typography>{user.type === "agent"?"Agent":""}</Typography>}
                            </Box>  
                            <Stack width={'10%'}>
                                <Typography color={user.status?"success":"error"}>{user.status?"Active":"Inactive"}</Typography>
                            </Stack>   
                            <Box width={'10%'}>
                                <Button size='small' variant='contained' color='info'>Manage</Button>
                            </Box>   
                            <Box width={'10%'}>
                                <Button size='small' variant='contained' color='error' onClick={()=>{editUserModal(user)}}>Edit</Button>
                            </Box>  
                        </Stack>
                    })}
                </Stack>
            </Stack>

            <Modal open={userModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={updateUser}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{newUser.id?"Edit":"Add"} User</Typography>
                        <TextField name='name' label="User Name" value={newUser.name} onChange={handleUser} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newUser.phone} onChange={handleUser} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>

                        <Box>
                            <TextField type='password' name='password' label="Password" value={newUser.password} onChange={handleUser} variant="outlined" fullWidth />
                            {newUser.id && <Typography fontSize={12}><i>Keep blank if not changed</i></Typography>}
                        </Box>

                        <FormControl fullWidth required>
                            <InputLabel id="select-label">Type</InputLabel>
                            <Select name='type' label="Type" value={newUser.type} onChange={handleUser} disabled={newUser.id?true:false}>
                                <MenuItem value={'agent'}>Agent</MenuItem>
                                <MenuItem value={'accountant'}>Accountant</MenuItem>
                                <MenuItem value={'admin'}>Admin</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth required>
                            <InputLabel id="select-label">Status</InputLabel>
                            <Select name='status' label="Status" value={newUser.status} onChange={handleUser}>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                        
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<Person/>}><span>{newUser.id?"Update":"Add"} User</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default UsersPage