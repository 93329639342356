import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners';
import { useApiRequest } from '../store/Common';
import { LoadingButton } from '@mui/lab';
import { Settings } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

function SettingsPage() {

    const {fetchData, postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const {openSnackbar} = useCommonUI();
    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [configs, setConfigs] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [emailsList, setEmailsList] = useState([]);
    const [emailTotal, setEmailTotal] = useState(0);

    const getData = useCallback(async() =>{
        setIsLoading(true);
        const resp = await fetchData('settings/list');
        const resp3 = await fetchData('template/list');
        if(resp && resp3){
            setConfigs(resp.data);
            setTemplateList(resp3.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getData();
    },[getData]);

    const handleConfig = (e) =>{
        const { name, value } = e.target;
        if(name === "emailFromName"){
            setConfigs({ ...configs, emailFrom: {...configs.emailFrom, name: value} });
        }else if(name === "emailFromEmail"){
            setConfigs({ ...configs, emailFrom: {...configs.emailFrom, email: value} });
        }else{
            setConfigs({ ...configs, [name]: value });
        }
    }

    const updateSettings = async() =>{
        if(configs.emailFrom.name === "" || configs.emailFrom.email === "" || configs.invoice_no === "" || configs.invoice_prefix === ""){
            openSnackbar("Some Fields Empty", "error");
            return;
        }
        setIsSubmitting(true);
        const resp = await postData("settings/update", {data: configs});
        if(resp){
            openSnackbar("Settings Updated", "success");
        }
        setIsSubmitting(false);
    }

    const loadEmailCount = async() =>{
        if(!selectedDate || selectedDate.length !== 2){
            return;
        }
        setIsEmailLoading(true);
        let start = selectedDate[0].setHours(0,0,0,0);
        let end = selectedDate[1].setHours(23, 59, 59, 999);
        const resp = await postData("settings/emailcount", {start, end});
        if(resp){
            setEmailsList(resp.data);
            setEmailTotal(resp.total);
        }
        setIsEmailLoading(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Settings</Typography>
                </Stack>}

                {configs && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Settings</Typography>
                        </Stack>
                    </Stack>

                    <Stack p={2} gap={2}>
                        <Stack flexDirection={'row'} gap={2}>
                            <TextField name="invoice_prefix" label="Invoice Prefix" value={configs.invoice_prefix} onChange={handleConfig} variant="outlined" fullWidth required/>

                            <TextField type='number' name="invoice_no" label="Invoice Number" value={configs.invoice_no} onChange={handleConfig} variant="outlined" fullWidth required/>
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <TextField name="emailFromName" label="Email From Name" value={configs.emailFrom.name} onChange={handleConfig} variant="outlined" fullWidth required/>

                            <TextField name="emailFromEmail" label="From Email" value={configs.emailFrom.email} onChange={handleConfig} variant="outlined" fullWidth required/>
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Invoice Template</InputLabel>
                                <Select label="Invoice Template" name="invoiceTemplate" value={configs.invoiceTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 

                            <FormControl fullWidth>
                                <InputLabel id="select-label">Recurring Invoice Template</InputLabel>
                                <Select label="Recurring Invoice Template" name="recurringInvoiceTemplate" value={configs.recurringInvoiceTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">GST Template (2nd of every month)</InputLabel>
                                <Select label="GST Template (2nd of every month)" name="gstTemplate" value={configs.gstTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Service List Template (after 2 days)</InputLabel>
                                <Select label="Service List Template (after 2 days)" name="serviceListTemplate" value={configs.serviceListTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">PTax Template (after 7 days of compliance start)</InputLabel>
                                <Select label="PTax Template (after 7 days of compliance start)" name="ptaxTemplate" value={configs.ptaxTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Website Template (after 10 days)</InputLabel>
                                <Select label="Website Template (after 10 days)" name="websiteTemplate" value={configs.websiteTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Startup India Template (after 15 days of compliance start)</InputLabel>
                                <Select label="Startup India Template (after 15 days of compliance start)" name="startupIndiaTemplate" value={configs.startupIndiaTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">ISO Template (after 20 days of compliance start)</InputLabel>
                                <Select label="ISO Template (after 20 days of compliance start)" name="IsoTemplate" value={configs.IsoTemplate} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">INC20 Template (after 120 and 150 days of incorporation)</InputLabel>
                                <Select label="INC20 Template (after 120 and 150 days of incorporation)" name="Inc20Template" value={configs.Inc20Template} onChange={handleConfig}>
                                    {
                                        templateList.map((item)=>{
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl> 
                        </Stack>

                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Settings/>} onClick={updateSettings}><span>Update Settings</span></LoadingButton>
                    </Stack>

                </Stack>}

                <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove', alignItems: 'center'}}>
                        <Typography variant='h5'>Email Count</Typography>
                        <Stack flexDirection={'row'} gap={2}>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                            <Button color='primary' variant="contained" onClick={loadEmailCount}>GO</Button>
                        </Stack>
                        <Typography variant='h6'>Total Emails: {emailTotal}</Typography>
                    </Stack>
                    {isEmailLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Email Counts</Typography>
                    </Stack>}

                    <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={3.2} pb={0} pt={0}>
                        <Box width={'70%'}>
                            <Typography><b>Date</b></Typography>
                        </Box>
                        <Box width={'30%'}>
                            <Typography><b>Count</b></Typography>
                        </Box>
                    </Stack>
                    <Stack p={2} gap={2} pt={0}>
                        {emailsList.map(email=>{
                            return <Paper key={email.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'70%'}>
                                        <Typography>{moment(email.date).format('Do MMM YYYY')}</Typography>
                                    </Box>  
                                    <Box width={'30%'}>
                                        <Typography color='#000'>{email.count}</Typography>
                                    </Box>   
                                </Stack>
                            </Paper>
                        })}
                    </Stack>
                </Stack>

            </Stack>
        </Stack>
    )
}

export default SettingsPage