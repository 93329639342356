import { Avatar, Box, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { stringAvatar, useApiRequest } from '../store/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LoadingButton } from '@mui/lab';
import { Close, PersonAdd, SearchTwoTone } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import moment from 'moment/moment';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';

function LeadsPage() {

    const {openSnackbar} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [leadsList, setLeadsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [bodyData, setBodyData] = useState({service: [], agent: [], source: [], type: [], priority: [], response: [], name: '', start: 0, end: 0, dtype: "created"});

    const [servicesList, setServicesList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [statesList, setStatesList] = useState([]);

    const [newLeadModal, setNewLeadModal] = useState(false);
    const [newLead, setNewLead] = useState({ name: '', phone: '', service: 'none', source: 'others', type: 'hot', priority: 'high', agent: 'none', company: '', address: '', state: '' });
    const [isSubmittingLead, setIsSubmittingLead] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [dateType, setDateType] = useState('created');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState([]);
    const [selectedSource, setSelectedSource] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState([]);

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setLeadsList([]);
            const resp = await postData('leads/lists/1', {service: [], agent: [], source: [], type: [], priority: [], response: [], name: '', start: 0, end: 0, dtype: "created"});
            if(resp){
                setLeadsList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('leads/lists/'+page, body);
        if(resp){
            setLeadsList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && leadsList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, leadsList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('services/list');
            const resp1 = await fetchData('users/agents');
            const resp2 = await fetchData('app/states');
            if(resp && resp1){
                setAgentsList(resp1.data);
                setServicesList(resp.data);
                setStatesList(resp2.data);
            }
        }
        getLists();
    },[fetchData])

    const handleNewLead = (e) => {
        const { name, value } = e.target;
        setNewLead({ ...newLead, [name]: value });
    };
    const openNewLeadModal = () =>{
        setNewLead({ name: '', phone: '', service: 'none', source: 'others', type: 'hot', priority: 'high', agent: 'none', company: '', state: '' });
        setNewLeadModal(true);
    }
    const closeNewLeadModal = () =>{
        if(isSubmittingLead){
            return;
        }
        setNewLeadModal(false);
    }

    const submitNewLead = async(e) =>{
        e.preventDefault();
        setIsSubmittingLead(true);
        const resp = await postData('leads/new', newLead);
        if(resp){
            setTotalDocs((total)=>(total+1))
            if(page){
                setLeadsList((prevArray) => {
                    const updatedArray = [resp.data, ...prevArray.slice(0, prevArray.length - 1)];
                    return updatedArray;
                });
            }else{
                setLeadsList((prevArray) => [resp.data, ...prevArray]);
            }
            setNewLeadModal(false);
            openSnackbar("Lead Added", "success");
        }
        setIsSubmittingLead(false);
    }

    const handleServiceChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedService(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleAgentChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedAgent(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleSoureChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedSource(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleTypeChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedType(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handlePriorityChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedPriority(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleResponseChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedResponse(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({service: [], agent: [], source: [], type: [], priority: [], response: [], name: '', start: 0, end: 0, dtype: "created"});
        setLeadsList([]);
        setSearchTerm('');
        setSelectedService([]);
        setSelectedAgent([]);
        setSelectedSource([]);
        setSelectedType([]);
        setSelectedPriority([]);
        setSelectedResponse([]);
        setDateType('created');
        setSelectedDate(null);
        getData(1, {service: [], agent: [], source: [], type: [], priority: [], response: [], name: '', start: 0, end: 0, dtype: "created"});
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            name: searchTerm,
            service: selectedService,
            agent: selectedAgent,
            source: selectedSource,
            type: selectedType,
            priority: selectedPriority,
            response: selectedResponse,
            start,
            end,
            dtype: dateType
        }
        setBodyData(body);
        setLeadsList([]);
        getData(1, body);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Leads ({totalDocs})</Typography>
                    <Button variant='contained' onClick={openNewLeadModal}>New Lead</Button>
                </Stack>
                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2} alignItems={'center'}>
                        <Stack gap={2} flex={1}>
                            <Stack flexDirection='row' gap={2}>
                                <TextField fullWidth variant='outlined' label='Name/Phone/Company Name' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                                <FormControl fullWidth>
                                    <InputLabel>Date Type</InputLabel>
                                    <Select value={dateType} onChange={(e)=>{setDateType(e.target.value)}} >
                                        <MenuItem value='created'>Created</MenuItem>
                                        <MenuItem value='lastFollowup'>Last Followup</MenuItem>
                                        <MenuItem value='nextFollowup'>Next Followup</MenuItem>
                                    </Select>
                                </FormControl>
                                <Stack>
                                    <Box width={300}></Box>
                                    <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                                </Stack>
                                <FormControl fullWidth>
                                    <InputLabel>Service</InputLabel>
                                    <Select label="Service" multiple value={selectedService} onChange={handleServiceChange} input={<OutlinedInput label="Chip" />} >
                                        {servicesList.map((srv) => {
                                            return <MenuItem key={srv.id} value={srv.id}>{srv.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack flexDirection='row' gap={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Agent</InputLabel>
                                    <Select label="Agent" multiple value={selectedAgent} onChange={handleAgentChange} input={<OutlinedInput label="Chip" />} >
                                        {agentsList.map((agent) => {
                                            return <MenuItem key={agent.id} value={agent.id}>{agent.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Source</InputLabel>
                                    <Select label="Source" multiple value={selectedSource} onChange={handleSoureChange} input={<OutlinedInput label="Chip" />} >
                                        <MenuItem value={'instagram'}><FontAwesomeIcon icon={faInstagram} />&nbsp;&nbsp;Instagram</MenuItem>
                                        <MenuItem value={'facebook'}><FontAwesomeIcon icon={faFacebook} />&nbsp;&nbsp;Facebook</MenuItem>
                                        <MenuItem value={'whatsapp'}><FontAwesomeIcon icon={faWhatsapp} />&nbsp;&nbsp;Whatsapp</MenuItem>
                                        <MenuItem value={'others'}>Others</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Type</InputLabel>
                                    <Select label="Type" multiple value={selectedType} onChange={handleTypeChange} input={<OutlinedInput label="Chip" />} >
                                        <MenuItem value={'hot'}>Hot</MenuItem>
                                        <MenuItem value={'cold'}>Cold</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Priority</InputLabel>
                                    <Select label="Priority" multiple value={selectedPriority} onChange={handlePriorityChange} input={<OutlinedInput label="Chip" />} >
                                        <MenuItem value={'high'}>High</MenuItem>
                                        <MenuItem value={'medium'}>Medium</MenuItem>
                                        <MenuItem value={'low'}>Low</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Response</InputLabel>
                                    <Select label="Response" multiple value={selectedResponse} onChange={handleResponseChange} input={<OutlinedInput label="Chip" />} >
                                        <MenuItem value={'interested'}>Interested</MenuItem>
                                        <MenuItem value={'medium'}>Medium</MenuItem>
                                        <MenuItem value={'not interested'}>Not Interested</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Stack>
                        <Box>
                            <IconButton type='submit'>
                                <SearchTwoTone fontSize='large' color='primary' />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton type='reset'>
                                <Close fontSize='large' color='primary' />
                            </IconButton>
                        </Box>
                    </Stack>
                </Box>
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 330px)' overflow='auto' gap={3} ref={containerRef}>

                    {leadsList.map(lead=>{
                        return <Stack key={lead.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'5%'}>
                                <Avatar {...stringAvatar(lead.name)} />
                            </Box>
                            <Stack width={'25%'}>
                                <Stack flexDirection={'row'} gap={2}>
                                    <Typography variant='h5' color='#000'>{lead.name}</Typography>
                                    {lead.converted && <Chip sx={{color: '#000'}} label={'Customer'} color="primary" />}
                                </Stack>
                                <Typography variant='h6'>+91 {lead.phone}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                <Typography>{lead.companyName}</Typography>
                                {lead.service && <Typography><b>{lead.service.name}</b></Typography>}
                            </Stack>
                            <Stack width={'15%'}>
                                {lead.source === "instagram" && <Typography><FontAwesomeIcon icon={faInstagram} /> Instagram</Typography>}
                                {lead.source === "facebook" && <Typography><FontAwesomeIcon icon={faFacebook} /> Facebook</Typography>}
                                {lead.source === "whatsapp" && <Typography><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp</Typography>}
                                {lead.source === "others" && <Typography>Others</Typography>}
                                <Typography>Created: {moment(lead.created).format('Do MMM yyyy hh:mm a')}</Typography>
                            </Stack>
                            <Stack width={'8%'}>
                                {lead.assigned && <Typography>{lead.assigned.name}</Typography>}
                                <Box>
                                    {lead.response === "not interested" && <Chip label={'Not Interested'} color='error' />}
                                    {lead.response === "medium" && <Chip label={'Medium'} color='warning' />}
                                    {lead.response === "interested" && <Chip label={'Interested'} color='success' />}
                                </Box>
                            </Stack>
                            <Box width={'12%'}>
                                {lead.nextFollowup && <Typography color='#000'>Next: {moment(lead.nextFollowup).format('Do MMM yyyy')}</Typography>}
                                {lead.lastFollowup && <Typography>Last: {moment(lead.lastFollowup).format('Do MMM yyyy hh:mm a')}</Typography>}
                            </Box>
                            <Box width={'5%'}>
                                {lead.type === "hot" && <Chip sx={{margin: '5px'}} label={'HOT'} color="error" />}
                                {lead.type === "cold" && <Chip sx={{margin: '5px'}} label={'COLD'} color="success" />}
                            </Box>
                            <Box width={'8%'}>
                                {lead.priority && lead.priority === "high" && <Chip sx={{margin: '5px'}} label={'HIGH'} color="error" />}
                                {lead.priority && lead.priority === "medium" && <Chip sx={{margin: '5px'}} label={'MEDIUM'} color="warning" />}
                                {lead.priority && lead.priority === "low" && <Chip sx={{margin: '5px'}} label={'LOW'} color="success" />}
                            </Box>
                            <Box width={'10%'}>
                                <Link to={`/lead/${lead.id}`}>
                                    <Button size='small' variant='contained'>Details</Button>
                                </Link>
                            </Box>
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Leads</Typography>
                    </Stack>}
                </Stack>
                
            </Stack>

            <Modal open={newLeadModal} onClose={closeNewLeadModal}>
                <Box component="form" sx={{position: 'absolute',top: '10%', bottom: '10%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewLead}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Lead</Typography>
                        <TextField label="Name" name='name' value={newLead.name} onChange={handleNewLead} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newLead.phone} onChange={handleNewLead} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>
                        <TextField label="Company Name" name='company' value={newLead.company} onChange={handleNewLead} variant="outlined" fullWidth/>
                        <FormControl fullWidth required>
                            <InputLabel>Service</InputLabel>
                            <Select label="Service" name="service" value={newLead.service} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'none'}>None</MenuItem>
                                {servicesList.map((srv) => {
                                    return <MenuItem key={srv.id} value={srv.id}>{srv.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Source</InputLabel>
                            <Select label="Source" name="source" value={newLead.source} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'instagram'}><FontAwesomeIcon icon={faInstagram} />&nbsp;&nbsp;Instagram</MenuItem>
                                <MenuItem value={'facebook'}><FontAwesomeIcon icon={faFacebook} />&nbsp;&nbsp;Facebook</MenuItem>
                                <MenuItem value={'whatsapp'}><FontAwesomeIcon icon={faWhatsapp} />&nbsp;&nbsp;Whatsapp</MenuItem>
                                <MenuItem value={'others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select label="Type" name="type" value={newLead.type} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'hot'}>Hot</MenuItem>
                                <MenuItem value={'cold'}>Cold</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Priority</InputLabel>
                            <Select label="Priority" name="priority" value={newLead.priority} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'high'}>High</MenuItem>
                                <MenuItem value={'medium'}>Medium</MenuItem>
                                <MenuItem value={'low'}>Low</MenuItem>
                                <MenuItem value={'none'}>None</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="Address" name='address' value={newLead.address} onChange={handleNewLead} variant="outlined" fullWidth multiline rows={4}/>
                        <FormControl fullWidth>
                            <InputLabel>State</InputLabel>
                            <Select label="State" name="state" value={newLead.state} onChange={handleNewLead} variant="outlined">
                                {statesList.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Agent</InputLabel>
                            <Select label="Agent" name="agent" value={newLead.agent} onChange={handleNewLead} variant="outlined">
                                <MenuItem value={'none'}>None</MenuItem>
                                {agentsList.map((agent) => {
                                    return <MenuItem key={agent.id} value={agent.id}>{agent.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingLead}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Lead</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default LeadsPage