// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tox-promotion,.tox-statusbar__branding{ display: none}
/* Webkit (Safari, Chrome) */
::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* background of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #fbb755; /* color of the thumb */
  border-radius: 10px; /* roundness of the thumb */
}
.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input, .rs-picker-date>.rs-input-group.rs-input-group-inside .rs-input {
  height: 54px;
  font-size: 16px;
}
.rs-picker.rs-picker-focused, .rs-picker:not(.rs-picker-disabled):hover {
  border-color: #fbb755 !important;
}
.rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #fbb755 !important;
}
.rs-input-group {
  border: 1px solid rgba(0,0,0,0.25) !important;
}
.rs-picker-popup.rs-picker-popup-daterange, .rs-picker-popup.rs-picker-popup-date {
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA,yCAAyC,aAAa;AACtD,4BAA4B;AAC5B;EACE,UAAU,EAAE,2BAA2B;AACzC;;AAEA;EACE,uBAAuB,EAAE,4BAA4B;AACvD;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;EAClD,mBAAmB,EAAE,2BAA2B;AAClD;AACA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,gCAAgC;AAClC;AACA;EACE,gCAAgC;AAClC;AACA;EACE,6CAA6C;AAC/C;AACA;EACE,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.tox-promotion,.tox-statusbar__branding{ display: none}\n/* Webkit (Safari, Chrome) */\n::-webkit-scrollbar {\n  width: 5px; /* width of the scrollbar */\n}\n\n::-webkit-scrollbar-track {\n  background: transparent; /* background of the track */\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #fbb755; /* color of the thumb */\n  border-radius: 10px; /* roundness of the thumb */\n}\n.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input, .rs-picker-date>.rs-input-group.rs-input-group-inside .rs-input {\n  height: 54px;\n  font-size: 16px;\n}\n.rs-picker.rs-picker-focused, .rs-picker:not(.rs-picker-disabled):hover {\n  border-color: #fbb755 !important;\n}\n.rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:not(.rs-input-group-disabled):hover {\n  border-color: #fbb755 !important;\n}\n.rs-input-group {\n  border: 1px solid rgba(0,0,0,0.25) !important;\n}\n.rs-picker-popup.rs-picker-popup-daterange, .rs-picker-popup.rs-picker-popup-date {\n  z-index: 9999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
