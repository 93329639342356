import { Autocomplete, Box, Button, Chip, FormControl, FormControlLabel, Grid2, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { currency, useApiRequest } from '../store/Common';
import moment from 'moment';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { CalendarMonth, Chat, Download, Email, LocalPhone, PersonPin, Public, Receipt, Reply, Store, TaskAlt, WhatsApp, WorkOutline } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import { ClipLoader } from 'react-spinners';
import parse from 'html-react-parser';

function CustomerDetails() {

    const {customerId} = useParams();
    const {openSnackbar, openDialog} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCompliance, setIsLoadingCompliance] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [activeServices, setActiveServices] = useState([]);
    const [templateHistory, setTemplateHistory] = useState([]);
    const [fyView, setFyView] = useState('');
    const [complianceList, setComplianceList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [recurringInvoiceList, setRecurringInvoiceList] = useState([]);

    const [servicesList, setServicesList] = useState([]);
    const [accountantsList, setAccountantsList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [templateList, setTemplateList] = useState([])
    const [fyList, setFyList] = useState([]);

    const [editEmailsList, setEditEmailsList] = useState([]);
    const [editEmailField, setEditEmailField] = useState('');
    const [emailModal, setEmailModal] = useState(false);

    const [editCustomerModal, setEditCustomerModal] = useState(false);
    const [editCustomer, setEditCustomer] = useState({ name: '', phone: '', company: '', address: '', gst: '', type: 'Private Limited Company', incorporation: dayjs(), state: '', newlyIncorporated: true, onboarding: dayjs(), username: '' });

    const [directorModal, setDirectorModal] = useState(false);
    const [directorName, setDirectorName] = useState('');
    const [directorPhone, setDirectorPhone] = useState('');

    const [templateModal, setTemplateModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateSubject, setTemplateSubject] = useState('');
    const [templateContent, setTemplateContent] = useState('');

    const [serviceModal, setServiceModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [serviceDate, setServiceDate] = useState(dayjs());
    
    const [professionalFees, setProfessionalFees] = useState(0);
    const [govtFees, setGovtFees] = useState(0);
    const [gstFees, setGstFees] = useState(0);
    const [invRecurring, setInvRecurring] = useState(false);
    const [invInternal, setInvInternal] = useState(0);
    const [invIntervalType, setInvIntervalType] = useState('month');
    const [invEndDate, setInvEndDate] = useState(dayjs());
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [invService, setInvService] = useState(null);
    const [invLinkedService, setInvLinkedService] = useState('');

    const [viewTemplateModal, setViewTemplateModal] = useState(false);
    const [viewTemplate, setViewTemplate] = useState(null);

    const [addFyModal, setAddFyModal] = useState(false);
    const [selectedFy, setSelectedFy] = useState("");
    const [useInc20, setUseInc20] = useState(false);

    const [complianceModal, setComplianceModal] = useState(false);
    const [selectedCompliance, setSelectedCompliance] = useState(null);
    const [complianceStatus, setComplianceStatus] = useState('To Be Done');
    const [complianceAccoutant, setComplianceAccoutant] = useState('None');

    const [jobModal, setJobModal] = useState(false);
    const [jobData, setJobData] = useState({title: '', accountant: '', expiryDate: null, isExpiry: false, status: 'To Be Done'});

    const [waTemplates, setWaTemplates] = useState([]);
    const [waModal, setWaModal] = useState(false);
    const [isLoadingWa, setIsLoadingWa] = useState(false);
    const [waHeaderComponents, setWaHeaderComponents] = useState(null);
    const [waHeaderVariables, setWaHeaderVariables] = useState([]);
    const [waHeaderMedia, setWaHeaderMedia] = useState('');
    const [waHeaderDocumentName, setWaHeaderDocumentName] = useState('');
    const [waBodyComponent, setWaBodyComponent] = useState(null);
    const [waBodyVariables, setWaBodyVariables] = useState([]);
    const [waFooterComponent, setWaFooterComponent] = useState(null);
    const [waButtonComponent, setWaButtonComponent] = useState(null);
    const [selectedWaTemplate, setSelectedWaTemplate] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('services/list');
            const resp1 = await fetchData('users/accountants');
            const resp2 = await fetchData('app/states');
            const resp3 = await fetchData('template/list');
            if(resp && resp1 && resp2){
                setAccountantsList(resp1.data);
                setServicesList(resp.data);
                setStatesList(resp2.data);
                setTemplateList(resp3.data);
                if(resp3.data.length > 1){
                    setSelectedTemplate(resp3.data[0]);
                }
            }
        }
        getLists();
    },[fetchData])

    const loadCompliances = useCallback(async(fy, cid) =>{
        setIsLoadingCompliance(true);
        setComplianceList([]);
        const resp = await postData('customers/compliances', {id: cid, fy: fy.id});
        if(resp){
            setComplianceList(resp.data);
        }
        setIsLoadingCompliance(false);
    },[postData]);

    const getData = useCallback(async(oid) =>{
        setIsLoading(true);
        const resp = await fetchData('customers/details/'+oid);
        const resp1 = await fetchData('customers/service/'+oid);
        const resp2 = await fetchData('customers/emailhistory/'+oid);
        const resp3 = await fetchData('customers/invoices/'+oid);
        if(resp && resp1 && resp2 && resp3){
            setCustomerDetails(resp.data);
            setActiveServices(resp1.data);
            setTemplateHistory(resp2.data);
            setInvoiceList(resp3.invoice);
            setRecurringInvoiceList(resp3.recurring);
            if(resp.data.compliance){
                const resp3 = await fetchData('app/financialyears');
                if(resp3){
                    setFyList(resp3.data);
                }
            }
            if(resp.data.financialYear && resp.data.financialYear.length > 0){
                setFyView(resp.data.financialYear[resp.data.financialYear.length-1]);
                loadCompliances(resp.data.financialYear[resp.data.financialYear.length-1], oid);
            }
        }
        setIsLoading(false);
    },[fetchData, loadCompliances]);

    const loadEmails = async() =>{
        const resp2 = await fetchData('customers/emailhistory/'+customerId);
        if(resp2){
            setTemplateHistory(resp2.data);
        }
    }

    useEffect(()=>{
        getData(customerId);
    },[getData, customerId]);

    const viewComplianceButton = () =>{
        if(fyView === ""){
            return;
        }
        loadCompliances(fyView, customerId);
    }

    const openEmailModal = () =>{
        if(!customerDetails){
            return;
        }
        setEditEmailsList(customerDetails.emails);
        setEditEmailField('');
        setEmailModal(true);
    }
    const submitEmails = async() =>{
        setIsSubmitting(true);
        const resp = await postData('customers/emails', {id: customerDetails.id, emails: editEmailsList});
        if(resp){
            setEmailModal(false);
            openSnackbar("Emails Updated", "success");
            setCustomerDetails(null);
            getData(customerId);
        }
        setIsSubmitting(false);
    }
    const addEmail = () =>{
        if(editEmailField === ""){
            return;
        }
        setEditEmailsList((emails)=> [...emails, editEmailField]);
        setEditEmailField('');
    }
    const removeEmail = (email) => {
        setEditEmailsList((emails) => emails.filter((em) => em !== email));
    };

    const openEditModal = () =>{
        if(!customerDetails){
            return;
        }
        const obj = {
            name: customerDetails.name,
            phone: customerDetails.phone, 
            company: customerDetails.companyName,
            address: customerDetails.address, 
            state: customerDetails.state.id, 
            type: customerDetails.type, 
            gst: customerDetails.gst?customerDetails.gst:'', 
            newlyIncorporated: customerDetails.newlyIncorporated?true:false,
            onboarding: dayjs(customerDetails.onboardingDate),
            incorporation: dayjs(customerDetails.incorporationDate),
            username: customerDetails.username?customerDetails.username:''
        }
        setEditCustomer(obj);
        setEditCustomerModal(true);
    }
    const handleEditCustomer = (e) => {
        const { name, value } = e.target;
        setEditCustomer({ ...editCustomer, [name]: value });
    };
    const updateCustomer = async(e) =>{
        e.preventDefault();
        if(editCustomer.state === ""){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('customers/update', {id: customerId, ...editCustomer});
        if(resp){
            setEditCustomerModal(false);
            openSnackbar("Customer Updated", "success");
            setCustomerDetails(null);
            getData(customerId);
        }
        setIsSubmitting(false);
    }

    const openDirectorModal = () =>{
        setDirectorName('');
        setDirectorPhone('');
        setDirectorModal(true);
    }
    const addDirector = async() =>{
        if(directorName === "" || directorPhone === ""){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('customers/director', {id: customerDetails.id, name: directorName, phone: directorPhone});
        if(resp){
            setDirectorModal(false);
            openSnackbar("Director Added", "success");
            setCustomerDetails(null);
            getData(customerId);
        }
        setIsSubmitting(false);
    }

    const removeDirector = (director) =>{
        if(isDeleting){
            openSnackbar('Wait for previous remove request to complete');
            return;
        }
        openDialog("Remove Director?", "Name: "+director.name, confirmRemoveDirector, {did: director._id, cid: customerDetails.id});
    }
    const confirmRemoveDirector = async(obj) =>{
        const tid = toast.loading('Removing Director',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await postData('customers/director/delete', obj);
        if(resp){
            toast.update(tid, { render: "Director Removed", type: "success", isLoading: false, autoClose: 2000 });
            setCustomerDetails(null);
            getData(customerId);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    const sendEmail = async() =>{
        if(templateContent === "" || templateSubject === ""){
            return;
        }
        const resp = await postData('customers/sendemail', {id: customerDetails.id, content: templateContent, subject: templateSubject, tid: selectedTemplate.id});
        if(resp){
            setTemplateModal(false);
            openSnackbar("Email Sent", "success");
            setTemplateHistory([]);
            getData(customerId);
        }
    }
    const openTemplateModal = () =>{
        setTemplateModal(true);
    }
    useEffect(()=>{
        if(selectedTemplate){
            setTemplateContent(selectedTemplate.content);
            setTemplateSubject(selectedTemplate.subject);
        }
    },[selectedTemplate]);

    const openInvocieModal = (service, linkedService) =>{
        setInvService(service);
        setInvLinkedService(linkedService);
        setServiceDate(dayjs());
        setProfessionalFees(service.professional);
        setGovtFees(service.govt);
        setGstFees(18);
        setInvRecurring(false);
        setInvInternal(1);
        setInvIntervalType('month');
        setInvEndDate(dayjs());
        setInvoiceModal(true);
    }
    const submitInvoice = async() =>{
        const resp = await postData('customers/invoice', {id: customerDetails.id, service: invService.id, start: serviceDate, professional: professionalFees, govt: govtFees, gst: gstFees, recurring: invRecurring, interval: invInternal, intervalType: invIntervalType, end: invEndDate, linkedService: invLinkedService});
        if(resp){
            setInvoiceModal(false);
            openSnackbar("Invoice Added", "success");
            setInvoiceList([]);
            setRecurringInvoiceList([]);
            getData(customerId);
        }
    }

    const openServiceModal = () =>{
        setSelectedService('');
        setServiceDate(dayjs());
        setProfessionalFees(0);
        setGovtFees(0);
        setGstFees(0);
        setInvRecurring(false);
        setInvInternal(1);
        setInvIntervalType('month');
        setInvEndDate(dayjs());
        setServiceModal(true);
    }
    const handleServiceChange = (e) => {   
        setSelectedService(e.target.value);
        const service = servicesList.find((s)=>s.id === e.target.value);
        setProfessionalFees(service.professional);
        setGovtFees(service.govt);
    }
    const submitService = async() =>{
        if(selectedService === ""){
            return;
        }
        const resp = await postData('customers/service', {id: customerDetails.id, service: selectedService, start: serviceDate, professional: professionalFees, govt: govtFees, gst: gstFees, recurring: invRecurring, interval: invInternal, intervalType: invIntervalType, end: invEndDate});
        if(resp){
            setServiceModal(false);
            openSnackbar("New Service Started", "success");
            setActiveServices([]);
            setInvoiceList([]);
            setRecurringInvoiceList([]);
            getData(customerId);
        }
    }

    const openTemplateView = (template) =>{
        setViewTemplate({subject: template.subject, content: template.content});
        setViewTemplateModal(true);
    }

    const closeModal = () =>{
        if(isSubmitting){
            return;
        }
        setEmailModal(false);
        setDirectorModal(false);
        setEditCustomerModal(false);
        setTemplateModal(false);
        setServiceModal(false);
        setViewTemplateModal(false);
        setAddFyModal(false);
        setComplianceModal(false);
        setInvoiceModal(false);
        setJobModal(false);
        setWaModal(false);
    }

    const endService = (service) =>{
        if(isDeleting){
            openSnackbar('Wait for previous end request to complete');
            return
        }
        openDialog("End Service?", "Service: "+service.service.name, confirmEndService, service.id);
    }
    const confirmEndService = async(id) =>{
        const tid = toast.loading('Ending Service',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await fetchData('customers/endservice/'+id);
        if(resp){
            toast.update(tid, { render: "Service Ended", type: "success", isLoading: false, autoClose: 2000 });
            setActiveServices([]);
            setRecurringInvoiceList([]);
            getData(customerId);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    const submitFy = async() =>{
        if(selectedFy === ""){
            return;
        }
        setIsSubmitting(true);
        const resp = await postData('customers/addfy', {id: customerDetails.id, fy: selectedFy, inc20: useInc20});
        if(resp){
            setAddFyModal(false);
            openSnackbar("Financial Year Added", "success");
            setCustomerDetails(null);
            getData(customerId);
        }
        setIsSubmitting(false);
    }

    const openComplianceModal = (compliance) =>{
        setSelectedCompliance(compliance);
        setComplianceStatus(compliance.status);
        setComplianceAccoutant(compliance.accountant?compliance.accountant.id:'None');
        setComplianceModal(true);
    }
    const updateCompliance = async() =>{
        setIsSubmitting(true);
        const resp = await postData('customers/compliance/update', {id: selectedCompliance.id, status: complianceStatus, accountant: complianceAccoutant});
        if(resp){
            setComplianceModal(false);
            openSnackbar("Compliance Updated", "success");
            setComplianceList([]);
            loadCompliances(fyView, customerId);
            if(complianceStatus === "Done"){
                loadEmails();
            }
        }
        setIsSubmitting(false);
    }

    const openNewJobModal = () =>{
        setJobData({title: '', accountant: '', expiryDate: null, isExpiry: false, status: 'To Be Done'});
        setJobModal(true);
    }
    const handleNewJob = (e) => {
        const { name, value } = e.target;
        setJobData({ ...jobData, [name]: value });
    };
    
    const submitNewJob = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        let resp = await postData('accountant/new', {...jobData, customer: {id: customerId}});
        if(resp){
            openSnackbar("Job Added", "success");
            setJobModal(false);
        }
        setIsSubmitting(false);
    }

    const openWaTemplateModal = async() =>{
        setWaModal(true);
        setIsLoadingWa(true);
        setWaTemplates([]);
        setWaHeaderVariables([]);
        setWaHeaderComponents(null);
        setWaHeaderDocumentName('');
        setWaHeaderMedia('');
        setWaBodyVariables([]);
        setWaBodyComponent(null);
        setWaFooterComponent(null);
        setSelectedWaTemplate(null);
        const resp = await fetchData("wapi/templates");
        if(resp){
            setWaTemplates(resp.data);
        }
        setIsLoadingWa(false);
    }

    const changeWaTemplate = (template) =>{
        setWaHeaderVariables([]);
        setWaHeaderComponents(null);
        setWaHeaderDocumentName('');
        setWaHeaderMedia('');
        setWaBodyVariables([]);
        setWaBodyComponent(null);
        setWaFooterComponent(null);
        setSelectedWaTemplate(template);
        if(!template){
            return;
        }
        const headerComponent = template.components.find(
            (component) => component.type === "HEADER"
        );
        setWaHeaderComponents(headerComponent);
        if(headerComponent && headerComponent.format === "TEXT" && headerComponent.variables.length > 0){
            setWaHeaderVariables(headerComponent.variables.map(() => ''));
        }
        const bodyComponent = template.components.find(
            (component) => component.type === "BODY"
        );
        const body = bodyComponent.text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,'<b>$1</b>')
            .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
            .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
            .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
            .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>')
            .replace(/\\n/g, '<br/>').replace(/\n/g, '<br/>');
        setWaBodyComponent({...bodyComponent, text: body});
        if(bodyComponent && bodyComponent.variables.length > 0){
            setWaBodyVariables(bodyComponent.variables.map(() => ''));
        }
        const footerComponent = template.components.find(
            (component) => component.type === "FOOTER"
        );
        setWaFooterComponent(footerComponent);
        const buttonComponent = template.components.find(
            (component) => component.type === "BUTTON"
        );
        setWaButtonComponent(buttonComponent);
    }
    const downloadFile = (mediaUrl, fileName) => {
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };
    const sendWhatsappTemplate = async() =>{
        setIsSubmitting(true);
        let obj = {
            templateName: selectedWaTemplate.name,
            language: selectedWaTemplate.language,
        }
        let templateData = {};
        if(waBodyVariables.length > 0){
            if (waBodyVariables.some(variable => variable === "")) {
                openSnackbar("Please fill all body variables", "error");
                setIsSubmitting(false);
                return;
            }
            templateData.body = {placeholders: waBodyVariables};
            obj.templateData = templateData;
        }
        if(waHeaderVariables.length > 0){
            if (waHeaderVariables.some(variable => variable === "")) {
                openSnackbar("Please fill all header variables", "error");
                setIsSubmitting(false);
                return;
            }
            templateData.header = {type: "TEXT", placeholders: waBodyVariables};
            obj.templateData = templateData;
        }else if(waHeaderComponents){
            if(waHeaderMedia !== ""){
                templateData.header = {type: waHeaderComponents.format, mediaUrl: waHeaderMedia};
                if(waHeaderComponents.format === "DOCUMENT"){
                    templateData.header = {...templateData.header, filename: waHeaderDocumentName}
                }
                obj.templateData = templateData;
            }
        }

        let resp = await postData('customers/whatsapptemplate', {obj: obj, id: customerId});
        if(resp){
            openSnackbar("Whatsapp Template Sent", "success");
            setWaModal(false);
        }

        setIsSubmitting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Customer Details</Typography>
                </Stack>}
                {customerDetails && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Customer Details</Typography>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                                <Typography variant='h6'>{customerDetails.name}</Typography>
                                {customerDetails.newlyIncorporated && <Chip sx={{margin: '5px'}} label={'New Incorportation'} color="success" />}
                            </Stack>
                        </Stack>
                        <Stack gap={1} alignItems={'flex-end'}>
                            <Stack flexDirection={'row'} gap={1}>
                                <Box><Button variant='contained' color='warning' onClick={openEmailModal}>Emails</Button></Box>
                                <Box><Button variant='contained' color='info' onClick={openNewJobModal}>Add Accountant Job</Button></Box>
                                <Box><Button variant='contained' onClick={openEditModal}>Edit</Button></Box>
                                <Box><Button variant='contained' color='success' onClick={openTemplateModal}>Send Template</Button></Box>
                            </Stack>
                            <Stack flexDirection={'row'} gap={1}>
                                <Box><Button variant='contained' color='success' onClick={openWaTemplateModal}>Send Whatsapp Template</Button></Box>
                                <Box><Button variant='contained' color='info' onClick={openDirectorModal}>Add Director</Button></Box>
                                <Box><Button variant='contained' color='success' onClick={openServiceModal}>Add Service</Button></Box>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack sx={{flexDirection: 'row', padding: 2, paddingTop: 0}}>
                        <Stack flex={1} gap={0.4}>
                            <Typography variant='h6'><b>Customer Name:</b> {customerDetails.name}</Typography>
                            <Typography variant='h6'><b>Customer Phone:</b> {customerDetails.phone}</Typography>
                            <Typography variant='h6'><b>Company Name:</b> {customerDetails.companyName}</Typography>
                            <Typography variant='h6'><b>Type:</b> {customerDetails.type}</Typography>
                            {customerDetails.gst && <Typography variant='h6'><b>GST:</b> {customerDetails.gst}</Typography>}
                            <Typography variant='h6'><b>Onboarding Date:</b> {moment(customerDetails.onboardingDate).format('Do MMMM YYYY')}</Typography>
                            <Typography variant='h6'><b>Incorporation Date:</b> {moment(customerDetails.incorporationDate).format('Do MMMM YYYY')}</Typography>
                            {customerDetails.saleBy && <Typography variant='h6'><b>Sales Person:</b> {customerDetails.saleBy.name}</Typography>}
                        </Stack>
                        <Stack flex={1} gap={0.4}>
                            <Stack>
                                <Typography variant='h6'><b>Emails</b></Typography>
                                {customerDetails.emails.map((email, index)=>{
                                    return <Typography key={index}>{email}</Typography>
                                })}
                            </Stack>
                            <Stack>
                                <Typography variant='h6'><b>Address</b></Typography>
                                <Typography>{customerDetails.address}</Typography>
                            </Stack>
                            <Typography variant='h6'><b>State:</b> {customerDetails.state.name}</Typography>
                            <Typography variant='h6'><b>Username:</b> {customerDetails.username}</Typography>
                            <Typography variant='h6'><b>Password:</b> {customerDetails.password}</Typography>
                        </Stack>
                    </Stack>

                    {customerDetails.directors.length > 0 && <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Typography variant='h5'><b>Directors</b></Typography>
                        <Grid2 container mt={1}>
                            {customerDetails.directors.map(director=>{
                            return <Grid2 size={3} key={director._id}>
                                <Box p={1}>
                                    <Paper elevation={4}>
                                        <Stack p={1.5} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
                                            <Box>
                                                <Typography variant='h6'>{director.name}</Typography>
                                                <Typography variant='body'>{director.phone}</Typography>
                                            </Box>
                                            <Box>
                                                <FontAwesomeIcon color='red' cursor={'pointer'} icon={faTrash} onClick={()=>{removeDirector(director)}}/>
                                            </Box>
                                        </Stack>
                                    </Paper>
                                </Box>
                            </Grid2>})}
                        </Grid2>
                    </Stack>}

                </Stack>}

                {customerDetails && customerDetails.compliance > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection:'row', alignItems:'center' ,justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}} gap={2}>
                        <Typography variant='h5'>Annual Compliance</Typography>
                        {customerDetails.financialYear && customerDetails.financialYear.length > 0 && <Stack flexDirection={'row'} gap={1}>
                            <Box width={250}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-label">Select Financial Year</InputLabel>
                                    <Select value={fyView} onChange={(e)=>{setFyView(e.target.value)}} label="Select Financial Year">
                                        {customerDetails.financialYear.map(fy=>{  
                                            return <MenuItem key={fy.id} value={fy}>{fy.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Button variant='contained' color='success' onClick={viewComplianceButton}>View</Button>
                        </Stack>}
                        <Button variant='contained' color='warning' onClick={()=>{setAddFyModal(true)}}>Add Financial Year</Button>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        {isLoadingCompliance && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                            <ClipLoader size={15} color='#000' />
                            <Typography variant='body'>Loading Compliance Details</Typography>
                        </Stack>}
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'35%'}>
                                <Typography><b>Compliance Name</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Expiry Date</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Status</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Completed On</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Accountant</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Modify</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {complianceList.map(compliance=>{
                            let eColor = 'success';
                            if (compliance.compliance.expiry && moment(compliance.compliance.expiry).isBefore(moment().add(10, 'days'))) {
                                eColor = 'warning';
                            }
                            if(compliance.compliance.expiry && moment(compliance.compliance.expiry).isBefore(moment())){
                                eColor = 'error';
                            }
                            if(compliance.status !== 'To Be Done'){
                                eColor = 'success';
                            }
                            return <Paper key={compliance.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'35%'}>
                                        <Typography color='#000'>{compliance.compliance.name}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        {compliance.compliance.expiry && <Typography color={eColor}>{moment(compliance.compliance.expiry).format('Do MMM YYYY')}</Typography>}
                                    </Box>  
                                    <Box width={'15%'}>
                                        <Typography>{compliance.status}</Typography>
                                    </Box>   
                                    <Box width={'15%'}>
                                        {compliance.doneOn && <Typography>{moment(compliance.doneOn).format('Do MMM YYYY h:mm a')}</Typography>}
                                    </Box>
                                    <Box width={'10%'}>
                                        {compliance.accountant && <Typography>{compliance.accountant.name}</Typography>}
                                    </Box>     
                                    <Box width={'10%'}>
                                        <Button size='small' variant='contained' color='success' onClick={()=>{openComplianceModal(compliance)}}>Modify</Button>
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>

                </Stack>}

                {activeServices.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Typography variant='h5'>Services</Typography>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'30%'}>
                                <Typography><b>Service Name</b></Typography>
                            </Box>
                            <Box width={'20%'}>
                                <Typography><b>Start Date</b></Typography>
                            </Box>
                            <Box width={'20%'}>
                                <Typography><b>End Date</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Status</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Add Invoice</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>End Service</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {activeServices.map(service=>{
                            return <Paper key={service.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'30%'}>
                                        <Typography color='#000'>{service.service.name}</Typography>
                                    </Box>  
                                    <Box width={'20%'}>
                                        <Typography>{moment(service.start).format('Do MMM YYYY')}</Typography>
                                    </Box>  
                                    <Box width={'20%'}>
                                        {service.end && <Typography>{moment(service.end).format('Do MMM YYYY')}</Typography>}
                                    </Box>  
                                    <Box width={'10%'}>
                                        <Typography color={service.status?"success":"error"}>{service.status?"Active":"Inactive"}</Typography>
                                    </Box>   
                                    <Box width={'10%'}>
                                        {service.status && <Button size='small' variant='contained' color='warning' onClick={()=>{openInvocieModal(service.service, service.id)}}>Add Invoice</Button>}
                                    </Box>  
                                    <Box width={'10%'}>
                                        {service.status && <Button size='small' variant='contained' color='error' onClick={()=>{endService(service)}}>END SERVICE</Button>}
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>

                </Stack>}

                {invoiceList.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Typography variant='h5'>Invoices</Typography>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'15%'}>
                                <Typography><b>Invoice Date</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Invoice No</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Linked Service</b></Typography>
                            </Box>
                            <Box width={'12%'}>
                                <Typography><b>Price</b></Typography>
                            </Box>
                            <Box width={'12%'}>
                                <Typography><b>GST</b></Typography>
                            </Box>
                            <Box width={'14%'}>
                                <Typography><b>Total</b></Typography>
                            </Box>
                            <Box width={'12%'}>
                                <Typography><b>Due</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>View</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {invoiceList.map(invoice=>{
                            let dueColor = "success";
                            if(invoice.due > 0){
                                dueColor = "error";
                            }
                            return <Paper key={invoice.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'15%'}>
                                        <Typography>{moment(invoice.invDate).format('Do MMM YYYY')}</Typography>
                                    </Box>  
                                    <Box width={'10%'}>
                                        <Typography>{invoice.invoiceNo}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        {invoice.linkedService && <Typography>{invoice.linkedService.service.name}</Typography>}
                                    </Box>  
                                    <Box width={'12%'}>
                                        <Typography>{currency} {invoice.price}</Typography>
                                    </Box>   
                                    <Box width={'12%'}>
                                        <Typography>{currency} {invoice.gst}</Typography>
                                    </Box> 
                                    <Box width={'14%'}>
                                        <Typography>{currency} {invoice.total}</Typography>
                                    </Box> 
                                    <Box width={'12%'}>
                                        <Typography color={dueColor}>{currency} {invoice.due}</Typography>
                                    </Box> 
                                    <Box width={'10%'}>
                                        <Link to={'/invoice/'+invoice.id}>
                                            <Button size='small' variant='contained'>View</Button>
                                        </Link>
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>

                </Stack>}

                {recurringInvoiceList.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Typography variant='h5'>Recurring Invoices</Typography>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'15%'}>
                                <Typography><b>Start Date</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>End Date</b></Typography>
                            </Box>
                            <Box width={'20%'}>
                                <Typography><b>Linked Service</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Interval</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Next Date</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>Status</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>View</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {recurringInvoiceList.map(invoice=>{
                            return <Paper key={invoice.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'15%'}>
                                        <Typography>{moment(invoice.startDate).format('Do MMM YYYY')}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        <Typography>{moment(invoice.endDate).format('Do MMM YYYY')}</Typography>
                                    </Box>  
                                    <Box width={'20%'}>
                                        {invoice.linkedService && <Typography>{invoice.linkedService.service.name}</Typography>}
                                    </Box>  
                                    <Box width={'15%'}>
                                        <Typography >{invoice.interval} {invoice.intervalType==="day"?"Days":"Months"}</Typography>
                                    </Box> 
                                    <Box width={'15%'}>
                                        {invoice.nextDate && <Typography>{moment(invoice.nextDate).format('Do MMM YYYY')}</Typography>}
                                        {!invoice.nextDate && <Typography fontWeight={'bold'}>Stopped</Typography>}
                                    </Box> 
                                    <Box width={'10%'}>
                                        <Typography color={invoice.status?"success":"error"}>{invoice.status?"Active":"Inactive"}</Typography>
                                    </Box> 
                                    <Box width={'10%'}>
                                        <Link to={'/recurringinvoice/'+invoice.id}>
                                            <Button size='small' variant='contained'>View</Button>
                                        </Link>
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>

                </Stack>}

                {templateHistory.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Typography variant='h5'>Email Template History</Typography>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'20%'}>
                                <Typography><b>Date</b></Typography>
                            </Box>
                            <Box width={'70%'}>
                                <Typography><b>Name</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>View</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {templateHistory.map(template=>{
                            return <Paper key={template.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'20%'}>
                                        <Typography>{moment(template.created).format('Do MMM YYYY h:mm a')}</Typography>
                                    </Box>  
                                    <Box width={'70%'}>
                                        <Typography color='#000'>{template.template.name}</Typography>
                                    </Box>    
                                    <Box width={'10%'}>
                                        <Button size='small' variant='contained' color='info' onClick={()=>{openTemplateView(template)}}>View</Button>
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>

                </Stack>}

            </Stack>

            <Modal open={serviceModal} onClose={closeModal} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: 400,
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    p: 4,
                }}>
                    <Typography variant="h5">Add Service</Typography>
                    <Stack mt={2} mb={2} gap={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Service</InputLabel>
                            <Select label="Select Service" value={selectedService} onChange={handleServiceChange}>
                                {
                                    servicesList.map((item, index)=>{
                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>  
                        <FormControl fullWidth>
                            <DatePicker format='MMM DD, YYYY' label="Service Start Date" value={serviceDate} onChange={(newValue) => setServiceDate(newValue)} defaultValue={dayjs()} disableFuture={true} />
                        </FormControl>  

                        <TextField label="Professional Fees" type='number' value={professionalFees} onChange={(e)=>{setProfessionalFees(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>

                        <TextField label="Government Fees" type='number' value={govtFees} onChange={(e)=>{setGovtFees(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>

                        <TextField label="GST" type='number' value={gstFees} onChange={(e)=>{setGstFees(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                endAdornment: (
                                <InputAdornment position="start">
                                    <Typography>%</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={invRecurring || false}
                                    onChange={(e) => setInvRecurring(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Recurring Invoice?"
                        />

                        {invRecurring && <Stack flexDirection={'row'} gap={1}>
                            <TextField label="Interval" type='number' value={invInternal} onChange={(e)=>{setInvInternal(e.target.value)}} variant="outlined" fullWidth required/>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Interval Type</InputLabel>
                                <Select label="Interval Type" value={invIntervalType} onChange={(e)=>{setInvIntervalType(e.target.value)}}>
                                    <MenuItem value={'day'}>Day</MenuItem>
                                    <MenuItem value={'month'}>Month</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>}

                        {invRecurring && <FormControl fullWidth>
                            <DatePicker format='MMM DD, YYYY' label="Invoice End Date" value={invEndDate} onChange={(newValue) => setInvEndDate(newValue)} defaultValue={dayjs()} disablePast={true} />
                        </FormControl> }

                    </Stack>
                    <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Store/>} onClick={submitService}><span>Add Service</span></LoadingButton>
                </Box>
            </Modal>

            <Modal open={invoiceModal} onClose={closeModal} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: 400,
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    p: 4,
                }}>
                    <Typography variant="h5">Add Invoice</Typography>
                    {invService && <Typography variant="h6">{invService.name}</Typography>}
                    <Stack mt={2} mb={2} gap={2}>
                        
                        <FormControl fullWidth>
                            <DatePicker format='MMM DD, YYYY' label={invRecurring?"Invoice Start Date":"Invoice Date"} value={serviceDate} onChange={(newValue) => setServiceDate(newValue)} defaultValue={dayjs()} disableFuture={true} />
                        </FormControl>  

                        <TextField label="Professional Fees" type='number' value={professionalFees} onChange={(e)=>{setProfessionalFees(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>

                        <TextField label="Government Fees" type='number' value={govtFees} onChange={(e)=>{setGovtFees(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>

                        <TextField label="GST" type='number' value={gstFees} onChange={(e)=>{setGstFees(e.target.value)}} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                                endAdornment: (
                                <InputAdornment position="start">
                                    <Typography>%</Typography>
                                </InputAdornment>
                                ),
                            },
                        }}/>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={invRecurring || false}
                                    onChange={(e) => setInvRecurring(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Recurring Invoice?"
                        />

                        {invRecurring && <Stack flexDirection={'row'} gap={1}>
                            <TextField label="Interval" type='number' value={invInternal} onChange={(e)=>{setInvInternal(e.target.value)}} variant="outlined" fullWidth required/>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Interval Type</InputLabel>
                                <Select label="Interval Type" value={invIntervalType} onChange={(e)=>{setInvIntervalType(e.target.value)}}>
                                    <MenuItem value={'day'}>Day</MenuItem>
                                    <MenuItem value={'month'}>Month</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>}

                        {invRecurring && <FormControl fullWidth>
                            <DatePicker format='MMM DD, YYYY' label="Invoice End Date" value={invEndDate} onChange={(newValue) => setInvEndDate(newValue)} defaultValue={dayjs()} disablePast={true} />
                        </FormControl> }
                    </Stack>
                    <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Receipt/>} onClick={submitInvoice}><span>Add Invoice</span></LoadingButton>
                </Box>
            </Modal>

            <Modal open={addFyModal} onClose={closeModal} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '8px',
                    p: 4,
                }}>
                    <Typography variant="h5">Add Financial Year</Typography>
                    <Stack mt={2} mb={2} gap={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Financial Year</InputLabel>
                            <Select label="Select Financial Year" value={selectedFy} onChange={(e)=>{setSelectedFy(e.target.value)}}>
                                {
                                    fyList.map((item, index)=>{
                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>  
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useInc20 || false}
                                    onChange={(e) => setUseInc20(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Add INC-20?"
                        />
                    </Stack>
                    <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<CalendarMonth/>} onClick={submitFy}><span>Add Financial Year</span></LoadingButton>
                </Box>
            </Modal>

            <Modal open={emailModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Emails</Typography>
                        <Stack gap={1}>
                            {editEmailsList.map((email, index)=>{
                                return <Stack key={index} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body'>{email}</Typography>
                                    <Typography color='error' sx={{cursor: 'pointer'}} onClick={()=>{removeEmail(email)}}><FontAwesomeIcon icon={faTrash}/></Typography>
                                </Stack>
                            })}
                        </Stack>
                        <Stack flexDirection={'row'} gap={2}>
                            <TextField label="Add Email" type='email' value={editEmailField} onChange={(e)=>{setEditEmailField(e.target.value)}} variant="outlined" fullWidth/>
                            <Button variant='contained' color='success' onClick={addEmail}>Add</Button>
                        </Stack>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Email/>} onClick={submitEmails}><span>Update Emails</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={editCustomerModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={updateCustomer}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Customer</Typography>
                        <TextField label="Name" name='name' value={editCustomer.name} onChange={handleEditCustomer} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={editCustomer.phone} onChange={handleEditCustomer} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>
                        <TextField label="Company Name" name='company' value={editCustomer.company} onChange={handleEditCustomer} variant="outlined" fullWidth required/>
                        
                        <TextField label="Address" name='address' value={editCustomer.address} onChange={handleEditCustomer} variant="outlined" fullWidth multiline rows={4} required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select label="State" name="state" value={editCustomer.state} onChange={handleEditCustomer} variant="outlined">
                                {statesList.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField label="GST" name='gst' value={editCustomer.gst} onChange={handleEditCustomer} variant="outlined" fullWidth/>
                        
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select label="Type" name="type" value={editCustomer.type} onChange={handleEditCustomer} variant="outlined">
                                <MenuItem value={'Private Limited Company'}>Private Limited Company</MenuItem>
                                <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                                <MenuItem value={'Public Limited Company'}>Public Limited Company</MenuItem>
                                <MenuItem value={'Section 8'}>Section 8</MenuItem>
                                <MenuItem value={'FPC'}>FPC</MenuItem>
                                <MenuItem value={'LLP'}>LLP</MenuItem>
                                <MenuItem value={'Trust'}>Trust</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label="Incorporation Date"
                            value={editCustomer.incorporation}
                            format='MMM DD, YYYY'
                            onChange={(newValue) => handleEditCustomer({ target: { name: 'incorporation', value: newValue } })}
                            defaultValue={dayjs()}
                            />
                        </LocalizationProvider>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={editCustomer.newlyIncorporated || false}
                                    onChange={(e) => handleEditCustomer({ target: { name: 'newlyIncorporated', value: e.target.checked } })}
                                    name="newlyIncorporated"
                                    color="primary"
                                />
                            }
                            label="Newly Incorporated?"
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label="Onboarding Date"
                            value={editCustomer.onboarding}
                            format='MMM DD, YYYY'
                            onChange={(newValue) => handleEditCustomer({ target: { name: 'onboarding', value: newValue } })}
                            defaultValue={dayjs()}
                            />
                        </LocalizationProvider>

                        <TextField label="Username" name='username' value={editCustomer.username} onChange={handleEditCustomer} variant="outlined" fullWidth required/>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<PersonPin/>}><span>Update Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={complianceModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    {selectedCompliance && <Stack gap={2}>
                        <Typography variant='h6'>{selectedCompliance.compliance.name}</Typography>
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Compliance Status</InputLabel>
                            <Select label="Compliance Status" value={complianceStatus} onChange={(e)=>{setComplianceStatus(e.target.value)}}>
                                <MenuItem value={'Done'}>Done</MenuItem>
                                <MenuItem value={'Ongoing'}>Ongoing</MenuItem>
                                <MenuItem value={'To Be Done'}>To Be Done</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Assigned Accountant</InputLabel>
                            <Select label="Assigned Accountant" value={complianceAccoutant} onChange={(e)=>{setComplianceAccoutant(e.target.value)}}>
                                <MenuItem value={'None'}>None</MenuItem>
                                {accountantsList.map((accountant, index)=>{
                                    return <MenuItem key={index} value={accountant.id}>{accountant.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<TaskAlt/>} onClick={updateCompliance}><span>Update Compliance</span></LoadingButton>
                    </Stack>}
                </Box>
            </Modal>

            <Modal open={directorModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add Director</Typography>
                        <TextField label="Name" name='name' value={directorName} onChange={(e)=>{setDirectorName(e.target.value)}} variant="outlined" fullWidth required/>
                            <TextField label="Phone" name='phone' type='number' value={directorPhone} onChange={(e)=>{setDirectorPhone(e.target.value)}} variant="outlined" fullWidth required
                            slotProps={{
                                input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography>+91</Typography>
                                    </InputAdornment>
                                ),
                                },
                            }}/>
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<PersonPin/>} onClick={addDirector}><span>Add Director</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={templateModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 500px)',width: 1000,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant="h5">Send Email Template</Typography> 
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Email Template</InputLabel>
                            <Select label="Select Email Template" value={selectedTemplate} onChange={(e)=>{setSelectedTemplate(e.target.value)}}>
                                {
                                    templateList.map((item, index)=>{
                                        return <MenuItem key={index} value={item}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>  
                        <TextField label="Subject" value={templateSubject} onChange={(e)=>{setTemplateSubject(e.target.value)}} variant="outlined" fullWidth/>
                        <Stack flexDirection={'row'} gap={2}>
                            <Chip label="{{name}}" color='info'/>
                            <Chip label="{{companyName}}" color='info'/>
                            <Chip label="{{address}}" color='info'/>
                        </Stack>
                        <Editor
                            apiKey='k11h0lei05kosgmjaodsq6on5o1hs0zxi8b222tjelc1kr0v'
                            init = {{
                                height: '600',
                                plugins: 'table accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help importcss insertdatetime link lists nonbreaking pagebreak preview quickbars save searchreplace visualblocks visualchars wordcount',
                                toolbar: 'undo redo bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify outdent indent code anchor emoticons fontsize table link bullist numlist pagebreak preview  quicklink accordion fullscreen',
                                paste_data_images: false,
                                relative_urls : false
                            }}
                            onEditorChange={(newValue, editor) => setTemplateContent(newValue)}
                            value={templateContent}
                        />
                        {selectedTemplate && selectedTemplate.attachments.length > 0 && <Stack>
                            <Typography><b>Attachments:</b></Typography>
                            {selectedTemplate.attachments.map(item=>{
                                return <Typography key={item._id}>{item.filename}</Typography>
                            })}    
                        </Stack>}
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} loadingPosition='start' startIcon={<Email/>} onClick={sendEmail}><span>Send Email</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={viewTemplateModal} onClose={()=>{setViewTemplateModal(false)}}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 500px)',width: 1000,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    {viewTemplate && <Stack gap={2}>
                        <TextField label="Subject" value={viewTemplate.subject} variant="outlined" fullWidth slotProps={{input: {readOnly: true}}} />
                        <Editor
                            apiKey='k11h0lei05kosgmjaodsq6on5o1hs0zxi8b222tjelc1kr0v'
                            init = {{
                                height: '750',
                                menubar: false,
                                toolbar: false,
                                readonly: true
                            }}
                            value={viewTemplate.content}
                        />
                    </Stack>}
                </Box>
            </Modal>

            <Modal open={jobModal} onClose={closeModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewJob}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Job</Typography>
                        <TextField label="Job Title" name='title' value={jobData.title} onChange={handleNewJob} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <InputLabel>Status</InputLabel>
                            <Select label="Status" value={jobData.status} name='status' onChange={handleNewJob}>
                                <MenuItem value={'Done'}>Done</MenuItem>
                                <MenuItem value={'Ongoing'}>Ongoing</MenuItem>
                                <MenuItem value={'To Be Done'}>To Be Done</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Accountant</InputLabel>
                            <Select label="Accountant" value={jobData.accountant} name='accountant' onChange={handleNewJob} >
                                {accountantsList.map((acc) => {
                                    return <MenuItem key={acc.id} value={acc.id}>{acc.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={jobData.isExpiry || false}
                                    onChange={(e) => handleNewJob({ target: { name: 'isExpiry', value: e.target.checked } })}
                                    name="isExpiry"
                                    color="primary"
                                />
                            }
                            label="Has Expiry Date?"
                        />
                        {jobData.isExpiry && <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expiry Date"
                                value={jobData.expiryDate}
                                format='MMM DD, YYYY'
                                onChange={(newValue) => handleNewJob({ target: { name: 'expiryDate', value: newValue } })}
                                defaultValue={dayjs()}
                                disablePast={true}
                                />
                        </LocalizationProvider>}
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<WorkOutline/>}><span>Add New Job</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={waModal} onClose={closeModal}>
                <Box sx={{position: 'absolute',top: '10%', left: '10%', right: '10%', bottom: '10%', overflowY: 'auto', bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Send Whatsapp Template</Typography>
                        <Stack>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={selectedWaTemplate}
                                    onChange={(event, newValue) => {
                                        changeWaTemplate(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    options={waTemplates}
                                    getOptionLabel={(option) => `${option.name}`}
                                    renderInput={(params) => <TextField {...params} label="Select Whatsapp Template" />}
                                    />
                            </FormControl>
                            {isLoadingWa && <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Whatsapp Templates</Typography>
                            </Stack>}
                        </Stack>
                    </Stack>
                    {selectedWaTemplate && <Stack flexDirection={'row'}>
                        <Stack flex={1}>
                            {waHeaderComponents && waHeaderComponents.variables.length > 0 && <Stack mt={3} gap={1}>
                                <Typography><b>Header Variables ({waHeaderComponents.format})</b></Typography>
                                {waHeaderComponents.format !== "TEXT" && <TextField type='url' label="Media URL" value={waHeaderMedia} onChange={(e)=>{setWaHeaderMedia(e.target.value)}} variant="outlined" fullWidth />}
                                {waHeaderComponents.format === "DOCUMENT" && <TextField label="File Name" value={waHeaderDocumentName} onChange={(e)=>{setWaHeaderDocumentName(e.target.value)}} variant="outlined" fullWidth />}
                                {waHeaderVariables.map((variable, index) => (
                                    <TextField
                                        key={index}
                                        label={waHeaderComponents.variables[index].name}
                                        value={variable}
                                        onChange={(e) => {
                                            const newVariables = [...waHeaderVariables];
                                            newVariables[index] = e.target.value;
                                            setWaHeaderVariables(newVariables);
                                        }}
                                        variant="outlined"
                                        fullWidth required
                                    />
                                ))}
                            </Stack>}
                            {waBodyVariables.length > 0 && <Stack mt={3} gap={1}>
                                <Typography><b>Body Variables</b></Typography>
                                {waBodyVariables.map((variable, index) => (
                                    <TextField
                                        key={index}
                                        label={waBodyComponent.variables[index].name}
                                        value={variable}
                                        onChange={(e) => {
                                            const newVariables = [...waBodyVariables];
                                            newVariables[index] = e.target.value;
                                            setWaBodyVariables(newVariables);
                                        }}
                                        variant="outlined"
                                        fullWidth required
                                    />
                                ))}
                            </Stack>}
                            <Box mt={5}>
                                <LoadingButton fullWidth onClick={sendWhatsappTemplate} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<WhatsApp/>}><span>Send Template via Whatsapp</span></LoadingButton>
                            </Box>
                        </Stack>
                        <Stack flex={1} p={2} justifyContent={'end'} alignItems={'flex-end'}>
                            <Stack sx={{width: 400, borderRadius: '18px', borderBottomRightRadius: '4px', p: 1.5, backgroundColor: '#075e54'}} gap={1}>
                                {waHeaderComponents && <Stack>
                                    {waHeaderComponents.format === "IMAGE" && <img src={waHeaderMedia !== ""?waHeaderMedia:waHeaderComponents.variables[0].mediaUrl} alt="" style={{width: '100%', maxHeight: '500px'}} />}
                                    {waHeaderComponents.format === "VIDEO" && <video src={waHeaderMedia !== ""?waHeaderMedia:waHeaderComponents.variables[0].mediaUrl} controls style={{width: '100%',maxHeight: '500px'}} />}
                                    {waHeaderComponents.format === "DOCUMENT" && <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{borderRadius: 2, p:1, background: 'rgba(0,0,0,0.1)', color: '#fff'}}>
                                        <Typography>{waHeaderMedia !== ""?waHeaderDocumentName:waHeaderComponents.variables[0].fileName}</Typography>
                                        <IconButton onClick={()=>{downloadFile(waHeaderComponents.variables[0].mediaUrl,waHeaderComponents.variables[0].fileName)}}>
                                            <Download color='#fff'/>
                                        </IconButton>
                                    </Stack>}
                                </Stack>}
                                {waBodyComponent && <Stack>
                                    <Typography variant="body1" color={'#fff'}>
                                        {parse(waBodyComponent.text)}
                                    </Typography>
                                </Stack>}
                                {waFooterComponent && <Stack>
                                    <Typography variant="body2" color={'#eee'}>
                                        {waFooterComponent.text}
                                    </Typography>
                                </Stack>}
                                {waButtonComponent && <Stack gap={1}>
                                    {waButtonComponent.buttons.map((btn, index)=>{
                                        let startIcon = <Chat />
                                        if(btn.type === "QUICK_REPLY"){
                                            startIcon = <Reply />
                                        }else if(btn.type === "URL"){
                                            startIcon = <Public />
                                        }else if(btn.type === "PHONE_NUMBER"){
                                            startIcon = <LocalPhone/>
                                        }
                                        return <Button key={index} variant='contained' size='small' startIcon={startIcon}>{btn.text}</Button>
                                    })}    
                                </Stack>}
                            </Stack>
                        </Stack>
                    </Stack>}
                </Box>
            </Modal>

        </Stack>
    )
}

export default CustomerDetails