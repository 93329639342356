import { Autocomplete, Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { Close, DeleteForever, ModeEdit, SearchTwoTone, WorkOutline } from '@mui/icons-material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from 'rsuite';
import { LoadingButton } from '@mui/lab';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';

function AccountantJobs() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar, openDialog} = useCommonUI();
    const [isLoading, setIsLoading] = useState();
    const [totalDocs, setTotalDocs] = useState(0);
    const [page, setPage] = useState(2);
    const containerRef = useRef();
    const [jobsList, setJobsList] = useState([]);
    const [accountantList, setAccountantList] = useState([]);
    const [customersList, setCustomerList] = useState([]);
    const [bodyData, setBodyData] = useState({accountant: [], status: [], name: '', start: 0, end: 0, dtype: "created"});
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateType, setDateType] = useState('created');
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedAccountant, setSelectedAccountant] = useState([]);

    const [jobModal, setJobModal] = useState(false);
    const [jobData, setJobData] = useState({id: null, title: '', customer: null, accountant: '', expiryDate: null, isExpiry: false, status: 'To Be Done'});
    const [inputValue, setInputValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('users/accountants');
            const resp1 = await fetchData("invoice/customers");
            if(resp && resp1){
                setAccountantList(resp.data);
                setCustomerList(resp1.data);
            }
        }
        getLists();
    },[fetchData])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setJobsList([]);
            const resp = await postData('accountant/jobs/1', {accountant: [], status: [], name: '', start: 0, end: 0, dtype: "created"});
            if(resp){
                setJobsList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('accountant/jobs/'+page, body);
        if(resp){
            setJobsList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && jobsList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, jobsList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleAccountantChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedAccountant(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleStatusChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({accountant: [], status: [], name: '', start: 0, end: 0, dtype: "created"});
        setJobsList([]);
        setSearchTerm('');
        setSelectedAccountant([]);
        setSelectedStatus([]);
        setDateType('created');
        setSelectedDate(null);
        getData(1, {accountant: [], status: [], name: '', start: 0, end: 0, dtype: "created"});
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            name: searchTerm,
            accountant: selectedAccountant,
            status: selectedStatus,
            start,
            end,
            dtype: dateType
        }
        setBodyData(body);
        setJobsList([]);
        getData(1, body);
    }

    const openNewJobModal = () =>{
        setJobData({id: null, title: '', customer: null, accountant: '', expiryDate: null, isExpiry: false, status: 'To Be Done'});
        setJobModal(true);
    }
    const editNewJobModal = (job) =>{
        setJobData({id: job.id, title: job.jobTitle, customer: job.customer?job.customer:null, accountant: job.accountant.id, expiryDate: job.expiryDate?dayjs(job.expiryDate):null, isExpiry: job.expiryDate?true:false, status: job.status});
        setJobModal(true);
    }

    const closeJobModal = () =>{
        if(isSubmitting){
            return;
        }
        setJobModal(false);
    }

    const handleNewJob = (e) => {
        const { name, value } = e.target;
        setJobData({ ...jobData, [name]: value });
    };
    
    const submitNewJob = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        let resp = null;
        if(jobData.id){
            resp = await postData('accountant/edit', jobData);
        }else{
            resp = await postData('accountant/new', jobData);
        }
        
        if(resp){
            if(jobData.id){
                setJobsList((prevList) =>
                    prevList.map((job) =>
                        job.id === jobData.id ? resp.data : job
                    )
                );
                openSnackbar("Job Updated", "success");
            }else{
                setTotalDocs((total)=>(total+1))
                if(page){
                    setJobsList((prevArray) => {
                        const updatedArray = [resp.data, ...prevArray.slice(0, prevArray.length - 1)];
                        return updatedArray;
                    });
                }else{
                    setJobsList((prevArray) => [resp.data, ...prevArray]);
                }
                openSnackbar("Job Added", "success");
            }
            setJobModal(false);
        }
        setIsSubmitting(false);
    }

    const removeJob = (job) =>{
        if(isDeleting){
            openSnackbar('Wait for previous remove request to complete');
            return;
        }
        openDialog("Delete this Job Permanently?", `Job: ${job.jobTitle} for ${job.accountant.name}`, confirmDeleteJob, job.id);
    }
    const confirmDeleteJob = async(jid) =>{
        const tid = toast.loading('Deleting Job',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await fetchData('accountant/delete/'+jid);
        if(resp){
            toast.update(tid, { render: "Job Deleted", type: "success", isLoading: false, autoClose: 2000 });
            setJobsList([]);
            getData(1, {accountant: [], status: [], name: '', start: 0, end: 0, dtype: "created"});
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Accountant Jobs ({totalDocs})</Typography>
                    <Button variant='contained' onClick={openNewJobModal}>New Job</Button>
                </Stack>
                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2}>
                        <TextField fullWidth variant='outlined' label='Name/Phone/Company Name/Job Title' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                        <FormControl fullWidth>
                            <InputLabel>Date Type</InputLabel>
                            <Select value={dateType} onChange={(e)=>{setDateType(e.target.value)}} >
                                <MenuItem value='created'>Created</MenuItem>
                                <MenuItem value='expiry'>Expiry Date</MenuItem>
                                <MenuItem value='doneOn'>Completed On</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack>
                            <Box width={250}></Box>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" value={selectedDate} onChange={setSelectedDate} />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select multiple value={selectedStatus} onChange={handleStatusChange} input={<OutlinedInput label="Chip" />} >
                                <MenuItem value={'Done'}>Done</MenuItem>
                                <MenuItem value={'Ongoing'}>Ongoing</MenuItem>
                                <MenuItem value={'To Be Done'}>To Be Done</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Accountants</InputLabel>
                            <Select multiple value={selectedAccountant} onChange={handleAccountantChange} input={<OutlinedInput label="Chip" />} >
                                {accountantList.map((acc) => {
                                    return <MenuItem key={acc.id} value={acc.id}>{acc.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <IconButton type='submit'>
                            <SearchTwoTone fontSize='large' color='primary' />
                        </IconButton>
                        <IconButton type='reset'>
                            <Close fontSize='large' color='primary' />
                        </IconButton>
                    </Stack>
                </Box>

                <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' gap={1} px={3.2}>
                    <Box width='10%'>
                        <Typography><b>Created</b></Typography>
                    </Box>
                    <Box width='25%'>
                        <Typography><b>Job Name</b></Typography>
                    </Box>
                    <Box width='20%'>
                        <Typography><b>Customer</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Expiry</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Status</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Completed</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Accountant</b></Typography>
                    </Box>
                    <Box width='5%'>
                        <Typography><b>Modify</b></Typography>
                    </Box>
                </Stack>

                <Stack mt={1} p={2} pt={0} height='calc(100vh - 300px)' overflow='auto' gap={3} ref={containerRef}>

                    {jobsList.map(job=>{
                        let eColor = 'success';
                        if (job.expiryDate && moment(job.expiryDate).isBefore(moment().add(10, 'days'))) {
                            eColor = 'warning';
                        }
                        if(job.expiryDate && moment(job.expiryDate).isBefore(moment())){
                            eColor = 'error';
                        }
                        if(job.status !== 'To Be Done'){
                            eColor = 'success';
                        }

                        return <Stack key={job.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'10%'}>
                                <Typography>{moment(job.created).format('Do MMM yyyy h:mm a')}</Typography>
                            </Box>
                            <Stack width={'25%'}>
                                <Typography color='#000'>{job.jobTitle}</Typography>
                            </Stack>
                            <Stack width={'20%'}>
                                {job.customer && <Box width={'fit-content'}>
                                    <Link to={`/customer/${job.customer.id}`} style={{textDecoration: 'none'}}>
                                        <Typography color={'#000'}>{job.customer.name}</Typography>
                                    </Link>
                                </Box>}
                                {job.customer && <Typography>+91 {job.customer.phone}</Typography>}
                                {job.customer && <Typography>{job.customer.companyName}</Typography>}
                            </Stack>
                            <Box width={'10%'}>
                                {job.expiryDate && <Typography color={eColor}>{moment(job.expiryDate).format('Do MMM yyyy')}</Typography>}
                            </Box>
                            <Box width={'10%'}>
                                <Typography>{job.status}</Typography>
                            </Box>   
                            <Box width={'10%'}>
                                {job.doneOn && <Typography>{moment(job.doneOn).format('Do MMM YYYY h:mm a')}</Typography>}
                            </Box>
                            <Box width={'10%'}>
                                {job.accountant && <Typography>{job.accountant.name}</Typography>}
                            </Box>  
                            <Stack width={'5%'} gap={0} flexDirection={'row'} pr={1}>
                                {!job.compliance && <IconButton onClick={()=>{editNewJobModal(job)}}>
                                    <ModeEdit color='warning' />
                                </IconButton>}
                                {!job.compliance && <IconButton onClick={()=>{removeJob(job)}}>
                                    <DeleteForever color='error' />
                                </IconButton>}
                            </Stack>
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Accountant Jobs</Typography>
                    </Stack>}
                </Stack>
                
            </Stack>
            <Modal open={jobModal} onClose={closeJobModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewJob}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{jobData.id?"Edit":"Add New"} Job</Typography>
                        <TextField label="Job Title" name='title' value={jobData.title} onChange={handleNewJob} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <Autocomplete
                                value={jobData.customer}
                                onChange={(event, newValue) => {
                                    handleNewJob({ target: { name: 'customer', value: newValue } })
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                options={customersList}
                                getOptionLabel={(option) => `${option.name} - ${option.companyName}`}
                                renderInput={(params) => <TextField {...params} label="Select Customer" />}
                                />
                        </FormControl>

                        <FormControl fullWidth required>
                            <InputLabel>Status</InputLabel>
                            <Select label="Status" value={jobData.status} name='status' onChange={handleNewJob}>
                                <MenuItem value={'Done'}>Done</MenuItem>
                                <MenuItem value={'Ongoing'}>Ongoing</MenuItem>
                                <MenuItem value={'To Be Done'}>To Be Done</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Accountant</InputLabel>
                            <Select label="Accountant" value={jobData.accountant} name='accountant' onChange={handleNewJob} >
                                {accountantList.map((acc) => {
                                    return <MenuItem key={acc.id} value={acc.id}>{acc.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={jobData.isExpiry || false}
                                    onChange={(e) => handleNewJob({ target: { name: 'isExpiry', value: e.target.checked } })}
                                    name="isExpiry"
                                    color="primary"
                                />
                            }
                            label="Has Expiry Date?"
                        />
                        {jobData.isExpiry && <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expiry Date"
                                value={jobData.expiryDate}
                                format='MMM DD, YYYY'
                                onChange={(newValue) => handleNewJob({ target: { name: 'expiryDate', value: newValue } })}
                                defaultValue={dayjs()}
                                disablePast={true}
                                />
                        </LocalizationProvider>}
                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<WorkOutline/>}><span>{jobData.id?"Update":"Add New"} Job</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default AccountantJobs