import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useCommonUI } from '../context/UI';
import { currency, useApiRequest } from '../store/Common';
import { PersonAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ClipLoader } from 'react-spinners';

function ServicesPage() {

    const {openSnackbar} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [servicesList, setServicesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [templateList, setTemplateList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');

    const [newServiceModal, setNewServiceModal] = useState(false);
    const [newService, setNewService] = useState({ name: '', status: true, professional: 0, govt: 0, hsn: '' });
    const [editServiceId, setEditServiceId] = useState(null);
    const [isSubmittingService, setIsSubmittingService] = useState(false);

    useEffect(()=>{
        const getLists = async() =>{
            const resp3 = await fetchData('template/list');
            if(resp3){
                setTemplateList(resp3.data);
                if(resp3.data.length > 1){
                    setSelectedTemplate(resp3.data[0]);
                }
            }
        }
        getLists();
    },[fetchData])

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setServicesList([]);
        const resp = await fetchData('services/all');
        if(resp){
            setServicesList(resp.data);
        }
        setIsLoading(false);
    },[fetchData])

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const handleNewService = (e) => {
        const { name, value } = e.target;
        setNewService({ ...newService, [name]: value });
    };
    const openNewServiceModal = () =>{
        setNewService({ name: '', status: true, professional: 0, govt: 0, hsn: '' });
        setEditServiceId(null);
        setSelectedTemplate(templateList[0]);
        setNewServiceModal(true);
    }
    const openEditServiceModal = (service) =>{
        setNewService({ name: service.name, status: service.status, professional: service.professional, govt: service.govt, hsn: service.hsn });
        setEditServiceId(service.id);
        setSelectedTemplate(templateList.find(t=>t.id === service.template.id));
        setNewServiceModal(true);
    }
    const closeNewServiceModal = () =>{
        if(isSubmittingService){
            return;
        }
        setNewServiceModal(false);
    }

    const submitNewService = async(e) =>{
        e.preventDefault();
        if(selectedTemplate === ""){
            return;
        }
        setIsSubmittingService(true);
        if(editServiceId){
            const resp = await postData('services/update', {id: editServiceId, ...newService, template: selectedTemplate.id});
            if(resp){
                setServicesList((prevList) =>
                    prevList.map((service) =>
                        service.id === editServiceId ? resp.data : service
                    )
                );
                setNewServiceModal(false);
                openSnackbar("Service Updated", "success");
            }
        }else{
            const resp = await postData('services/new', {...newService, template: selectedTemplate.id});
            if(resp){
                setServicesList((prevArray) => [...prevArray, resp.data]);
                setNewServiceModal(false);
                openSnackbar("Service Added", "success");
            }
        }
        setIsSubmittingService(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>

                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Services ({servicesList.length})</Typography>
                    <Button variant='contained' onClick={openNewServiceModal}>New Service</Button>
                </Stack>

                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Services</Typography>
                </Stack>}
                
                <Stack mt={5} flexDirection='row'alignItems='center' width='100%' paddingX={4} gap={1}>
                    <Box width='25%'>
                        <Typography><b>Name</b></Typography>
                    </Box>
                    <Box width='25%'>
                        <Typography><b>Email Template</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>HSN Code</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Professional Fees</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Govt Fees</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Status</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Edit</b></Typography>
                    </Box>
                </Stack>
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={3} ref={containerRef}>
                    {servicesList.map(service=>{
                            return <Stack key={service.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Stack width={'25%'}>
                                <Typography variant='h6' color='#000'>{service.name}</Typography>
                            </Stack>
                            <Stack width={'25%'}>
                                <Typography>{service.template.name}</Typography>
                            </Stack>
                            <Stack width={'10%'}>
                                <Typography>{service.hsn}</Typography>
                            </Stack>
                            <Stack width={'10%'}>
                                <Typography>{currency} {service.professional}</Typography>
                            </Stack>
                            <Stack width={'10%'}>
                                <Typography>{currency} {service.govt}</Typography>
                            </Stack>
                            <Stack width={'10%'}>
                                <Typography color={service.status?"success":"error"}>{service.status?"Active":"Inactive"}</Typography>
                            </Stack>
                            <Box width={'10%'}>
                                <Button size='small' variant='contained' onClick={()=>{openEditServiceModal(service)}}>Edit</Button>
                            </Box>
                        </Stack>
                    })}
                </Stack>

            </Stack>

            <Modal open={newServiceModal} onClose={closeNewServiceModal}>
                <Box component="form" sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewService}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{editServiceId?"Edit":"Add New"} Service</Typography>
                        <TextField label="Name" name='name' value={newService.name} onChange={handleNewService} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <InputLabel>Status</InputLabel>
                            <Select label="Status" name="status" value={newService.status} onChange={handleNewService} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth required>
                            <InputLabel>Service Start Email Template</InputLabel>
                            <Select label="Service Start Email Template" value={selectedTemplate} onChange={(e)=>{setSelectedTemplate(e.target.value)}} variant="outlined">
                                {templateList.map(template=>{
                                    return <MenuItem key={template.id} value={template}>{template.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField label="Professional Fees" name='professional' type='number' value={newService.professional} onChange={handleNewService} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>

                        <TextField label="Government Fees" name='govt' type='number' value={newService.govt} onChange={handleNewService} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{currency}</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>

                        <TextField label="HSN Code" name='hsn' value={newService.hsn} onChange={handleNewService} variant="outlined" fullWidth required/>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingService}  loadingPosition='start' startIcon={<PersonAdd/>}><span>{editServiceId?"Update":"Add New"} Service</span></LoadingButton>

                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default ServicesPage