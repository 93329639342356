import { Avatar, Box, Button, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { stringAvatar, useApiRequest } from '../store/Common';
import { LoadingButton } from '@mui/lab';
import { Close, PersonAdd, SearchTwoTone } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';

function CustomersPage() {

    const {openSnackbar} = useCommonUI();
    const {afterToday} = DateRangePicker;
    const { fetchData, postData} = useApiRequest();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [customersList, setCustomersList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [bodyData, setBodyData] = useState({type: [], service: [], name: '', start: 0, end: 0, dtype: "onboardingDate"});
    const [statesList, setStatesList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(''); 

    const [newCustomerModal, setNewCustomerModal] = useState(false);
    const [newCustomer, setNewCustomer] = useState({ name: '', phone: '', company: '', address: '', gst: '', type: 'Private Limited Company', incorporation: dayjs(), state: '', newlyIncorporated: true, onboarding: dayjs(), username: ''});
    const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [dateType, setDateType] = useState('onboardingDate');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedType, setSelectedType] = useState([]);

    useEffect(()=>{
        const getLists = async() =>{
            const resp = await fetchData('app/states');
            const resp1 = await fetchData('users/agents');
            const resp2 = await fetchData('services/list');
            if(resp && resp1 && resp2){
                setStatesList(resp.data);
                setAgentsList(resp1.data);
                setServicesList(resp2.data);
            }
        }
        getLists();
    },[fetchData])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setCustomersList([]);
            const resp = await postData('customers/lists/1', {type: [], service: [], name: '', start: 0, end: 0, dtype: "onboardingDate"});
            if(resp){
                setCustomersList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('customers/lists/'+page, body);
        if(resp){
            setCustomersList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && customersList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, customersList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleNewCustomer = (e) => {
        const { name, value } = e.target;
        setNewCustomer({ ...newCustomer, [name]: value });
    };
    const openNewCustomerModal = () =>{
        setNewCustomer({ name: '', phone: '', company: '', address: '', gst: '', state: '', type: 'Private Limited Company', incorporation: dayjs(), newlyIncorporated: true, onboarding: dayjs(), username: ''});
        setNewCustomerModal(true);
    }
    const closeNewCustomerModal = () =>{
        if(isSubmittingCustomer){
            return;
        }
        setNewCustomerModal(false);
    }

    const submitNewCustomer = async(e) =>{
        e.preventDefault();
        if(newCustomer.state === ""){
            return;
        }
        if(selectedAgent === ""){
            return;
        }
        setIsSubmittingCustomer(true);
        const resp = await postData('customers/new', {...newCustomer, saleBy: selectedAgent});
        if(resp){
            setTotalDocs((total)=>(total+1))
            if(page){
                setCustomersList((prevArray) => {
                    const updatedArray = [resp.data, ...prevArray.slice(0, prevArray.length - 1)];
                    return updatedArray;
                });
            }else{
                setCustomersList((prevArray) => [resp.data, ...prevArray]);
            }
            setNewCustomerModal(false);
            openSnackbar("Customer Added", "success");
        }
        setIsSubmittingCustomer(false);
    }

    const handleTypeChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedType(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleServiceChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedService(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({type: [], service: [], name: '', start: 0, end: 0, dtype: "onboardingDate"});
        setCustomersList([]);
        setSearchTerm('');
        setSelectedService([]);
        setSelectedType([]);
        setDateType('onboardingDate');
        setSelectedDate(null);
        getData(1, {type: [], service: [], name: '', start: 0, end: 0, dtype: "onboardingDate"});
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            name: searchTerm,
            service: selectedService,
            type: selectedType,
            start,
            end,
            dtype: dateType
        }
        setBodyData(body);
        setCustomersList([]);
        getData(1, body);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Customers ({totalDocs})</Typography>
                    <Button variant='contained' onClick={openNewCustomerModal}>New Customer</Button>
                </Stack>
                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2}>
                        <TextField fullWidth variant='outlined' label='Name/Phone/Company Name' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                        <FormControl fullWidth>
                            <InputLabel>Date Type</InputLabel>
                            <Select value={dateType} onChange={(e)=>{setDateType(e.target.value)}} >
                                <MenuItem value='onboardingDate'>Onboarding Date</MenuItem>
                                <MenuItem value='incorporationDate'>Incorporation Date</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack>
                            <Box width={300}></Box>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select multiple value={selectedType} onChange={handleTypeChange} input={<OutlinedInput label="Chip" />} >
                                <MenuItem value={'Private Limited Company'}>Private Limited Company</MenuItem>
                                <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                                <MenuItem value={'Public Limited Company'}>Public Limited Company</MenuItem>
                                <MenuItem value={'Section 8'}>Section 8</MenuItem>
                                <MenuItem value={'FPC'}>FPC</MenuItem>
                                <MenuItem value={'LLP'}>LLP</MenuItem>
                                <MenuItem value={'Trust'}>Trust</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Active Service</InputLabel>
                            <Select multiple value={selectedService} onChange={handleServiceChange} input={<OutlinedInput label="Chip" />} >
                                {servicesList.map((srv) => {
                                    return <MenuItem key={srv.id} value={srv.id}>{srv.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <IconButton type='submit'>
                            <SearchTwoTone fontSize='large' color='primary' />
                        </IconButton>
                        <IconButton type='reset'>
                            <Close fontSize='large' color='primary' />
                        </IconButton>
                    </Stack>
                </Box>
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 250px)' overflow='auto' gap={3} ref={containerRef}>

                    {customersList.map(customer=>{
                        return <Stack key={customer.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'5%'}>
                                <Avatar {...stringAvatar(customer.name)} />
                            </Box>
                            <Stack width={'15%'}>
                                <Typography variant='h5' color='#000'>{customer.name}</Typography>
                                <Typography variant='h6'>+91 {customer.phone}</Typography>
                            </Stack>
                            <Stack width={'30%'}>
                                <Typography variant='h6' color='#000'>{customer.companyName}</Typography>
                                <Typography variant='body'>{customer.type}</Typography>
                            </Stack>
                            <Box width={'20%'}>
                                {<Typography color='#000'>Incorporation: {moment(customer.incorporationDate).format('Do MMM yyyy')}</Typography>}
                                {<Typography>Onboarding: {moment(customer.onboardingDate).format('Do MMM yyyy')}</Typography>}
                            </Box>
                            <Stack flexDirection={'row'} width={'30%'} gap={1} flexWrap={'wrap'}>
                                {customer.activeServices.map((service) => { 
                                    return <Chip key={service.id} label={service.name} color='success' size='small' /> 
                                })}
                            </Stack>
                            <Box width={'5%'}>
                                <Link to={`/customer/${customer.id}`}>
                                    <Button size='small' variant='contained'>Details</Button>
                                </Link>
                            </Box>
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Customers</Typography>
                    </Stack>}
                </Stack>
                
            </Stack>

            <Modal open={newCustomerModal} onClose={closeNewCustomerModal}>
                <Box component="form" sx={{position: 'absolute',top: '10%', bottom: '10%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitNewCustomer}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add New Customer</Typography>
                        <TextField label="Name" name='name' value={newCustomer.name} onChange={handleNewCustomer} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newCustomer.phone} onChange={handleNewCustomer} variant="outlined" fullWidth required
                        slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>+91</Typography>
                                </InputAdornment>
                              ),
                            },
                        }}/>
                        <TextField label="Company Name" name='company' value={newCustomer.company} onChange={handleNewCustomer} variant="outlined" fullWidth required/>

                        <TextField label="Address" name='address' value={newCustomer.address} onChange={handleNewCustomer} variant="outlined" fullWidth multiline rows={4} required/>

                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select label="State" name="state" value={newCustomer.state} onChange={handleNewCustomer} variant="outlined">
                                {statesList.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField label="GST" name='gst' value={newCustomer.gst} onChange={handleNewCustomer} variant="outlined" fullWidth/>
                        
                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select label="Type" name="type" value={newCustomer.type} onChange={handleNewCustomer} variant="outlined">
                                <MenuItem value={'Private Limited Company'}>Private Limited Company</MenuItem>
                                <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                                <MenuItem value={'Public Limited Company'}>Public Limited Company</MenuItem>
                                <MenuItem value={'Section 8'}>Section 8</MenuItem>
                                <MenuItem value={'FPC'}>FPC</MenuItem>
                                <MenuItem value={'LLP'}>LLP</MenuItem>
                                <MenuItem value={'Trust'}>Trust</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label="Incorporation Date"
                            value={newCustomer.incorporation}
                            format='MMM DD, YYYY'
                            onChange={(newValue) => handleNewCustomer({ target: { name: 'incorporation', value: newValue } })}
                            defaultValue={dayjs()}
                            />
                        </LocalizationProvider>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={newCustomer.newlyIncorporated || false}
                                    onChange={(e) => handleNewCustomer({ target: { name: 'newlyIncorporated', value: e.target.checked } })}
                                    name="newlyIncorporated"
                                    color="primary"
                                />
                            }
                            label="Newly Incorporated?"
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label="Onboarding Date"
                            value={newCustomer.onboarding}
                            format='MMM DD, YYYY'
                            onChange={(newValue) => handleNewCustomer({ target: { name: 'onboarding', value: newValue } })}
                            defaultValue={dayjs()}
                            />
                        </LocalizationProvider>

                        <FormControl fullWidth required>
                            <InputLabel>Sale By</InputLabel>
                            <Select label="Sale By" value={selectedAgent} onChange={(e)=>{setSelectedAgent(e.target.value)}} variant="outlined">
                                {agentsList.map((agent) => {
                                    return <MenuItem key={agent.id} value={agent.id}>{agent.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <TextField label="Username" name='username' value={newCustomer.username} onChange={handleNewCustomer} variant="outlined" fullWidth required/>

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmittingCustomer}  loadingPosition='start' startIcon={<PersonAdd/>}><span>Add New Customer</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default CustomersPage