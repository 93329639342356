import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useCommonUI } from '../context/UI';
import { currency, useApiRequest } from '../store/Common';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { toast } from 'react-toastify';

function RecurringInvoiceDetails() {

    const {invoiceId} = useParams();
    const {openSnackbar, openDialog} = useCommonUI();
    const {fetchData, postData} = useApiRequest();
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceLists, setInvoiceLists] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

     const getData = useCallback(async(oid) =>{
        setIsLoading(true);
        const resp = await fetchData('invoice/recurringdetails/'+oid);
        if(resp){
            setInvoiceDetails(resp.data);
            setInvoiceItems(resp.items);
            setInvoiceLists(resp.invoices);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getData(invoiceId);
    },[getData, invoiceId]);

    const recurringStatus = async(status)=>{
        if(isDeleting){
            openSnackbar('Wait for previous change request to complete');
            return;
        }
        openDialog(status?"Enable Recurring Invoice?":"Disable Recurring Invoice?", ``, confirmStatus, {id: invoiceId, status: status});
    }
    const confirmStatus = async(obj) =>{
        const tid = toast.loading('Updating Status',{position:'bottom-right'});
        setIsDeleting(true);
        const resp = await postData('invoice/recurringstatus', obj);
        if(resp){
            toast.update(tid, { render: "Recurring Invoice Status Updated", type: "success", isLoading: false, autoClose: 2000 });
            setInvoiceDetails(null);
            getData(invoiceId);
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }
    
    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} gap={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Recurring Invoice Details</Typography>
                </Stack>}
                {invoiceDetails && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Recurring Invoice Details</Typography>
                        </Stack>
                        <Stack flexDirection={'row'} gap={1}>
                            {invoiceDetails.status && <Button variant='contained' color='error' onClick={()=>{recurringStatus(false)}}>Disable Recurring Invoice</Button>}
                            {!invoiceDetails.status && invoiceDetails.nextDate && <Button variant='contained' color='success' onClick={()=>{recurringStatus(true)}}>Enable Recurring Invoice</Button>}
                        </Stack>
                    </Stack>

                    <Stack sx={{flexDirection: 'row', padding: 2, paddingTop: 0}}>
                        <Stack flex={1} gap={2}>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Invoice Start Date</Typography>
                                <Typography fontWeight={'bold'}>{moment(invoiceDetails.startDate).format('Do MMM YYYY')}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Invoice End Date</Typography>
                                <Typography fontWeight={'bold'}>{moment(invoiceDetails.endDate).format('Do MMM YYYY')}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Next Invoice Date</Typography>
                                {invoiceDetails.nextDate && <Typography fontWeight={'bold'}>{moment(invoiceDetails.nextDate).format('Do MMM YYYY')}</Typography>}
                                {!invoiceDetails.nextDate && <Typography fontWeight={'bold'}>Stopped</Typography>}
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Interval</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.interval} {invoiceDetails.intervalType==="day"?"Days":"Months"}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Status</Typography>
                                <Typography fontWeight={'bold'}><Typography color={invoiceDetails.status?"success":"error"}>{invoiceDetails.status?"Active":"Inactive"}</Typography></Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Created</Typography>
                                <Typography fontWeight={'bold'}>{moment(invoiceDetails.created).format('Do MMM YYYY h:mm a')}</Typography>
                            </Box>
                        </Stack>
                        <Stack flex={1} gap={2}>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Customer Name</Typography>
                                <Box width={'fit-content'}>
                                    <Link to={`/customer/${invoiceDetails.customer.id}`} style={{textDecoration: 'none'}}>
                                        <Typography fontWeight={'bold'} color='#000'>{invoiceDetails.customer.name}</Typography>
                                    </Link>
                                </Box>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Customer Phone</Typography>
                                <Typography fontWeight={'bold'}>+91 {invoiceDetails.customer.phone}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Company Name</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.companyName}</Typography>
                            </Box>
                            {invoiceDetails.customer.gst && <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>GST</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.gst}</Typography>
                            </Box>}
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Customer Address</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.address}</Typography>
                            </Box>
                            <Box sx={{paddingLeft: 2, borderLeft: '2px solid rgb(30,64,175)'}}>
                                <Typography>Place of Supply</Typography>
                                <Typography fontWeight={'bold'}>{invoiceDetails.customer.state.code} - {invoiceDetails.customer.state.name}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>}

                {invoiceItems.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Stack justifyContent={'space-between'}>
                            <Typography variant='h5'>Invoice Items</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ paddingY: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2} px={2}>
                            <Box width={'5%'}>
                                <Typography><b>No</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>HSN/ SAC</b></Typography>
                            </Box>
                            <Box width={'45%'}>
                                <Typography><b>Product</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Price</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>GST</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Amount</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                            {invoiceItems.map((item, index)=>{
                                const gstAmt = (parseFloat(item.gst)/100)*parseFloat(item.professional);
                                return <Stack key={item.id} flexDirection='row' gap={1} p={1.2} px={2} sx={{borderBottom: '1px solid #e2e3e5', color: '#000'}}>
                                    <Box width={'5%'}>
                                        <Typography>{index + 1}</Typography>
                                    </Box>
                                    <Box width={'10%'}>
                                        <Typography>{item.product.hsn}</Typography>
                                    </Box>
                                    <Box width={'45%'} pr={4}>
                                        <Typography>{item.product.name}</Typography>
                                        {item.govt > 0 && <Stack alignItems={'flex-end'} gap={0.5}>
                                            <Typography>Professional Fees: {currency} {parseFloat(item.professional).toFixed(2)}</Typography>
                                            <Typography>Government Fees: {currency} {parseFloat(item.govt).toFixed(2)}</Typography>
                                        </Stack>}
                                    </Box>
                                    <Box width={'15%'}>
                                        <Typography>{currency} {parseFloat(parseFloat(item.professional) + parseFloat(item.govt)).toFixed(2)}</Typography>
                                    </Box>
                                    <Stack width={'10%'}>
                                        <Typography>{currency} {parseFloat(gstAmt).toFixed(2)}</Typography>
                                        <Typography variant='body2'>@ {item.gst}%</Typography>
                                    </Stack>
                                    <Box width={'15%'}>
                                        <Typography>{currency} {parseFloat(parseFloat(item.professional)+parseFloat(item.govt)+parseFloat(gstAmt)).toFixed(2)}</Typography>
                                    </Box>
                                </Stack>
                            })}
                        </Stack>
                    </Stack>

                </Stack>}

                {invoiceLists.length > 0 && <Stack sx={{background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>

                    <Stack sx={{borderWidth: 0, borderBottomWidth: 2, padding: 2, borderColor: '#e2e3e5', borderStyle: 'groove'}}>
                        <Typography variant='h5'>Invoices</Typography>
                    </Stack>

                    <Stack sx={{ padding: 2, paddingTop: 0}}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} width='100%' p={1.2}>
                            <Box width={'15%'}>
                                <Typography><b>Invoice Date</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Invoice No</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Price</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>GST</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Total</b></Typography>
                            </Box>
                            <Box width={'15%'}>
                                <Typography><b>Due</b></Typography>
                            </Box>
                            <Box width={'10%'}>
                                <Typography><b>View</b></Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2}>
                        {invoiceLists.map(invoice=>{
                            let dueColor = "success";
                            if(invoice.due > 0){
                                dueColor = "error";
                            }
                            return <Paper key={invoice.id} elevation={3}>
                                <Stack flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', color: 'rgb(71, 85, 105)'}}>
                                    <Box width={'15%'}>
                                        <Typography>{moment(invoice.invDate).format('Do MMM YYYY')}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        <Typography>{invoice.invoiceNo}</Typography>
                                    </Box>  
                                    <Box width={'15%'}>
                                        <Typography>{currency} {invoice.price}</Typography>
                                    </Box>   
                                    <Box width={'15%'}>
                                        <Typography>{currency} {invoice.gst}</Typography>
                                    </Box> 
                                    <Box width={'15%'}>
                                        <Typography>{currency} {invoice.total}</Typography>
                                    </Box> 
                                    <Box width={'15%'}>
                                        <Typography color={dueColor}>{currency} {invoice.due}</Typography>
                                    </Box> 
                                    <Box width={'10%'}>
                                        <Link to={'/invoice/'+invoice.id}>
                                            <Button size='small' variant='contained'>View</Button>
                                        </Link>
                                    </Box>  
                                </Stack>
                            </Paper>
                        })}
                        </Stack>
                    </Stack>

                </Stack>}

            </Stack>
        </Stack>
    )
}

export default RecurringInvoiceDetails