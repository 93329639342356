import { Box, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { Close, SearchTwoTone } from '@mui/icons-material';

function PaymentsPage() {

    const {postData} = useApiRequest();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [paymentsList, setPaymentsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [bodyData, setBodyData] = useState({search: '', start: 0, end: 0, type: []});

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedType, setSelectedType] = useState([]);

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setPaymentsList([]);
            const resp = await postData('invoice/payments/1', {search: '', start: 0, end: 0, type: []});
            if(resp){
                setPaymentsList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('invoice/payments/'+page, body);
        if(resp){
            setPaymentsList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && paymentsList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, paymentsList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handleTypeChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedType(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({search: '', start: 0, end: 0, type: []});
        setPaymentsList([]);
        setSearchTerm('');
        setSelectedType([]);
        setSelectedDate(null);
        getData(1, {search: '', start: 0, end: 0, type: []});
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            search: searchTerm,
            type: selectedType,
            start,
            end
        }
        setBodyData(body);
        setPaymentsList([]);
        getData(1, body);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Payments ({totalDocs})</Typography>
                </Stack>
                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2}>
                        <TextField fullWidth variant='outlined' label='Name/Phone/Company Name/Invoice No' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                        <Stack>
                            <Box width={400}></Box>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Payment Date Range" value={selectedDate} onChange={setSelectedDate} />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select multiple value={selectedType} onChange={handleTypeChange} input={<OutlinedInput label="Chip" />} >
                                <MenuItem value={'Cash'}>Cash</MenuItem>
                                <MenuItem value={'Card'}>Card</MenuItem>
                                <MenuItem value={'UPI'}>UPI</MenuItem>
                                <MenuItem value={'Net Banking'}>Net Banking</MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton type='submit'>
                            <SearchTwoTone fontSize='large' color='primary' />
                        </IconButton>
                        <IconButton type='reset'>
                            <Close fontSize='large' color='primary' />
                        </IconButton>
                    </Stack>
                </Box>
                <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' gap={1} px={3.2}>
                    <Box width='15%'>
                        <Typography><b>Pay Date</b></Typography>
                    </Box>
                    <Box width='20%'>
                        <Typography><b>Customer Name</b></Typography>
                    </Box>
                    <Box width='20%'>
                        <Typography><b>Customer Company</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Invoice No</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Payment Type</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Amount</b></Typography>
                    </Box>
                </Stack>

                <Stack mt={1} p={2} pt={0} height='calc(100vh - 300px)' overflow='auto' gap={3} ref={containerRef}>
                    {paymentsList.map(payment=>{
                        return <Stack key={payment.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width='15%'>
                                <Typography>{moment(payment.payDate).format('Do MMM yyyy h:mm a')}</Typography>
                            </Box>
                            <Stack width={'20%'}>
                                <Box width={'fit-content'}>
                                    <Link to={`/customer/${payment.customer.id}`} style={{textDecoration: 'none'}}>
                                        <Typography color={'#000'} variant='h6'>{payment.customer.name}</Typography>
                                    </Link>
                                </Box>
                            </Stack>
                            <Stack width={'20%'}>
                                <Typography>{payment.customer.companyName}</Typography>
                            </Stack>
                            <Box width='15%'>
                                <Box width={'fit-content'}>
                                    <Link to={`/invoice/${payment.invoice.id}`} style={{textDecoration: 'none'}}>
                                        <Typography color={'#000'} variant='h6'>{payment.invoice.invoiceNo}</Typography>
                                    </Link>
                                </Box>
                            </Box>
                            <Box width='15%'>
                                <Typography>{payment.payType}</Typography>
                            </Box>
                            <Box width='15%'>
                                <Typography>{currency} {payment.amount}</Typography>
                            </Box>
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Payments</Typography>
                    </Stack>}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PaymentsPage