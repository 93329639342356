import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { currency, useApiRequest } from '../store/Common';
import moment from 'moment/moment';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { Close, SearchTwoTone } from '@mui/icons-material';

function InvoicePage() {

    const {postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [invoicesList, setInvoicesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [bodyData, setBodyData] = useState({type: '', func: '', search: '', start: 0, end: 0, value: ''});

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedType, setSelectedType] = useState('');
    const [selectedFunction, setSelectedFunction] = useState('');
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setInvoicesList([]);
            const resp = await postData('invoice/lists/1', {type: '', func: '', search: '', start: 0, end: 0, value: ''});
            if(resp){
                setInvoicesList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('invoice/lists/'+page, body);
        if(resp){
            setInvoicesList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && invoicesList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, invoicesList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({type: '', func: '', search: '', start: 0, end: 0, value: ''});
        setInvoicesList([]);
        setSearchTerm('');
        setSelectedType('');
        setSelectedDate(null);
        setSelectedFunction('');
        setSelectedValue('');
        getData(1, {type: '', func: '', search: '', start: 0, end: 0, value: ''});
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].setHours(0,0,0,0)
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            search: searchTerm,
            type: selectedType,
            func: selectedFunction,
            value: selectedValue,
            start,
            end
        }
        setBodyData(body);
        setInvoicesList([]);
        getData(1, body);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Invoices ({totalDocs})</Typography>
                </Stack>
                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2}>
                        <TextField fullWidth variant='outlined' label='Name/Phone/Company Name/Invoice No' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                        <Stack>
                            <Box width={300}></Box>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Invoice Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                        </Stack>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select value={selectedType} onChange={(e)=>{setSelectedType(e.target.value)}} >
                                <MenuItem value={'price'}>Price</MenuItem>
                                <MenuItem value={'gst'}>GST</MenuItem>
                                <MenuItem value={'total'}>Total</MenuItem>
                                <MenuItem value={'due'}>Due</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Function</InputLabel>
                            <Select value={selectedFunction} onChange={(e)=>{setSelectedFunction(e.target.value)}} >
                                <MenuItem value={'>='}>{'>='}</MenuItem>
                                <MenuItem value={'='}>{'='}</MenuItem>
                                <MenuItem value={'<='}>{'<='}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField fullWidth variant='outlined' label='Value' type='number' value={selectedValue} onChange={(e)=>{setSelectedValue(e.target.value)}} />
                        <IconButton type='submit'>
                            <SearchTwoTone fontSize='large' color='primary' />
                        </IconButton>
                        <IconButton type='reset'>
                            <Close fontSize='large' color='primary' />
                        </IconButton>
                    </Stack>
                </Box>
                <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' gap={1} px={3.2}>
                    <Box width='10%'>
                        <Typography><b>Invoice No</b></Typography>
                    </Box>
                    <Box width='10%'>
                        <Typography><b>Invoice Date</b></Typography>
                    </Box>
                    <Box width='25%'>
                        <Typography><b>Customer Name and Company</b></Typography>
                    </Box>
                    <Box width='15%'>
                        <Typography><b>Linked Service</b></Typography>
                    </Box>
                    <Box width='8%'>
                        <Typography><b>Price</b></Typography>
                    </Box>
                    <Box width='8%'>
                        <Typography><b>GST</b></Typography>
                    </Box>
                    <Box width='8%'>
                        <Typography><b>Total</b></Typography>
                    </Box>
                    <Box width='8%'>
                        <Typography><b>Due</b></Typography>
                    </Box>
                    <Box width='8%'>
                        <Typography><b>Details</b></Typography>
                    </Box>
                </Stack>

                <Stack mt={1} p={2} pt={0} height='calc(100vh - 300px)' overflow='auto' gap={3} ref={containerRef}>
                    {invoicesList.map(invoice=>{
                        let dueColor = "success";
                        if(invoice.due > 0){
                            dueColor = "error";
                        }
                        return <Stack key={invoice.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width='10%'>
                                <Typography>{invoice.invoiceNo}</Typography>
                            </Box>
                            <Box width='10%'>
                                <Typography>{moment(invoice.invDate).format('Do MMM yyyy')}</Typography>
                            </Box>
                            <Stack width={'25%'}>
                                <Box width={'fit-content'}>
                                    <Link to={`/customer/${invoice.customer.id}`} style={{textDecoration: 'none'}}>
                                        <Typography color={'#000'} variant='h6'>{invoice.customer.name}</Typography>
                                    </Link>
                                </Box>
                                <Typography>{invoice.customer.companyName}</Typography>
                            </Stack>
                            <Stack width={'15%'}>
                                {invoice.linkedService && <Typography>{invoice.linkedService.service.name}</Typography>}
                            </Stack>
                            <Box width='8%'>
                                <Typography>{currency} {invoice.price}</Typography>
                            </Box>
                            <Box width='8%'>
                                <Typography>{currency} {invoice.gst}</Typography>
                            </Box>
                            <Box width='8%'>
                                <Typography>{currency} {invoice.total}</Typography>
                            </Box>
                            <Box width='8%'>
                                <Typography color={dueColor}>{currency} {invoice.due}</Typography>
                            </Box>
                            <Box width={'8%'}>
                                <Link to={`/invoice/${invoice.id}`}>
                                    <Button size='small' variant='contained'>Details</Button>
                                </Link>
                            </Box>
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Invoices</Typography>
                    </Stack>}
                </Stack>
                
            </Stack>

        </Stack>
    )
}

export default InvoicePage