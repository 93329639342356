import { Box, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { useApiRequest } from '../store/Common';
import { Editor } from '@tinymce/tinymce-react';
import { LoadingButton } from '@mui/lab';
import { AttachEmail, Close, DeleteForever, Email, SearchTwoTone } from '@mui/icons-material';
import { useCommonUI } from '../context/UI';
import moment from 'moment';
import { toast } from 'react-toastify';


function TemplatePage() {

    const {postData} = useApiRequest();
    const {openSnackbar, openDialog} = useCommonUI();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const containerRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [bodyData, setBodyData] = useState({search: ""});
    const [searchTerm, setSearchTerm] = useState('');

    const [newTemplateModal, setNewTemplateModal] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [newTemplateSubject, setNewTemplateSubject] = useState('');
    const [newTemplateStatus, setNewTemplateStatus] = useState(true);
    const [newTemplateContent, setNewTemplateContent] = useState("");
    const [templateId, setTemplateId] = useState(null);

    const [attachmentModal, setAttachmentModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [file, setFile] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setTemplateList([]);
        const resp = await postData('template/lists/1', {search: ""});
        if(resp){
            setTemplateList(resp.data.docs);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };
    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('template/lists/'+page, body);
        if(resp){
            setTemplateList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && templateList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, templateList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const openNewModal = () =>{
        setNewTemplateContent('');
        setNewTemplateName('');
        setNewTemplateSubject('');
        setNewTemplateStatus(true);
        setTemplateId(null);
        setNewTemplateModal(true);
    }
    const editTemplateModal = (template) => {
        setNewTemplateContent(template.content);
        setNewTemplateName(template.name);
        setNewTemplateSubject(template.subject);
        setNewTemplateStatus(template.status);
        setTemplateId(template.id);
        setNewTemplateModal(true);
    }
    const closeNewTemplateModal = () =>{
        if(isSubmitting){
            return;
        }
        setNewTemplateModal(false);
    }
    const submitNewTemplate = async() =>{
        if(newTemplateSubject === "" || newTemplateName === "" || newTemplateContent === ""){
            return;
        }
        setIsSubmitting(true);
        let resp = null;
        if(templateId){
            resp = await postData('template/edit', {id: templateId, name: newTemplateName, subject: newTemplateSubject, status: newTemplateStatus, content: newTemplateContent});
        }else{
            resp = await postData('template/new', {name: newTemplateName, subject: newTemplateSubject, status: newTemplateStatus, content: newTemplateContent});
        }
        
        if(resp){
            if(templateId){
                setTemplateList((prevList) =>
                    prevList.map((template) =>
                        template.id === templateId ? resp.data : template
                    )
                );
                openSnackbar("Template Updated", "success");
            }else{
                setTotalDocs((total)=>(total+1))
                if(page){
                    setTemplateList((prevArray) => {
                        const updatedArray = [resp.data, ...prevArray.slice(0, prevArray.length - 1)];
                        return updatedArray;
                    });
                }else{
                    setTemplateList((prevArray) => [resp.data, ...prevArray]);
                }
                openSnackbar("Template Added", "success");
            }
            setNewTemplateModal(false);
        }
        setIsSubmitting(false);
    }

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({search: ''});
        setTemplateList([]);
        setSearchTerm('');
        getData(1, {search: ''});
    }
    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let body = {
            search: searchTerm
        }
        setBodyData(body);
        setTemplateList([]);
        getData(1, body);
    }

    const openAttachmentModal = (template) =>{
        setSelectedTemplate(template);
        setFile(null);
        setAttachmentModal(true);
    }
    const closeAttachmentModal = () =>{
        if(isSubmitting){
            return;
        }
        setAttachmentModal(false);
    }
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const submitAttachment = async() =>{
        if(!file){
            return;
        }
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', selectedTemplate.id); 
        const resp = await postData("template/attachment", formData, {headers: {'Content-Type': 'multipart/form-data'}});
        if(resp){
            setAttachmentModal(false);
            openSnackbar("Attachement Added", "success");
            setTemplateList((prevList) =>
                prevList.map((template) =>
                    template.id === resp.data.id ? resp.data : template
                )
            );
        }
        setIsSubmitting(false);
    }

    const deleteAttach = (item) =>{
        if(isDeleting){
            openSnackbar("Previous delete request processing", "info");
            return;
        }
        openDialog("Delete Attachment ?", item.filename, deleteAttachment, {id: selectedTemplate.id, aid: item._id});
    }

    const deleteAttachment = async(obj) =>{
        setIsDeleting(true);
        const tid = toast.loading('Deleting Attachment',{position:'bottom-right'});
        const resp = await postData("template/attachment/delete", obj);
        if(resp){
            setAttachmentModal(false);
            toast.update(tid, { render: "Attachment Deleted", type: "success", isLoading: false, autoClose: 2000 });
            setTemplateList((prevList) =>
                prevList.map((template) =>
                    template.id === resp.data.id ? resp.data : template
                )
            );
        }else{
            toast.dismiss();
        }
        setIsDeleting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#d8d9dd', height: '100%', overflow: 'auto'}}>
            <Stack padding={2} pt={4} borderRadius={5} sx={{background: 'linear-gradient(to bottom, #e2e3e5, #cfd6e6)', flex: 1}}>
                <Stack px={2} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Email Templates ({totalDocs})</Typography>
                    <Button variant='contained' onClick={openNewModal}>New Email Template</Button>
                </Stack>
                <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                    <Stack flexDirection='row' gap={2}>
                        <TextField fullWidth variant='outlined' label='Name/Subject' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                        <IconButton type='submit'>
                            <SearchTwoTone fontSize='large' color='primary' />
                        </IconButton>
                        <IconButton type='reset'>
                            <Close fontSize='large' color='primary' />
                        </IconButton>
                    </Stack>
                </Box>
                <Stack flexDirection='row' gap={1} p={3.2} pb={0} alignItems='center'>
                    <Box width={'20%'}>
                        <Typography ><b>Created</b></Typography>
                    </Box>  
                    <Box width={'20%'}>
                        <Typography color='#000'><b>Name</b></Typography>
                    </Box>  
                    <Box width={'30%'}>
                        <Typography><b>Subject</b></Typography>
                    </Box>     
                    <Box width={'10%'}>
                        <Typography ><b>Status</b></Typography>
                    </Box>  
                    <Box width={'10%'}>
                        <Typography ><b>Attachments</b></Typography>
                    </Box>  
                    <Box width={'7%'}>
                        <Typography><b>Modify</b></Typography>
                    </Box>  
                </Stack>
                <Stack mt={1} p={2} pt={0} height='calc(100vh - 300px)' overflow='auto' gap={3} ref={containerRef}>
                    {templateList.map(template=>{
                        return <Stack key={template.id} flexDirection='row' gap={1} p={1.2} alignItems='center' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                            <Box width={'20%'}>
                                <Typography>{moment(template.created).format('Do MMMM YYYY h:mm a')}</Typography>
                            </Box>  
                            <Box width={'20%'}>
                                <Typography color='#000'>{template.name}</Typography>
                            </Box>  
                            <Box width={'30%'}>
                                <Typography>{template.subject}</Typography>
                            </Box>    
                            <Stack width={'10%'}>
                                <Typography color={template.status?"success":"error"}>{template.status?"Active":"Inactive"}</Typography>
                            </Stack> 
                            <Stack width={'10%'}>
                                <Button size='small' variant='contained' color='info' onClick={()=>{openAttachmentModal(template)}}>Manage</Button>
                            </Stack> 
                            <Box width={'7%'}>
                                <Button size='small' variant='contained' color='error' onClick={()=>{editTemplateModal(template)}}>Edit</Button>
                            </Box>  
                        </Stack>
                    })}
                    {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Templates</Typography>
                    </Stack>}
                </Stack>
            </Stack>

            <Modal open={newTemplateModal} onClose={closeNewTemplateModal} disableEnforceFocus={true}>
                <Box component="form" sx={{position: 'absolute',top: '5%', bottom: '5%', overflowY: 'auto', left: 'calc(50% - 500px)',width: 1000,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{templateId?'Edit':'Add New'} Email Template</Typography>
                        <TextField label="Name" value={newTemplateName} onChange={(e)=>{setNewTemplateName(e.target.value)}} variant="outlined" fullWidth/>
                        <TextField label="Subject" value={newTemplateSubject} onChange={(e)=>{setNewTemplateSubject(e.target.value)}} variant="outlined" fullWidth/>
                        <FormControl fullWidth required>
                            <InputLabel>Status</InputLabel>
                            <Select label="Status" value={newTemplateStatus} onChange={(e)=>{setNewTemplateStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack flexDirection={'row'} gap={2}>
                            <Chip label="{{name}}" color='info'/>
                            <Chip label="{{companyName}}" color='info'/>
                            <Chip label="{{address}}" color='info'/>
                        </Stack>
                        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                            <Typography>Annual Compliance Service Only: </Typography>
                            <Chip label="{{username}}" color='info'/>
                            <Chip label="{{password}}" color='info'/>
                        </Stack>
                        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                            <Typography>Invoice Only: </Typography>
                            <Chip label="{{invoiceNo}}" color='info'/>
                            <Chip label="{{invoiceDate}}" color='info'/>
                            <Chip label="{{invoiceAmount}}" color='info'/>
                        </Stack>
                        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                            <Typography>Annual Compliance Only: </Typography>
                            <Chip label="{{complianceName}}" color='info'/>
                            <Chip label="{{complianceDoneDate}}" color='info'/>
                            <Chip label="{{complianceExpiryDate}}" color='info'/>
                        </Stack>
                        <Editor
                            apiKey='k11h0lei05kosgmjaodsq6on5o1hs0zxi8b222tjelc1kr0v'
                            init = {{
                                height: '600',
                                plugins: 'table accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help importcss insertdatetime link lists nonbreaking pagebreak preview quickbars save searchreplace visualblocks visualchars wordcount',
                                toolbar: 'undo redo bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify outdent indent code anchor emoticons fontsize table link bullist numlist pagebreak preview  quicklink accordion fullscreen',
                                paste_data_images: false,
            	                relative_urls : false
                            }}
                            onEditorChange={(newValue, editor) => setNewTemplateContent(newValue)}
                            value={newTemplateContent}
                        />
                        <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} onClick={submitNewTemplate} loadingPosition='start' startIcon={<Email/>}><span>{templateId?'Update':'Add New'} Template</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={attachmentModal} onClose={closeAttachmentModal}>
                <Box sx={{position: 'absolute', top: '15%', left: 'calc(50% - 250px)',width: 500, bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    {selectedTemplate && <Stack gap={2}>
                        <Typography variant='h6'>Email Attachments</Typography>
                        <Typography><b>Template Name: </b>{selectedTemplate.name}</Typography>
                        {selectedTemplate.attachments.length > 0 && <Stack>
                            <Typography><b>Files:</b></Typography>
                            {selectedTemplate.attachments.map(item=>{
                                return <Stack key={item._id} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography>{item.filename}</Typography>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <IconButton onClick={()=>{deleteAttach(item)}}>
                                            <DeleteForever color='error' />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            })}    
                        </Stack>}

                        <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                            {file && <Typography variant="body2">{file.name}</Typography>}
                            <Button variant="contained" component="label">
                                {file?"Change":"Add New"} File
                                <input type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </Stack>
                        {file && <LoadingButton fullWidth variant="contained" color="primary" loading={isSubmitting} onClick={submitAttachment} loadingPosition='start' startIcon={<AttachEmail/>}><span>Upload File Attachment</span></LoadingButton>}
                        
                    </Stack>}
                </Box>
            </Modal>
        </Stack>
    )
}

export default TemplatePage