import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: 'configSlice',
    initialState: {
        loggedIn: null,
        name: '',
    },
    reducers: {
        updateLogin(state, action){
            state.loggedIn = action.payload
        },
        updateName(state, action){
            state.name = action.payload
        }
    }
})

export const {updateLogin, updateName} = configSlice.actions;
export default configSlice.reducer;